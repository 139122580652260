import { Injectable } from '@angular/core';
declare var angular: any;
import { downgradeInjectable } from '@angular/upgrade/static';
import settings from '../../../../config.json';
import { HttpClient } from '@angular/common/http';
import { ToastService } from './toast.service';
import { ToastrService } from 'ngx-toastr';
import { AircraftService } from './aircraft.service';
import { CoreService } from './core.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  menuItems;
  menuItemsLoaded;

  constructor(private http: HttpClient,
    private toastService: ToastService,
    private toastrService: ToastrService,
    private aircraftService: AircraftService,
    private coreservice: CoreService) { }

  getContentMenu() {
    var url:string = settings.configuration.env.apiUrl + 'mainmenu';
    console.log("getContent Menu Called...", url);
    return this.http.get(url).
    toPromise().then((res : any) => {
      this.menuItems = res;
      this.change_tv_show_title(res);
      this.menuItemsLoaded = true;

      // Code Commented to Fix Systemupdate/MsuVersionUpdate Toast Message
      // if (this.coreservice.aircraft.aircraft_id != null) {
      //   this.systemUpdate(this.coreservice.aircraft.aircraft_id);
      //   this.msuVersionUpdate(this.coreservice.aircraft.aircraft_id);
      // }

      return res;
    });
  }

  systemUpdate(aircraftId){
    console.log("inside SymstemUpdate API");
    return this.aircraftService.getSystemDetails(aircraftId).then(
      (res: any) => {
        try {
          if(res == true) {
            this.toastService.toastSecurityUpdate("error", "error");
          } else {
            console.log("systemUpdate error errorToast", this.coreservice.errorToast);
            if(this.coreservice.errorToast != undefined){
              this.toastrService.clear(this.coreservice.errorToast.toastId);
            }
          }  
        } catch (error) {
          console.log("getsystem exc ",error);
        }   
      });
  }

  msuVersionUpdate(aircraftId){
    console.log("inside MSU API");
    return this.aircraftService.getMSUDetails(aircraftId).then(
      (res: any) => {
        try {
          if(res == true) {
            this.toastService.toastMSUversionWarning("warning", "warning");
          } else {
            console.log("msuVersionUpdate error CustomToast", this.coreservice.customToast);
            //
            if(this.coreservice.customToast != undefined){
              this.toastrService.clear(this.coreservice.customToast.toastId);
            }
          }
        } catch (error) {
          console.log("msuVersionUpdate exc ",error);
        }
        
      },
      (err: any) => {
        console.log("msuVersionUpdate error is: ", err);
       });
  }


  change_tv_show_title(resp) {
    for (var i = 0; i < resp.length; i++) {
        if (resp[i].title == 'TV Show') {
            resp[i].title = 'TV Shows';
        } 
    }
  }
}
angular
  .module('app.core')
  .factory('menuService', downgradeInjectable(MenuService));