import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OperatorService } from '../core/services/operator.service';
import { ToastService } from '../core/services/toast.service';

@Component({
  selector: 'app-operator-confirm-dialog',
  templateUrl: './operator-confirm-dialog.component.html',
  styleUrls: ['../aircraft/aircraft.less']
})
export class OperatorConfirmDialogComponent implements OnInit {

  public option;
  public deleteName;

  constructor(private toastService: ToastService,
    public operatorService: OperatorService,
    private OperatorConfirmDialog: MatDialogRef<OperatorConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: any,) {
      this.option = 'operator';
      this.deleteName = this.dialogData.operatorId;
    }

  ngOnInit() {
  }

  deleteConfirmed() {
    return this.operatorService.deleteOperator(this.deleteName).then((res) => {
        if (res.status == 200) {
            this.toastService.toastSuccess("success_operator_deleted", "operator_management");
            this.OperatorConfirmDialog.close();
        } else if (res.status == 400) {
            this.toastService.toastError("error_operator_with_aircrafts_delete", "operator_management");
        } else {
            this.toastService.toastError("error_operator_delete", "operator_management");
        }
    });
  }

  closeThisDialog() {
    this.OperatorConfirmDialog.close();
  }
}
