import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-subscription-plan-confirm-dialog',
  templateUrl: './subscription-plan-confirm-dialog.component.html',
  styleUrls: ['./aircraft.less', './../layout/dialog-main.less']
})

export class SubscriptionPlanConfirmDialogComponent implements OnInit {
  public heading: string = '';
  public message: string = '';
  public action: string = '';
  public cancel: string = '';

  constructor(
    private dialogRef: MatDialogRef<SubscriptionPlanConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.heading = this.data.heading
    this.message = this.data.message
    this.action = this.data.action
    this.cancel = this.data.cancel
  }

  ngOnInit() { }

  actionClicked() { this.dialogRef.close({ event: 'action' }); }

  cancelClicked() { this.dialogRef.close({ event: 'cancel' }); }
}
