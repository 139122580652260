import { Component, OnInit } from '@angular/core';
import * as _ from "lodash";
import { downgradeComponent } from '@angular/upgrade/static';
import { AircraftService } from '../core/services/aircraft.service';
import settings from '../../../config.json';
declare var angular: angular.IAngularStatic;

@Component({
  selector: 'sidebardownload',
  templateUrl: './sidebar-download.component.html',
  styleUrls: ['./sidebar-download.component.less']
})

export class SidebarDownloadComponent implements OnInit {

  public image: string;
  public syncaircraft: any = [];
  public aircraft_id: any = [];
  public isSelected: boolean = true;
  private syncQueuePath: string = '/download'

  constructor(private aircraftService: AircraftService) {
      this.image = settings.configuration.env.apiUrl + 'images/fleet/';
  }

  ngDoCheck() {
      this.aircraft_id = window.location.pathname.split(this.syncQueuePath).pop();
      if (this.aircraft_id != '' && this.isSelected) {
          this.aircraftService.getAircraftDetails(this.aircraft_id.split('/').pop()).then((res: any) => {
              this.syncaircraft = res;
              this.isSelected = false;
          });
      }
  }

  ngOnInit() { }
}

angular
  .module('app.download')
  .directive(
    'sidebardownload',
    downgradeComponent({ component: SidebarDownloadComponent }) as angular.IDirectiveFactory
  );