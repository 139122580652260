import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import { AircraftService } from '../core/services/aircraft.service';
import { CoreService } from '../core/services/core.service';

@Component({
  selector: '[custom-toast-component]',
  styleUrls: [`./custom-toast.component.less`],
  templateUrl: `./custom-toast.component.html`,
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        display: 'none',
        opacity: 0
      })),
      transition('inactive => active', animate('400ms ease-out', keyframes([
        style({
          opacity: 0,
        }),
        style({
          opacity: 1,
        })
      ]))),
      transition('active => removed', animate('400ms ease-out', keyframes([
        style({
          opacity: 1,
        }),
        style({
          transform: 'translate3d(10%, 0, 0) skewX(10deg)',
          opacity: 0,
        }),
      ]))),
    ]),
  ],
  preserveWhitespaces: false,
})

export class CustomToastComponent extends Toast {
  
  undoString = 'undo';
  public aircraft_id: any = [];
  selectedMSUVersionStatus: number = 0;

  constructor(
    protected toastrService: ToastrService,
    private aircraftService: AircraftService,
    public coreservice: CoreService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
    this.aircraft_id = this.coreservice.aircraft.aircraft_id;
    console.log("In custom-toast aircraft_id", this.aircraft_id);
  }

  action(event: Event) {
    event.stopPropagation();
    this.undoString = 'undid';
    this.toastPackage.triggerAction();
    return false;
  }

  checkboxSelected(e) {
    if(e.target.checked){  
      console.log("e.target.checked", e.target.checked);
      return this.aircraftService.msuUpgradeNotice(this.aircraft_id, e.target.checked, this.selectedMSUVersionStatus).then(
        (res: any) => {
           if(res.body == true)
           {
             this.toastrService.clear(this.coreservice.customToast.toastId);
           }
        }
      ); 
    }
 }
}