import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { HttpClient } from '@angular/common/http';
import { CoreService } from './core.service';
import settings from '../../../../config.json';
declare var angular: any;

@Injectable({
  providedIn: 'root'
})
export class AssignPrivilegesService {

  service;

  constructor(private http: HttpClient,
    private coreservice: CoreService) { 
    }

  getAssignedAircrafts(userId: string) {
    var url: string = settings.configuration.env.apiUrl + 'account/user/' + userId + '/aircrafts';
    return this.http.get(url)
    .toPromise().then((res: any) => {
      console.log("getAssignedAircrafts", res);
       return res;
    });
  }

  getAssignedOperators(userId: string) {
    var url: string = settings.configuration.env.apiUrl + 'account/user/' + userId + '/operators';
    return this.http.get(url)
    .toPromise().then((res: any) => {
      console.log("getAssignedOperators", res);
      return res;
     });
  }

  assignAircraft(userId: string, aircraftId: string) {
    var url: string = settings.configuration.env.apiUrl + 'account/user/' + userId + '/assign/aircraft/' + aircraftId;
    return this.http.post(url, null, {
      observe: "response"
   })
    .toPromise().then((res: any) => {
      return res;
      });
  }

  unassignAircraft(userId: string, aircraftId: string) {
    var url: string = settings.configuration.env.apiUrl + 'account/user/' + userId + '/unassign/aircraft/' + aircraftId;
    return this.http.post(url, null, {
      observe: "response"
   })
    .toPromise().then((res: any) => {
      return res;
     });
  }

  assignOperator(userId: string, operatorId: string) {
    var url: string = settings.configuration.env.apiUrl + 'account/user/' + userId + '/assign/operator/' + operatorId;
    return this.http.post(url, null, {
      observe: "response"
   })
    .toPromise().then((res: any) => {
      console.log("assignOperator", res);
      return res;
     });
  } 

  unassignOperator(userId: string, operatorId: string) {
    var url: string = settings.configuration.env.apiUrl + 'account/user/' + userId + '/unassign/operator/' + operatorId;
    return this.http.post(url, null, {
      observe: "response"
   })
    .toPromise().then((res: any) => {
      console.log("unassignOperator", res);
      return res;
     });
  }

  getAircraftPrivileges(userId: string, aircraftId: string) {
    var url: string = settings.configuration.env.apiUrl + 'account/user/' + userId + '/aircraft/' + aircraftId + '/privileges';
    return this.http.get(url)
    .toPromise().then((res: any) => {
      console.log("getAircraftPrivileges", res);
      return res;
    });
  }

  getOperatorPrivileges(userId: string, operatorId: string) {
    var url: string = settings.configuration.env.apiUrl + 'account/user/' + userId + '/operator/' + operatorId + '/privileges';
    return this.http.get(url)
    .toPromise().then((res: any) => {
      console.log("getOperatorPrivileges", res);
      return res;
    });
  }

  getGlobalPrivileges(userId: string) {
    var url:string = settings.configuration.env.apiUrl + 'account/user/' + userId + '/privileges ';
    return this.http.get(url)
    .toPromise().then((res: any) => {
      return res;
    });
  }

  setAircraftPrivileges(privileges, userId, aircraftId) {
    var url = settings.configuration.env.apiUrl + 'account/user/' + userId + '/assign/aircraft/' + aircraftId + '/privileges';
    return this.http.post(url, privileges, {
      observe: "response"
    })
    .toPromise().then((res: any) => {
      console.log("setAircraftPrivileges", res);
      return res;
     });
  }

  setOperatorPrivileges(privileges: string, userId: string, operatorId: string) {
    var url: string = settings.configuration.env.apiUrl + 'account/user/' + userId + '/assign/operator/' + operatorId + '/privileges';
    return this.http.post(url, privileges, {
      observe: "response"
    })
    .toPromise().then((res: any) => {
      console.log("setOperatorPrivileges", res);
      return res;
     });
  }

  setGlobalPrivileges(privileges: string, userId: string) {
    var url: string = settings.configuration.env.apiUrl + 'account/user/' + userId + '/assign/privileges';
    return this.http.post(url, privileges, {
      observe: "response"
    })
    .toPromise().then((res: any) => {
      return res;
     });
  }
}
  angular
        .module('app.core')
        .factory('privilegesService', downgradeInjectable(AssignPrivilegesService));
