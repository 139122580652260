import { Component, OnInit, Input, SimpleChange, SimpleChanges, KeyValueDiffers, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MediaPlayer, MediaPlayerClass } from 'dashjs';
//import dashjs from 'dashjs';

@Component({
    selector: 'app-selected',
    templateUrl: './movie-trailer.component.html',
    styleUrls: ['./video.less']
})

export class DashPlayer implements OnInit {

    private trailerUri: string;
    public player: MediaPlayerClass;
    constructor(
        public dashDialog: MatDialogRef<DashPlayer>,
        @Inject(MAT_DIALOG_DATA) public data: any
        ) {
            
        this.trailerUri = data.trailerUri;
        console.log("url____", this.trailerUri);
    }
    
    ngOnInit() {
        this.activate();
    }

    activate() {
        this.player = MediaPlayer().create();
        this.player.initialize(document.querySelector("#videoPlayer"), this.trailerUri, true);
    }
}
