import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import settings from '../../../config.json';
import { SelectItemsService } from '../core/services/select-items.service';
import { AuthorizedService } from '../core/services/authorized.service';
import { CoreService } from '../core/services/core.service';
import { Config }  from '../core/config';
import { MAT_DIALOG_DATA } from '@angular/material';
import * as _ from "lodash";

@Component({
  selector: 'app-album-dialog',
  templateUrl: './album-dialog.component.html',
  styleUrls: ['./musiccontent.component.less', '../video/video.less']
})

export class AlbumDialogComponent implements OnInit {

    public details: any = [];
    public isPlaylist: boolean;
    public track: any;
    public coverImage: string;
    public image: string;
    public imageCategory: string;
    public imageChannel: string;
    public dateTime: any;
    public today: Date;
    public until: Date;
    public fromDate: Date;

    constructor(private route: ActivatedRoute,
                private selectItems: SelectItemsService,
                private config: Config,
                public coreservice: CoreService,
                public authorizedService: AuthorizedService,
                @Inject(MAT_DIALOG_DATA) private dialogData: any) {
            this.coverImage = settings.configuration.env.apiUrl;
            this.image = settings.configuration.env.apiUrl + 'images/albums/' + this.config.IMAGE_FORMATS.MUSIC_DETAILS + '/';
            this.imageCategory = settings.configuration.env.apiUrl + 'images/categories/' + this.config.IMAGE_FORMATS.DETAILS_CATEGORIE_BANNER + '/';
            this.imageChannel = settings.configuration.env.apiUrl + 'images/channels/' + this.config.IMAGE_FORMATS.DETAILS_CATEGORIE_BANNER + '/';
            
            this.today = new Date();
            this.dateTime = settings.configuration.datetime_formats;
    }

    ngOnInit() {
        this.activate();
        this.details = this.dialogData.albumDetails;
        this.until = new Date(this.details.valid_until);
        this.fromDate = new Date(this.details.valid_from);
    }
    
    getQueryParam(): any {
        this.route.paramMap.subscribe(params => {
            return params.get('playlist_id');
        });
    }

    activate() {
        if (this.getQueryParam()) {
            this.isPlaylist = true;
        } else {
            this.isPlaylist = false;
        }
    }

    selectTrack(track: any) {
        var trackIndex = track.track_index;
        var status = track.is_selected;
        var boolean;
        var newStatus;

        if (status == 0) {
            boolean = true;
            newStatus = 4;
        } else if (status == 4) {
            boolean = false;
            newStatus = 0;
        }

        this.selectItems.addToListTrack(track, 'music', 'album', 'track', boolean, this.details.tracks, trackIndex).then((res: any) => {
            _.find(this.details.tracks, { track_index: trackIndex }).is_selected = newStatus;
        });
    }
}

