import { Injectable } from '@angular/core';
declare var angular: any;
import { downgradeInjectable } from '@angular/upgrade/static';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import { CustomToastComponent } from '../../custom-toast/custom-toast.component';
import { ErrorToastComponent } from '../../error-toast/error-toast.component';
import { CoreService } from './core.service';

@Injectable({
  providedIn: 'root'
})

export class ToastService {

  constructor(
    private translate: TranslateService,
    private toastr: ToastrService,
    private coreservice: CoreService
  ) { }

  toastMSUversionWarning(action: string, message: string) {
    var actionString: string = action;
    var messageString: string = message;
    this.translate.get([action, message]).subscribe(translations => {
      this.coreservice.customToast = this.toastr.warning(actionString, translations[messageString], {
        disableTimeOut: true,
        positionClass:'toast-top-right',
        enableHtml: true,
        tapToDismiss: false,
        closeButton: true,
        toastComponent: CustomToastComponent,      
      });
    });
  }

  toastSecurityUpdate(action: string, message: string) {
    var actionString: string = action;
    var messageString: string = message;
    this.translate.get([action, message]).subscribe(translations => {
      this.coreservice.errorToast = this.toastr.error(actionString, translations[messageString], {
        disableTimeOut: true,
        positionClass:'toast-top-right',
        enableHtml: true,
        tapToDismiss: false,
        closeButton: true,
        toastComponent: ErrorToastComponent,      
      });
    });
  }

  toastSuccess(action: string, message: string) {
    var actionString: string = action;
    var messageString: string = message;
    this.translate.get([action, message]).subscribe(translations => {
      this.toastr.success(translations[actionString], translations[messageString]);
    })
  }

  toastError(action: string, message: string) {
    var actionString: string = action;
    var messageString: string = message;
    this.translate.get([action, message]).subscribe(translations => {
      this.toastr.error(translations[actionString], translations[messageString]);
    })
  }

toastInfo(action: string, message: string) {
    var actionString: string = action;
    var messageString: string = message;
    this.translate.get([action, message]).subscribe(translations => {
      this.toastr.info(translations[actionString], translations[messageString]);
    });
  }

toastWarning(action: string, message: string) {
    var actionString: string = action;
    var messageString: string = message;
    this.translate.get([action, message]).subscribe(translations => {
      this.toastr.warning(translations[actionString], translations[messageString]);
    });
  }
}

angular
  .module('app.core')
  .factory('toastService', downgradeInjectable(ToastService));
