import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ToastService } from '../core/services/toast.service';
import { UserService } from '../core/services/user.service';

@Component({
  selector: 'app-unlink-user-dialog',
  templateUrl: './unlink-user-dialog.component.html',
  styleUrls: ['../aircraft/aircraft.less', './user.component.less']
})
export class UnlinkUserDialogComponent implements OnInit {

  public option;
  public deleteName;
  public hideUnlinkUser: boolean = false;

  constructor(private toastService: ToastService,
    private userService: UserService,
    private UserConfirmDialog: MatDialogRef<UnlinkUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: any)
     { 
      this.option = 'user';
      this.deleteName = this.dialogData.userId;
      this.hideUnlinkUser = this.dialogData.hideUnlinkUser;
    }

  ngOnInit() {
  }

  unlinkConfirmed() {
    return this.userService.unlinkUser(this.dialogData.userId).then((res) => {
        if (res.status == 200) {
            this.dialogData.hideUnlinkUser = true;
            this.hideUnlinkUser = true;
            this.toastService.toastSuccess("success_usermanagement_unlink_user", "usermanagement_management");
            this.UserConfirmDialog.close();
        } else {
            this.toastService.toastError("error_usermanagement_unlink_user", "usermanagement_management");
        }
    });
  }

  closeThisDialog() {
    this.UserConfirmDialog.close();
  }

}
