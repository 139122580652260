import { Component, OnInit, DoCheck, KeyValueDiffers } from '@angular/core';
declare var angular: angular.IAngularStatic;
import * as _ from "lodash";
import settings from '../../../config.json';
import { Config } from '../core/config';
import { DataService } from '../core/services/data.service';
import { ChannelService } from '../core/services/channel.service';
import { SortingService } from '../core/services/sorting.service';
import { PlaylistService } from '../core/services/playlist.service';
import { ActivatedRoute } from '@angular/router';
import { MediadriveService } from '../core/services/mediadrive.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { EpisodeDialog } from './episode-dialog.component';
import { SelectItemsService } from '../core/services/select-items.service';
import { CoreService } from '../core/services/core.service';
import { AuthorizedService } from '../core/services/authorized.service';
import { downgradeComponent } from '@angular/upgrade/static';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'seriescontent',
  templateUrl: './seriescontent.component.html',
  styleUrls: ['./video.less', '../layout/layout.less']
})

export class SeriescontentComponent implements OnInit, DoCheck {

  public coverImage;
  public image;
  public imageSml;
  public imageCategory;
  public imageChannel;
  public checkLoading: boolean;
  public getMore: boolean;
  public catalog: any = [];
  public filtering;
  public genreFilter;
  public filterFlag: boolean;
  public langFlag: boolean;
  public sortFlag: boolean;
  public channelFlag: boolean;
  public contentFlag: boolean;
  private filter;
  public sorting;
  public sortBy;
  private sort;
  public languages;
  public languageFilter;
  private language;
  public contentItems;
  public channelItems;
  public dateTime = settings.configuration.datetime_formats;
  public isPlaylist;
  public message;
  public isLoading;
  public differ;
  public showingList;
  public dataLimit;
  public content_search
  private blockUI = false;
  public isUpdateAvailable: boolean = false;
  public audienceList;
  public selectedAudience;
  public audience;

  constructor(private config: Config,
    private dataService: DataService,
    private channelService: ChannelService,
    private sortingService: SortingService,
    private playlistService: PlaylistService,
    private route: ActivatedRoute,
    private mediadriveService: MediadriveService,
    private http: HttpClient,
    private dialog: MatDialog,
    private selectItems: SelectItemsService,
    public coreservice: CoreService,
    public authorizedService: AuthorizedService,
    private differs: KeyValueDiffers) {
    this.differ = this.differs.find({}).create();
    this.getEpisodesData();

    this.coverImage = settings.configuration.env.apiUrl;
    this.image = settings.configuration.env.apiUrl + 'images/tvshows/' + this.config.IMAGE_FORMATS.TV_GRID + '/';
    this.imageSml = settings.configuration.env.apiUrl + 'images/tvshows/' + this.config.IMAGE_FORMATS.TV_LIST + '/';
    this.imageCategory = settings.configuration.env.apiUrl + 'images/categories/' + this.config.IMAGE_FORMATS.DEFAULT_SQUARE + '/';
    this.imageChannel = settings.configuration.env.apiUrl + 'images/channels/' + this.config.IMAGE_FORMATS.CHANNEL_BANNER + '/';
    this.checkLoading = true;
    this.getMore = true;
    this.catalog = null;
    this.filterFlag = false;
    this.langFlag = false;
    this.sortFlag = false;
    this.channelFlag = false;
    this.contentFlag = false;
    this.dataLimit = 30;
    this.showingList = false;
  }

  ngOnInit() { }

  ngDoCheck() {
    if (!!this.coreservice.aircraft) {
      const change = this.differ.diff(this.coreservice);
      if (change) {
        change.forEachChangedItem(item => {
          if (item.currentValue != item.previousValue && item.key == 'aircraft') {
            this.cleanCall();
          }
        })
      }
    }
  }

  trackByFn(video: any) {
    return video.movie_id;
  }

  async getEpisodesData() {
    this.channelService.getChannels('season').then((res: any) => {
      this.channelItems = res;
      this.channelFlag = true;
    });
    this.sortingService.getFiltering('seasons').then((res: any) => {
      this.filtering = res;
      this.filterFlag = true;
      this.genreFilter = this.filtering[0].id;
      this.filter = this.genreFilter;
    });
    this.sortingService.getSorting('seasons').then((res: any) => {
      this.sorting = res;
      this.sortFlag = true;
      this.sortBy = this.sorting[0].id;
      for (var f in this.sorting) {
        if (this.sorting[f].id == "Added Date") {
          this.sortBy = this.sorting[f].id;
        }
      }
      this.sort = this.sortBy;
    });
    await this.sortingService.getFiltering('language/season').then((res: any) => {
      this.languages = res;
      this.langFlag = true;
      this.languageFilter = this.languages[0];
      this.language = this.languageFilter;
    });
    await this.sortingService.getAudienceList('tvshow/audience').then((res: any) => {
      this.audienceList = res;
  });
    if (window.location.pathname == '/content/seasons/all') {
      this.dataService.getMediaItems('tvshows', 'seasons', 0, 30, '', 'Added Date', '').then((res: any) => {
        this.contentItems = res;
        this.catalog = this.contentItems;
        this.contentFlag = true;
      });
    }
    else {
      this.dataService.getChanneledData('tvshows', 'seasons', 0, 30, '', 'Added Date', '', window.location.pathname.substr(window.location.pathname.lastIndexOf('/') + 1, window.location.pathname.length)).then((res: any) => {
        this.contentItems = res;
        this.catalog = this.contentItems;
        this.contentFlag = true;
      });
    }
  }

  getQueryParam(param: any): any {
    this.route.paramMap.subscribe(params => {
      return params.get(param);
    });
  }

  getSelectableSeasons() {
    var result = this.catalog.filter((season) => season.selectable == false);
    if (result.length > 0) {
      this.isUpdateAvailable = true;
    } else {
      this.isUpdateAvailable = false;
    }
    return this.catalog.filter((season) => season.selectable == true);
  }

  getNonSelectableSeasons() {
    var result = this.catalog.filter((season) => season.selectable == false);
    return result;
  }

  finished(checkLoading: any = true) {
    if (checkLoading == true) {
      this.increaseLimit();
      this.checkLoading = false;
    }
  };

  activate() {
    this.isPlaylist = !!this.getQueryParam('playlist_id') ? true : false;
    this.watchDatalimit();
  }

  showList() {
    if (!this.showingList) {
      this.showingList = true;
    }
  }

  showTiles() {
    if (this.showingList) {
      this.showingList = false;
    }
  }

  watchDatalimit() {
    this.dataLimit = 30;
    this.increaseLimit();
  }

  increaseLimit() {
    if (this.getMore) {
      var oldLimit = this.dataLimit;
      this.dataLimit += 30;
      this.getMoreItems(oldLimit, 30);
    }
  }

  getMoreItems(skip: any, take: any) {
    if (this.getMore) {
      if (!!this.getQueryParam('playlist_id')) {
        return this.playlistService.getPlaylistItems(this.getQueryParam('playlist_id'), 'tvshows', 'seasons', skip, take, this.filter, this.sort, this.language)
          .then((res: any) => {
            if (res.length >= 1) {
              return this.catalog = this.catalog.concat(res);
            } else {
              this.mediadriveService.getMediaDrive();
              this.getMore = false;
            }
          });
      } else {
        var channelname = window.location.pathname.substr(window.location.pathname.lastIndexOf('/') + 1, window.location.pathname.length)
        if (channelname != '') {
          return this.dataService.getChanneledData('tvshows', 'seasons', skip, take, this.filter, this.sort, this.language, channelname,this.audience)
            .then((res) => {
              if (res.length >= 1) {
                this.catalog = this.catalog.concat(res);
                this.finished();
              } else {
                this.mediadriveService.getMediaDrive();
                this.getMore = false;
              }
            });
        } else {
          return this.dataService.getMediaItems('tvshows', 'seasons', skip, take, this.filter, this.sort, this.language,this.audience)
            .then((res) => {
              if (res.length >= 1) {
                this.catalog = this.catalog.concat(res);
                this.finished();
              } else {
                this.mediadriveService.getMediaDrive();
                this.getMore = false;
              }
            });
        }
      }
    }
  }

  filterSelected() {
    this.cleanCall();
  }

  sortSelected() {
    this.cleanCall();
  }

  languageSelected() {
    this.cleanCall();
  }
  selectedData=[];
  audienceChange($event:any)
  {
    if($event.source._selected)
    {
      if($event.source.value=="All")
        this.selectedData.push("");
      else
        this.selectedData.push($event.source.value);
    }
    else
    {
      var index=this.selectedData.indexOf($event.source.value)
      if(index!=-1)
      {
        this.selectedData.splice(index,1);
      }
    }
    this.selectedAudience=this.selectedData.toString();
    this.cleanCall();
  }
  cleanCall() {
    var skip = 0;
    var take = 30;
    this.sort = this.sortBy;
    this.filter = this.genreFilter;
    this.language = this.languageFilter;
    this.audience=this.selectedAudience;
    this.dataLimit = 30;
    this.getMore = false;
    this.message = '';
    this.catalog = [];

    var mediaContainer = document.getElementsByClassName('media-files')[0];
    var mediaListContainer = document.getElementsByClassName('double-small')[0];

    if (!!mediaContainer && !!mediaListContainer) {
      mediaContainer.scrollTop = 0;
      mediaListContainer.scrollTop = 0;
    } else if (!!mediaContainer) {
      mediaContainer.scrollTop = 0;
    }

    this.isLoading = true;

    this.channelService.getChannels('season').then((res) => {
      return this.channelItems = res;
    })

    if (!!this.getQueryParam('playlist_id')) {
      return this.playlistService.getPlaylistItems(!!this.getQueryParam('playlist_id'), 'tvshows', 'seasons', skip, take, this.filter, this.sort, this.language)
        .then((res) => {
          if (res.length >= 1) {
            this.getMore = true;
            this.message = '';
            return this.catalog = res;
          } else {
            this.getMore = false;
            this.mediadriveService.getMediaDrive();
            this.message = 'no_results';
            return this.catalog = [];
          }
        });
    } else {
      var channelname = window.location.pathname.substr(window.location.pathname.lastIndexOf('/') + 1, window.location.pathname.length)
      if (channelname != '') {
        return this.dataService.getChanneledData('tvshows', 'seasons', skip, take, this.filter, this.sort, this.language, channelname,this.audience)
          .then((res) => {
            if (res.length >= 1) {
              this.getMore = true;
              this.message = '';
              this.finished();
              return this.catalog = res;
            } else {
              this.getMore = false;
              this.mediadriveService.getMediaDrive();
              this.message = 'no_results';
              return this.catalog = [];
            }
          });
      } else {
        return this.dataService.getMediaItems('tvshows', 'seasons', skip, take, this.filter, this.sort, this.language,this.audience)
          .then((res) => {
            if (res.length >= 1) {
              this.getMore = true;
              this.message = '';
              this.finished();
              return this.catalog = res;
            } else {
              this.getMore = false;
              this.mediadriveService.getMediaDrive();
              this.message = 'no_results';
              return this.catalog = [];
            }
          });
      }
    }
  }

  getDetails(video: any) {
    this.getEpisodesDetails(video);
  }

  getEpisodes(video: any) {
    var tvshowId = video.tvshow_id;
    var seasonId = video.season_id;
    var url: string = '';
    var params: string = this.getQueryParam('playlist_id');

    if (params) {
      url = settings.configuration.env.apiUrl + 'playlist/' + params + '/tvshow/' + tvshowId + '/season/' + seasonId;
    } else {
      url = settings.configuration.env.apiUrl + 'content/tvshow/' + tvshowId + '/season/' + seasonId;
    }

    return this.http
      .get(url)
      .toPromise().then(
        (res: any) => { return res }
      )
  }

  async getEpisodesDetails(video: any) {
    var episodeDetails;
    if (!this.blockUI) {
      this.blockUI = true;
      await this.getEpisodes(video).then((res: any) => {
        episodeDetails = res;
      });
      var dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        episodeDetails: episodeDetails,
        isUpdateAvailable: video.selectable
      };
      dialogConfig.panelClass = ['matdialog-custom-dialog', 'episode-width'];
      let moviedialog = this.dialog.open(EpisodeDialog, dialogConfig);
      moviedialog.beforeClosed().subscribe(res => {
        this.blockUI = false;
      })
    }
  }

  selectItem(video: any, index: any) {
    var seasonIndex = index;
    var status = video.is_selected;
    var boolean;

    switch (status) {
      case 0:
        boolean = true;
        break;
      case 1:
        boolean = false;
        break;
      case 4:
        boolean = false;
        break;
      default:
        boolean = true;
        break;
    }

    this.selectItems.addToListSeason(video, 'tvshow', 'season', boolean, this.catalog[seasonIndex]).then((res) => {
      //For Future use
      //var newStatus = res.is_selected;
      //this.catalog[seasonIndex].is_selected = newStatus;
    });
  }

  selectedStatus(tvshowId: any, seasonId: any, contentType: any, type: any) {
    var url;
    var newStatus;
    var params: string = this.getQueryParam('playlist_id');

    if (params) {
      url = settings.configuration.env.apiUrl + 'playlist/' + params + '/' + contentType + '/' + tvshowId + '/' + type + '/' + seasonId;
    } else {
      url = settings.configuration.env.apiUrl + 'content/' + contentType + '/' + tvshowId + '/' + type + '/' + seasonId;
    }

    return this.http
      .get(url)
      .toPromise().then(
        (res: any) => {
          newStatus = res.is_selected;
          _.find(this.catalog, { tvshow_id: tvshowId, season_id: seasonId }).is_selected = newStatus;
        }
      )
  }
}

angular
  .module('app.video')
  .directive(
    'seriescontent',
    downgradeComponent({ component: SeriescontentComponent }) as angular.IDirectiveFactory
  );

