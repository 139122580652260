import { Component, OnInit, Input, SimpleChange, SimpleChanges, KeyValueDiffers, DoCheck } from '@angular/core';
declare var angular: angular.IAngularStatic;
import settings from '../../../config.json';
import { Config }  from '../core/config';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { SelectItemsService } from '../core/services/select-items.service';
import { SelectedContentService } from '../core/services/selected-content.service';
import { FeedService } from '../core/services/feed.service';
import { CoreService } from '../core/services/core.service';
import { AuthorizedService } from '../core/services/authorized.service';
import { HttpClient } from '@angular/common/http';
import {MatDialog, MatDialogConfig } from "@angular/material";
import { MovieDialog } from '../video/movie-dialog.component';
import { EpisodeDialog } from '../video/episode-dialog.component';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
  selector: 'selected',
  templateUrl: './selected.component.html',
  styleUrls: ['./selected.component.less']
})
export class SelectedComponent implements DoCheck {

  movies:Array<object> = [];
  episodes:Array<object> = [];
  tracks:Array<object> = [];
  games:Array<object> = [];
  magazines:Array<object> = [];
  destinations:Array<object> = [];
  folders:Array<object> = [];
  news:Array<object> = [];
  stocks:Array<object> = [];
  sports:Array<object> = [];
  differ:any;
  spinner:boolean = false;
  load_movie:boolean = true;
  load_episodes:boolean = true;
  load_tracks:boolean = true;
  load_games:boolean = true;
  load_magazines:boolean = true;
  load_destinations:boolean = true;
  load_folders:boolean = true;
  load_news:boolean = true;
  load_stocks:boolean = true;
  load_sports:boolean = true;
  is_aircraft:boolean = true;
  videoImage;
  episodeImage;
  dateTime;
  blockUI;
  isPlaylist;
  constructor(private config: Config,
              private route: ActivatedRoute,
              private router: Router,
              private selectItems: SelectItemsService,
              private selectedContent: SelectedContentService,
              private feedService: FeedService,
              private coreservice: CoreService,
              private differs: KeyValueDiffers,
              private dialog: MatDialog,
              private http: HttpClient,
              public authorizedService: AuthorizedService) { 

              this.differ = this.differs.find({}).create();
              this.videoImage = settings.configuration.env.apiUrl + 'images/movies/' + this.config.IMAGE_FORMATS.MOVIES_LIST_SMALL + '/';
              this.episodeImage = settings.configuration.env.apiUrl + 'images/tvshows/' + this.config.IMAGE_FORMATS.TV_LIST_SMALL + '/';
              this.dateTime = settings.configuration.datetime_formats;
              this.blockUI = false;
  
              if (!!this.coreservice.aircraft){
                this.getAllAssests();
              }
              else {
                this.is_aircraft = false;
              }
            }



  ngDoCheck(){
    if (!!this.coreservice.aircraft) {
      const change = this.differ.diff(this.coreservice);
      if (change) {
        change.forEachChangedItem(item => {
          if (item.currentValue != item.previousValue && item.key == 'aircraft') {
            this.router.navigate([window.location.pathname]);
            //window.location.reload();
          }
        })
      }
    }
  }

  getMovies(videoId) {
    var url: string = '';
    var params: string = this.getQueryParam();
    if (params) {
        url = settings.configuration.env.apiUrl + 'playlist/' + params + '/video/movie/' + videoId;
    } else {
        url = settings.configuration.env.apiUrl + 'content/video/movie/' + videoId;
    }

    return this.http
        .get(url)
        .toPromise().then(
            (res: any) => { return res },
            null
        )
  }
  getEpisodes(video) {
      var url = '';
      var tvshowId = video.tvshow_id;
      var seasonId = video.season_id;      
      if (this.getQueryParam()) {
          url = settings.configuration.env.apiUrl + 'playlist/' + this.getQueryParam() + '/tvshow/' + tvshowId + '/season/' + seasonId;
      } else {
          url = settings.configuration.env.apiUrl + 'content/tvshow/' + tvshowId + '/season/' + seasonId;
      }
      return this.http.get(url)
          .toPromise()
          .then((res: any) => {
              return res;
          });
  }

 getAllAssests(){
    this.selectedContent.getSelectedMovies().then((res: any) => {
      this.movies = res;
      this.load_movie = false;

    });
    this.selectedContent.getSelectedEpisodes().then((res: any) => {
      this.episodes = res;
      this.load_episodes = false;
    });

    this.selectedContent.getSelectedTrackes().then((res: any) => {
      this.tracks = res;
      this.load_tracks = false;
    });

    this.selectedContent.getSelectedGames().then((res: any) => {
      this.games = res;
      this.load_games = false;
    });

    this.selectedContent.getSelectedMagazines().then((res: any) => {
      this.magazines = res;
      this.load_magazines = false;
    });

    this.selectedContent.getSelectedDestinations().then((res: any) => {
      this.destinations = res;
      this.load_destinations = false;
    });

    this.selectedContent.getSelectedFolders().then((res: any) => {
      this.folders = res;
      this.load_folders = false;
    });

    this.selectedContent.getSelectedNews().then((res: any) => {
      this.news = res;
      this.load_news = false;
    });

    this.selectedContent.getSelectedStocks().then((res: any) => {
      this.stocks = res;
      this.load_stocks = false;
    });

    this.selectedContent.getSelectedSports().then((res: any) => {
      this.sports = res;
      this.load_sports = false;
    });
    this.spinner = true;
  }

  getQueryParam(): any {
    this.route.paramMap.subscribe(params => {
        console.log("params", params);
        console.log("queryparam", params.get('playlist_id'));
        return params.get('playlist_id');
    });
  }

  activate() {
    if (this.getQueryParam()) {
        this.isPlaylist = true;
    } else {
        this.isPlaylist = false;
    }
  }

  removeMovie(movie_id, index) {
    this.selectItems.addToList(movie_id, 'movie', 'video', false, null);
    this.movies.splice(index, 1);
  }

  removeTrack(track, index) {
    this.selectItems.addToListTrack(track, 'music', 'album', 'track', false);
    this.tracks.splice(index, 1);
  }

  removeEpisode(episode, index) {
    var tvshowId = episode.tvshow_id;
    var seasonId = episode.season_id;
    var episodeId = episode.episode_id;
    this.selectItems.addToListEpisode(tvshowId, seasonId, episodeId, 'tvshow', 'season', 'episode', false);
    this.episodes.splice(index, 1);
  }

  removeGame(game, index) {
    var gameId = game.game_id;
    this.selectItems.addToList(gameId, 'game', 'games', false, null);
    this.games.splice(index, 1);
  }

  removeMagazines(magazine, index) {
    var magazineId = magazine.magazine_id;
    this.selectItems.addToList(magazineId, 'magazine', 'magazines', false, null);
    this.magazines.splice(index, 1);
  }

  removeDestinations(destination, index) {
    var destinationId = destination.destination_id;
    this.selectItems.addToList(destinationId, 'destination', 'destinations', false, null);
    this.destinations.splice(index, 1);
  }

   removeDocument(folder, index) {
    var folderId = folder.name;
    this.selectItems.addToList(folderId, 'folder', 'documents', false, null);
    this.folders.splice(index, 1);
  }

  removeStock(stock, index) {
    var stockId = stock.id;
    this.feedService.selectStock(stockId, false);
    this.stocks.splice(index, 1);
  }

  removeNews(news, index) {
    var newsId = news.topic_name;
    var feedId = news.feed_name;
    var sourceId = news.source_name;
    this.feedService.removeNews(newsId, feedId, 'source', false, sourceId);
    this.news.splice(index, 1);
}

  removeSports(sport, index) {
    var sportId = sport.topic_name;
    var feedId = sport.feed_name;
    var sourceId = sport.source_name;
    this.selectItems.addSportTolist(feedId, sportId, false, sourceId);
    this.sports.splice(index, 1);
  }

  // Function is not yet implemented in angularJS code
  getMagazinesDetails(magazine) {
    //TO DO
  }

  // Function is not yet implemented in angularJS code
  getDestinationsDetails(destination) {
    //TO DO
  }

  async getMovieDetails(videoId) {
    var movieDetails;
    if (!this.blockUI) {
      this.blockUI = true;
      await this.getMovies(videoId).then((res: any) => {
        movieDetails = res;
      });
      var dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        movieDetails: movieDetails
      };
      dialogConfig.panelClass = ['matdialog-custom-dialog', 'video-width'];
      let moviedialog = this.dialog.open(MovieDialog, dialogConfig);
      moviedialog.beforeClosed().subscribe(res => {
        this.blockUI = false;
      })
    }
  }

  async getSeriesDetails(video) {
    var episode;
    if (!this.blockUI) {
      this.blockUI = true;
      await this.getEpisodes(video).then((res:any) => {
        episode = res;
      });
      var dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        episodes: episode
      };
      dialogConfig.panelClass = ['matdialog-custom-dialog', 'episode-width'];
      let episodedialog = this.dialog.open(EpisodeDialog, dialogConfig);
      episodedialog.beforeClosed().subscribe(res => {
      this.blockUI = false;
      });
    }
  }
}

angular
  .module('app.selected')
  .directive(
    'selected',
    downgradeComponent({component: SelectedComponent}) as angular.IDirectiveFactory
  );
