import { Component, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
declare var angular: angular.IAngularStatic;
import settings from '../../../config.json';
import { AuthorizedService } from '../core/services/authorized.service';
import { OperatorService } from '../core/services/operator.service';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { OperatorDialogComponent } from './operator-dialog.component';
import { CoreService } from '../core/services/core.service';
import { OperatorConfirmDialogComponent } from './operator-confirm-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.less'],
  styles: [':host { width: -webkit-fill-available; }']
})

export class AdminComponent implements OnInit {

  public image: string = settings.configuration.env.apiUrl + 'images/operators/';
  public catalog: any = [];
  public timeStamp;
  public blockUI: boolean;
  public message: string;
  private contentItems: any = [];
  public content_search;
  public accountTypes: any = [];
  public accountType: any = [];
  public accountSelected: any;
  public filterAccountType: any;

  constructor(public authorizedService: AuthorizedService,
    public operatorService: OperatorService,
    private dialog: MatDialog,
    private router: Router,
    public coreservice: CoreService,) {
      this.timeStamp = '?=' + new Date().getTime();
      this.blockUI = false;

      this.filterAccountType = 'All';

      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      }
      this.router.onSameUrlNavigation = 'reload';

      this.operatorService.getAllAccountTypes().then((res: any) => {
        this.accountTypes = res;
        console.log("this.accountTypes", this.accountTypes);
      });

      this.accountType = this.accountTypes
      this.accountSelected = this.accountTypes;

      this.operatorService.getAllOperators().then((res: any) => {
        this.contentItems = res;
        this.catalog = this.contentItems;
        console.log("this.catalog", this.catalog);
      });
  }

  ngOnInit() {
  }

  accountTypeSelected(){
    this.accountType = this.accountSelected;
  }

  async addOperator() {
    if (!this.blockUI) {
      this.blockUI = true;
      var dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        operator: '',
        tabName: 'operator',
        selectedAccountType: 'Customer',
        accountTypes: this.accountTypes
      };

      dialogConfig.panelClass = ['matdialog-custom-dialog', 'edit-aircraft-width', 'dialog'];
      let OperatorDialog = this.dialog.open(OperatorDialogComponent, dialogConfig);
      OperatorDialog.beforeClosed().subscribe(res => {
        this.timeStamp = '?=' + new Date().getTime();
        this.cleanCall();
        this.blockUI = false;
      });
    }
  }

  /* Set selected operator name in operator page */
  setAircraftOperatorName(operator) {
    this.coreservice.operatorName = operator;
    this.router.navigateByUrl('/aircrafts');
  }

  /* Set selected operator name in aircraft page */
  setUserOperatorName(operator) {
    this.coreservice.operatorName = operator;
    this.router.navigateByUrl('/users');
  }

  deleteOperator(operatorId, index) {
    if (!this.blockUI) {
        this.blockUI = true;
        var dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
          operatorId: operatorId
        }
        dialogConfig.panelClass = ['matdialog-custom-dialog', 'confirm-dialog', 'dialog'];
        let OperatorConfirmDialog = this.dialog.open(OperatorConfirmDialogComponent, dialogConfig);
        OperatorConfirmDialog.beforeClosed().subscribe(res => {
            this.blockUI = false;
            this.cleanCall();
        });
    }
  }

  openDialog(operatorId, tabName, accountType) {
    if (!this.blockUI) {
        this.blockUI = true;
        var dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
          operatorId: operatorId,
          tabName: tabName,
          selectedAccountType: accountType,
          accountTypes: this.accountTypes
        };

        dialogConfig.panelClass = ['matdialog-custom-dialog', 'edit-aircraft-width', 'dialog'];
        let OperatorDialog = this.dialog.open(OperatorDialogComponent, dialogConfig);
        OperatorDialog.beforeClosed().subscribe(res => {
          this.timeStamp = '?=' + new Date().getTime();
          this.cleanCall();
          this.blockUI = false;
        });
    }
  }

  cleanCall() {
    this.message = '';
    return this.operatorService.getAllOperators()
        .then((res) => {
          if (res != null) {
            return this.catalog = res;
          } else {
              this.message = 'no_results';
              return this.catalog = [];
          }
        });
  }
}

angular
  .module('app.admin')
  .directive(
    'admin',
    downgradeComponent({component: AdminComponent}) as angular.IDirectiveFactory
  );
