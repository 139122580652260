import { Injectable } from '@angular/core';
declare var angular: any;
import { downgradeInjectable } from '@angular/upgrade/static';
import {HttpClient} from '@angular/common/http';
import settings from '../../../../config.json';
import * as _ from "lodash";

@Injectable({
  providedIn: 'root'
})

export class AuthorizedService {

  public userRoles: string = '';
  public hasLoadedRoles: boolean = false;

  constructor(
    private http: HttpClient
    ) { }
    
  getUserPrivileges() {
    var url: string = settings.configuration.env.apiUrl + 'sections';
    return this.http
      .get(url)
      .toPromise()
      .then((res: string) => {
        this.hasLoadedRoles = true;
        this.userRoles = res;
        return this.userRoles;
      }, 
     (err: string) => {
        this.hasLoadedRoles = false;
        return;
    });
  }

  isAuthorized(authorizedRoles: string, permissionType: string) {
    var status: boolean = false;
    var userRoles: any = this.userRoles;

    if (permissionType == 'atLeastOne') {
        var compare: string = _.intersection(userRoles, authorizedRoles);

        if (compare.length >= 1) {
            return true;
        } else {
            return false;
        }
    } else if (permissionType == 'onlyOne') {
          //var status = _.contains(userRoles, authorizedRoles[0]);
          status = _.includes(userRoles, authorizedRoles[0]);
          return status;
    }
  }

  checkIfAuthorized(authorizedRole: any, permissionType: string) {
    var userRoles: any = this.userRoles;
    var status: boolean = true;

    switch (true) {
      case (!this.hasLoadedRoles):
        this.hasLoadedRoles = true;
        this.getUserPrivileges()
          .then((res: any) => {
              if (res) {
                return this.isAuthorized(authorizedRole, permissionType);
              }
            }
          )
        // this.getUserPrivileges().then(function () {
        //   console.log("in then") ;
        //   return this.isAuthorized(authorizedRole, permissionType);
        // });
        break;
      default:
        return this.isAuthorized(authorizedRole, permissionType);
    }
  }
}

angular
  .module('app.core')
  .factory('authorizedService', downgradeInjectable(AuthorizedService));
