import { Injectable } from '@angular/core';
import settings from '../../../../config.json';
import { EventManager } from '@angular/platform-browser';

class SystemEvent {
    public name: string;
    public value: any;
    public timestamp: string;

    constructor(name: string, value: any, timestamp: string) {
        this.name = name;
        this.value = value;
        this.timestamp = timestamp;
    }
}

class RCHost {

    private version: string;
    private rcAppOrigin: string;
    private currentSettings: SystemEvent[];
    private subscriptions: any[];

    constructor(rcAppOrigin: string) {
        this.version = "1.6.35.0";
        this.rcAppOrigin = rcAppOrigin;
        this.currentSettings = [];
        this.subscriptions = [];
    }

    getSystemEvent(name: string, value: any, timestamp: string) {
        return (new SystemEvent(name, value, timestamp));
    }
      onSystemEvents(events: any) {

        var systemEvents = JSON.parse(events);
        for (var i = 0; i < systemEvents.length; i++) {
            var systemEvent = systemEvents[i];
            var value = systemEvent['value'];
            switch (systemEvent["name"]) {
                case "":
                    // TODO - HANDLE
                    break;
                default:
                    // TODO - HANDLE
                    break;
            }
        }
    }

    pushSystemEventsToClient(app: any, events: any) {
        if (app) {
            app.postMessage(encodeURI(JSON.stringify({
                    "function": "onSystemEvents",
                    "arguments": [JSON.stringify(events)]
                })),
                this.rcAppOrigin);
        }
    }

    onSubscribing() {
        var _this = this;
        this.handleSubscribe(function (app) {
            if (app) {
                // add app reference
                _this.subscriptions.push(app);
            }
        });
    }

    handleSubscribe(callback) {
        callback(null);
    }
    
    onUnsubscribing() {
        var _this = this;
        this.handleUnsubscribe(function (app) {
            if (app) {
                // remove app reference
            }
        });
    }

    handleUnsubscribe (callback) {
        callback(null);
    }

    createSystemEvents() {
        var events = [];
        for (var i = 0; i < this.currentSettings.length; i++) {
            var setting = this.currentSettings[i];
            events.push(new SystemEvent(setting["name"],
                setting["values"][setting["current"]],
                null));
        }
        return events;
    }
}



@Injectable({
  providedIn: 'root'
})

export class BridgeService {

  //private hasAppOrigin: boolean = false;
  private rcAppOrigin: string;
  public RCHOST: RCHost;

  constructor(
      private eventManager: EventManager
    ) {
        eventManager.addGlobalEventListener('window', 'message', this.handleEvent.bind(this));
        this.rcAppOrigin = settings.configuration.env.appOrigin;
        this.RCHOST = new RCHost(this.rcAppOrigin)
  }

  handleEvent(event: any) {
       this.parseData(event);
  }

  parseData(event: any) {
    // check origin
    var origin: string = event.origin || event.originalEvent.origin;

    if (origin !== this.rcAppOrigin) {
      return;
    }

    // process message
    var message = JSON.parse(decodeURI(event.data));
    var function_name = message["function"];
    var function_arguments = message["arguments"];

    if (function_name == "onSubscribing") {
      this.RCHOST.onSubscribing();
    } else if (function_name == "onSystemEvents") {
      this.RCHOST.onSystemEvents(function_arguments[0]);
    }
  }
}
