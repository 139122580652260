import { Component, OnInit, DoCheck , KeyValueDiffers} from '@angular/core';
import { MoviecontentComponent } from '../../video/moviecontent.component';
import { MusiccontentComponent } from '../../music/musiccontent.component';
import { SeriescontentComponent } from '../../video/seriescontent.component';
import { CoreService } from '../../core/services/core.service';

@Component({
  selector: 'text-loader',
  template: '<div class="textloader"><span translate="textloading"></span></div>',
  //template: '<div style= " height: 30px;position: absolute;top: 0;left: 0;background: white;padding: 10px 20px; right: 0;bottom: 0;z-index: 1;"><span translate="textloading"></span></div>',
  styleUrls: ['../../core/config.less']
})
export class TextLoaderComponent implements OnInit,DoCheck {

  differ:any;
  catalogMovie:any;
  catalogMusic:any;
  catalogSeries:any;

  constructor(private movie: MoviecontentComponent,
              private series: SeriescontentComponent,
              private music: MusiccontentComponent,
              private differs: KeyValueDiffers,
              private coreservice: CoreService) { 
      this.differ = this.differs.find({}).create();
  }

  ngOnInit() {}
    
  ngDoCheck(){
      this.movieLoader();
      this.seriesLoader();
      this.musicLoader();
  }

  movieLoader() {
      this.catalogMovie = this.movie.catalog;
      var selects = document.querySelectorAll('.custom-select > select');
      for (var i = 0; i < selects.length; i++) {
          selects[i].addEventListener('change', (event) => {
            this.coreservice.textloader = true;
          });
      }

      const change = this.differ.diff(this);
      if (change) {
          change.forEachChangedItem(item => {
            if (item.key == 'catalogMovie') {
                this.coreservice.textloader = false;
            }
          })
      }
  }

  seriesLoader() {
    this.catalogSeries = this.series.catalog;
    var selects = document.querySelectorAll('.custom-select > select');
    for (var i = 0; i < selects.length; i++) {
        selects[i].addEventListener('change', (event) => {
            this.coreservice.textloader = true;
        });
    }

    const changeSeries = this.differ.diff(this);
    if (changeSeries) {
      changeSeries.forEachChangedItem(item => {
        if (item.key == 'catalogSeries') {
            this.coreservice.textloader = false;
        }
      })
    }
  }

  musicLoader() {
      this.catalogMusic = this.music.catalog;
      var selects = document.querySelectorAll('.custom-select > select');
      for (var i = 0; i < selects.length; i++) {
          selects[i].addEventListener('changeMusic', (event) => {
              this.coreservice.textloader = true;
          });
      }

      const changeMusic = this.differ.diff(this);
      if (changeMusic) {
        changeMusic.forEachChangedItem(item => {
          if (item.key == 'catalogMusic') {
              this.coreservice.textloader = false;
          }
        })
      }
  }
}

  
