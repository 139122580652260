import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import * as _ from "lodash";
import settings from '../../../config.json';
import { AircraftService } from '../core/services/aircraft.service';
import { ToastService } from '../core/services/toast.service';

@Component({
  selector: 'select-aircraft',
  templateUrl: './select-aircraft.component.html',
  styleUrls: ['../../app/aircraft/aircraft.less']
})
export class SelectAircraft implements OnInit {

  image:string;
  aircrafts:any;
  fleetMode:boolean;
  public primaryFleet;
  public secondaryFleet;

  constructor(private aircraftService: AircraftService,
              private toastservice: ToastService,
              private selectaircraftdialog: MatDialogRef<SelectAircraft>,
              @Inject(MAT_DIALOG_DATA) private dialogData: any) {
    this.image = settings.configuration.env.apiUrl + 'images/fleet/';
    this.primaryFleet = 0;
    this.secondaryFleet = 1;
   }

  ngOnInit() {
    this.aircrafts = this.dialogData.aircrafts;
    this.fleetMode = this.dialogData.fleetMode;
  }

  selectAircraft(aircraft) {
    this.aircraftService.setAircraft(aircraft);

    var oldAircrafts = localStorage.getItem('aircrafts in use');
    var id = aircraft.id;

    if(_.includes(oldAircrafts, id)) {
      this.toastservice.toastError("aircraft you are operating is in use by someone else", "operator_management");
    }
  }

  closeThisDialog() {
    this.selectaircraftdialog.close();
  }

}
