import { Component, OnInit } from '@angular/core';
import settings from '../../../config.json';
import { UserService } from '../core/services/user.service';
import { AuthService } from '../core/services/auth.service';
import { ToastService } from '../core/services/toast.service';
import { CoreService } from '../core/services/core.service';
import { BridgeService } from '../core/services/bridge.service';
import { MatDialogRef } from '@angular/material';

var _app_data: any;

@Component({
  //selector: 'app-user-agreement',
  templateUrl: './user-agreement.component.html',
  styleUrls: ['./user-agreement.component.less']
})

export class LoginUserAgreementComponent implements OnInit {

  userAgreement: any;
  withActions: boolean;
  userAgreementAppName: string;
  userAgreementAppFilePath: string;
  terms: string;
  showAgreementLoading: boolean;

  constructor(
    public dialogRef: MatDialogRef<LoginUserAgreementComponent>,
    private userService: UserService,
    private authService: AuthService,
    private toastService: ToastService,
    private coreservice: CoreService,
    private bridgeService: BridgeService
  ) {
        this.withActions = true;
        this.showAgreementLoading = true;
  }

  async ngOnInit() {
    this.userAgreement = await this.userService.getUserAgreement();
    _app_data = this.userAgreement.app_data;
    this.userAgreementAppName = this.userAgreement.name;
    this.userAgreementAppFilePath = this.userAgreement.url;

    if (this.coreservice.showingFalconUser) {
      this.terms = settings.configuration.env.apiUrl + 'terms/term/' + this.userAgreementAppName + '/' + this.userAgreementAppFilePath + '?rc-host-origin=' + settings.configuration.ssoconfig.dassaultHostOrigin;
    } else {
      this.terms = settings.configuration.env.apiUrl + 'terms/term/' + this.userAgreementAppName + '/' + this.userAgreementAppFilePath + '?rc-host-origin=' + settings.configuration.env.hostOrigin;
    }

    //this.terms = 'http://www.terms.com:6200/terms.html' + '?rc-host-origin=' + settings.configuration.env.hostOrigin;

    //this.bridgeService.RCHOST.handleSubscribe = ((callback: any) => this.handleSubscribe(callback)).bind(this);
    this.bridgeService.RCHOST.handleSubscribe = this.handleSubscribe;
    this.bridgeService.RCHOST.onSystemEvents = this.onSystemEvents.bind(this);

    this.bridgeService.RCHOST.handleSubscribe(null);
  }

  handleSubscribe(callback: any) {
    var _this: any = this;
    function getApplicationsIFrame() {
        return document.getElementById('rc-app-iframe');
    }

    function getApplicationIFrameContent() {
        return getApplicationsIFrame() ? (<HTMLIFrameElement> getApplicationsIFrame()).contentWindow : null;
    }

    function pushEvents() {
      if(!callback) {
        return;
      }

      var applications_iframe = getApplicationsIFrame();
      if (applications_iframe) {
        var app = getApplicationIFrameContent()
        if(app) {
            var settings = _this.createSystemEvents();
            settings.push(_this.getSystemEvent('metadata', {'app_data': _app_data}, null));
            _this.pushSystemEventsToClient(app, settings);
            callback(app);
            return;
        }
      }
    }

    pushEvents();
  }

  onSystemEvents (events: any) {
    var systemEvents = JSON.parse(events);
    for (var i = 0; i < systemEvents.length; i++) {
        var systemEvent = systemEvents[i];
        var value = systemEvent['value'];
        switch (systemEvent["name"]) {
            case "contentLoaded":
                setTimeout(() => {
                    this.showAgreementLoading = false;
                }, 1000);
                break;
            default:
                // TODO - HANDLE
                break;
        }
    }
  }

  agreementConfirmed() {
    return this.userService.acceptUserAgreement(this.userAgreement.id)
      .then(
          (res: any) => {
            if (res.status == 200) {
              this.toastService.toastSuccess("success_agreement_accepted", "success");
              this.dialogRef.close(true);
              this.coreservice.readyToAuth = false;
            } else {
              this.toastService.toastError("error_agreement_accept", "error");
              this.authService.logOut();
            }
      });
  }

  decline() {
    this.dialogRef.close(false);
    this.authService.logOut();
  }
}
