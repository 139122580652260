import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import strings from '../../../assets/locale-en.json';

@Component({
    selector: 'sync-preference-change-dialog',
    templateUrl: './sync-preference-change-dialog.component.html',
    styleUrls: ['./../layout/dialog-main.less']
})
export class SyncPreferenceChangeDialogComponent implements OnInit {

    title: string = '';
    connection_type: string = '';
    selectedStatus: number = 0;
    status: string = '';
    warning: boolean = false;
    radioSelected:any = '';	
    groupingFlag:boolean = false;

    constructor(
        public dialogRef: MatDialogRef<SyncPreferenceChangeDialogComponent>,
         @Inject(MAT_DIALOG_DATA) private dialogData: any
    ) { }

    ngOnInit() {
        this.title = this.dialogData.title;
        this.connection_type = this.dialogData.connection_type;
        this.selectedStatus = this.dialogData.selectedStatus;
        this.radioSelected = strings.existing_selection;

        if(this.title == strings.news)
        {
          this.groupingFlag = true;
        }
  
        if(this.selectedStatus === 0) {
          this.status = strings.enable;
          this.warning = true;
        }
        else {
          this.status = strings.disable;
          this.warning = false;
        }
    }

    onConfirm() {
      this.dialogRef.close({event : strings.event_close, data:this.radioSelected});
    }

    onCancel() {
      this.dialogRef.close({event : strings.event_cancel});
    }
}
