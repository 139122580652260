import { Injectable } from '@angular/core';
declare var angular: any;
import settings from '../../../../config.json';
import { HttpClient } from '@angular/common/http';
import { MediadriveService } from '../services/mediadrive.service';
import { ToastService } from '../services/toast.service';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as _ from "lodash";
import { switchMap } from "rxjs/operators"

@Injectable({
  providedIn: 'root'
})
export class SelectItemsService {

  playlist_id_param: any;
  name: any;
  constructor(private http: HttpClient,
              private mediadriveService: MediadriveService,
              private route: ActivatedRoute,
              private router: Router,
              private toastService: ToastService) { }

  getQueryParam(): any {
    this.route.paramMap.subscribe(params => {
        console.log("params", params);
        console.log("queryparam", params.get('playlist_id'));
        return params.get('playlist_id');
        });
    }
    
  addSportCategoryTolist(feed_id: string, boolean, category: string) {
    var url: string = settings.configuration.env.apiUrl + 'content/feeds/feed/' + feed_id + '/categories/select/' + boolean + '/category/' + category;
    console.log("addSportCategoryTolist");
    return this.http.post(url, null)
        .toPromise().then((data: any) => {
          if (this.playlist_id_param) {
            this.mediadriveService.getPlaylistSize(this.playlist_id_param);
        } else {
            this.mediadriveService.getMediaDrive();
          } 
          return data;
        },
        (err: any) => {
            console.log("Server Error");
        });
  }

  addSportTolist(feed_id: string, feed_topic_name: string, boolean, name: string) {
    var url = settings.configuration.env.apiUrl + 'content/feeds/feed/' + feed_id + '/topics/topic/' + feed_topic_name + '/sources/select/' + boolean + '/source/' + name;
    console.log("addSportTolist");
    return this.http.post(url, null)
        .toPromise().then((data: any) => {
          if (this.getQueryParam()) {
            this.mediadriveService.getPlaylistSize(this.playlist_id_param);
        } else {
            this.mediadriveService.getMediaDrive();
          } 
          return data;
        },
        (err: any) => {
            console.log("Server Error");
        });
}

   addToList(item_id: string, type: string, contentType: string, boolean, item_child_id: string) {
    var url = '';
    if (this.getQueryParam()) {
        url = settings.configuration.env.apiUrl + 'playlist/' + this.playlist_id_param + '/' + contentType + '/select/' + boolean + '/' + type + '/' + item_id;

     } else if (window.location.pathname == '/playlist') {
         console.log("addToList else if 1");
         url = settings.configuration.env.apiUrl + contentType + '/select/' + boolean + '/' + type + '/' + item_id;

        // if feed topic
    } else if (window.location.pathname.includes('/feed')) {
        if (contentType == 'topic') {
            url = settings.configuration.env.apiUrl + 'content/feeds/feed/' + type + '/topics/select/' + boolean + '/topic/' + item_id;
        } else if (contentType == 'source') {
            url = settings.configuration.env.apiUrl + 'content/feeds/feed/' + type + '/topics/topic/' + item_id + '/sources/select/' + boolean + '/source/' + item_child_id;
        }
        // if subscription
    } else if (window.location.pathname.includes('aircrafts') && contentType == 'isSubscription') {
        url = settings.configuration.env.apiUrl + 'content/feeds/select/' + boolean + '/' + 'feed/' + item_id + '/aircraft/' + type;
    } else {
        url = settings.configuration.env.apiUrl + 'content/' + contentType + '/select/' + boolean + '/' + type + '/' + item_id;
    }

    var itemId = document.getElementById(item_id);
    var itemId_wide = document.getElementById('wide_' + item_id);
    return this.http.post(url, null)
        .toPromise().then(async (data: any) => {
          await this.mediadriveService.checkStorageLimit().then((flag_data: boolean) => {
            if (flag_data == true) {
                data.is_selected = 0;
                
                //Send url to deselect this asset in database
                this.addToList(item_id, type, contentType, false, item_child_id);
                this.toastService.toastWarning('media_storage_limit', 'warning');             
                
            }
            else {
                if (this.getQueryParam()) {
                    console.log("addToList post if");
                    this.mediadriveService.getPlaylistSize(this.getQueryParam());
                } else {
                    console.log("addToList post else");
                    this.mediadriveService.getMediaDrive();
                }
                // Mainly for overlays
                if (data.is_selected == 1 || data.is_selected == 4) {
                    if (!!itemId && !!itemId_wide) {
                        itemId.classList.remove('partiallySelected');
                        itemId.classList.add('itemSelected');
                        itemId_wide.classList.remove('partiallySelected');
                        itemId_wide.classList.add('itemSelected');
                    } else if (!!itemId) {
                        itemId.classList.remove('partiallySelected');
                        itemId.classList.add('itemSelected');
                    }
                } else if (data.is_selected == 0) {
                    if (!!itemId && !!itemId_wide) {
                        itemId.classList.remove('itemSelected');
                        itemId.classList.remove('partiallySelected');
                        itemId_wide.classList.remove('itemSelected');
                        itemId_wide.classList.remove('partiallySelected');
                    } else if (!!itemId) {
                        itemId.classList.remove('itemSelected');
                        itemId.classList.remove('partiallySelected');
                    }
                }
            }         
          });    
          return data;    
        },(err: any) => {
             console.log("Server Error"); 
        }); 
  }

  addToListSeason(video, contentType: string, type: string, boolean, catalogItem=null) {
    var tvshowId = video.tvshow_id;
    var seasonId = video.season_id;
    var joinedId = tvshowId + seasonId;
    var itemId = document.getElementById(joinedId);
    var itemId_wide = document.getElementById('wide_' + joinedId);

    var partialId = document.getElementById('partial_' + joinedId)

    var url = '';
    console.log("addToListSeason");
    if (this.getQueryParam()) {
        console.log("addToListSeason if");
        url = settings.configuration.env.apiUrl + 'playlist/' + this.getQueryParam() + '/' + contentType + '/' + tvshowId + '/select/' + boolean + '/' + type + '/' + seasonId;
    } else {
        console.log("addToListSeason else");
        url = settings.configuration.env.apiUrl + 'content/' + contentType + '/' + tvshowId + '/select/' + boolean + '/' + type + '/' + seasonId;
    }

    return this.http.post(url, null)
        .toPromise().then((data: any) => {
            this.mediadriveService.checkStorageLimit().then((flag_data: boolean) => {
                if(flag_data == true){
                    catalogItem.is_selected = 0;
                    //Send url to deselect this asset in database
                    this.addToListSeason(video, contentType, type, false, catalogItem);
                    this.toastService.toastWarning('media_storage_limit', 'warning');
                }
                else{
                    if (catalogItem != null){
                        catalogItem.is_selected = data.is_selected;
                    }
                    if (this.getQueryParam()) {
                        this.mediadriveService.getPlaylistSize(this.getQueryParam());
                    } else {
                        this.mediadriveService.getMediaDrive();
                    }
                    
                    if (data.is_selected) {

                        if (this.getQueryParam()) {
                            url = settings.configuration.env.apiUrl + 'playlist/' + this.getQueryParam() + '/' + contentType + '/' + tvshowId + '/' + type + '/' + seasonId;
                        } else {
                            url = settings.configuration.env.apiUrl + 'content/' + contentType + '/' + tvshowId + '/' + type + '/' + seasonId;
                        }
                        return this.http.get(url)
                            .toPromise()
                            .then((res: any) => {
                                var selectedStatus = res.is_selected;
                                if (selectedStatus === 4) {
                                    partialId.classList.remove('partial_selection');
                                    //itemId_wide.classList.remove('partiallySelected');
                                    itemId.classList.add('itemSelected');
                                    itemId_wide.classList.add('itemSelected');
                                } else if (selectedStatus === 1) {
                                    //console.log("this can't happen?");
                                } else if (selectedStatus === 0) {
                                    //console.log("this can't happen");
                                }
                                return data;
                            });

                    } else if (!data.is_selected) {
                        if (this.getQueryParam()) {
                            url = settings.configuration.env.apiUrl + 'playlist/' + this.getQueryParam() + '/' + contentType + '/' + tvshowId + '/' + type + '/' + seasonId;
                        } else {
                            url = settings.configuration.env.apiUrl + 'content/' + contentType + '/' + tvshowId + '/' + type + '/' + seasonId;
                        }

                        return this.http.get(url)
                            .toPromise()
                            .then((res: any) => {
                                var selectedStatus = res.is_selected;
                                if (selectedStatus === 4) {
                                    //console.log("this can't happen");
                                } else if (selectedStatus === 1) {
                                    //console.log("this can't happen?");
                                } else if (selectedStatus === 0) {
                                    itemId.classList.remove('itemSelected');
                                    itemId_wide.classList.remove('itemSelected');
                                    partialId.classList.remove('partial_selection');
                                    //itemId_wide.classList.remove('partiallySelected');
                                }
                                return data;
                            });
                    }
                }
            });
            return data;
        },(err: any) => {
            console.log("Server Error");
        });
  }

  addToListEpisode(tvshowId, seasonId, episodeId, contentType, type, subType, boolean, episodes=null, episodeIndex=null) {
    var joinedId = tvshowId + seasonId;
    var itemId = document.getElementById(joinedId);
    var itemId_wide = document.getElementById('wide_' + joinedId);
    var itemId_partial = document.getElementById(episodeId);

    var partialId = document.getElementById('partial_' + joinedId);

    var countId = document.getElementById('count_' + joinedId);

    var url = '';
    console.log("addToListEpisode");
    if (this.getQueryParam()) {
        console.log("addToListEpisode if");
        url = settings.configuration.env.apiUrl + 'playlist/' + this.getQueryParam() + '/' + contentType + '/' + tvshowId + '/' + type + '/' + seasonId + '/select/' + boolean + '/' + subType + '/' + episodeId;
    } else {
        console.log("addToListEpisode else");
        url = settings.configuration.env.apiUrl + 'content/' + contentType + '/' + tvshowId + '/' + type + '/' + seasonId + '/select/' + boolean + '/' + subType + '/' + episodeId;
    }

    return this.http.post(url, null)
        .toPromise().then((data: any) => {
            this.mediadriveService.checkStorageLimit().then((flag_data: boolean) => {
                if(flag_data == true){
                    _.find(episodes, { episode_index: episodeIndex }).is_selected = 0;
                    //Send url to deselect this asset in database
                    this.addToListEpisode(tvshowId, seasonId, episodeId, contentType, type, subType, false, episodes, episodeIndex);
                    this.toastService.toastWarning('media_storage_limit', 'warning');
                }
                else{
                    if (episodes != null){
                        _.find(episodes, { episode_index: episodeIndex }).is_selected = data.is_selected;
                    }
                    if (this.getQueryParam()) {
                        this.mediadriveService.getPlaylistSize(this.getQueryParam());
                    } else {
                        this.mediadriveService.getMediaDrive();
                    }

                    if (data.is_selected == 4) {
                        if (!!itemId_partial) {
                            itemId_partial.classList.add('itemSelected');
                        }


                        if (this.getQueryParam()) {
                            url = settings.configuration.env.apiUrl + 'playlist/' + this.getQueryParam() + '/' + contentType + '/' + tvshowId + '/' + type + '/' + seasonId;
                        } else {
                            url = settings.configuration.env.apiUrl + 'content/' + contentType + '/' + tvshowId + '/' + type + '/' + seasonId;
                        }

                        return this.http.get(url)
                            .toPromise()
                            .then((res: any) => {
                                var selectedStatus = res.is_selected;
                                if (selectedStatus === 4) {
                                    if (!!itemId && !!itemId_wide) {
                                        partialId.classList.remove('partial_selection');
                                        itemId.classList.add('itemSelected');
                                        //itemId_wide.classList.remove('partiallySelected');
                                        itemId_wide.classList.add('itemSelected');
                                    }
                                } else if (selectedStatus === 1) {
                                    if (!!itemId && !!itemId_wide) {
                                        itemId.classList.add('itemSelected');
                                        partialId.classList.add('partial_selection');
                                        itemId_wide.classList.add('itemSelected');
                                        //itemId_wide.classList.add('partiallySelected');

                                        countId.innerHTML = res.selected_episodes_count;
                                    }
                                } else if (selectedStatus === 0) {
                                    if (!!itemId && !!itemId_wide) {
                                        partialId.classList.remove('partial_selection');
                                        itemId.classList.remove('itemSelected');
                                        //itemId_wide.classList.remove('partiallySelected');
                                        itemId_wide.classList.remove('itemSelected');
                                    }
                                }
                                return data;
                            });
                    }
                    else if (data.is_selected == 0) {
                        if (!!itemId_partial) {
                            itemId_partial.classList.remove('itemSelected');
                        }

                        if (this.getQueryParam()) {
                            url = settings.configuration.env.apiUrl + 'playlist/' + this.getQueryParam() + '/' + contentType + '/' + tvshowId + '/' + type + '/' + seasonId;
                        } else {
                            url = settings.configuration.env.apiUrl + 'content/' + contentType + '/' + tvshowId + '/' + type + '/' + seasonId;
                        }

                        return this.http.get(url)
                            .toPromise()
                            .then((res: any) => {
                                var selectedStatus = res.is_selected;
                                if (selectedStatus === 4) {
                                    //console.log("this can't happen");
                                } else if (selectedStatus === 1) {
                                    if (!!itemId && !!itemId_wide) {
                                        itemId.classList.add('itemSelected');
                                        partialId.classList.add('partial_selection');
                                        itemId_wide.classList.add('itemSelected');
                                        //itemId_wide.classList.add('partiallySelected');

                                        countId.innerHTML = res.selected_episodes_count;
                                    }

                                } else if (selectedStatus === 0) {
                                    if (!!itemId && !!itemId_wide) {
                                        itemId.classList.remove('itemSelected');
                                        partialId.classList.remove('partial_selection');
                                        itemId_wide.classList.remove('itemSelected');
                                        //itemId_wide.classList.remove('partiallySelected');
                                    }
                                }
                                return data;
                            });
                    }
                }
            });
            return data;
        },(err: any) => {
            //console.log("Server Error");
        });
  }


  addToListAlbum(item_id, contentType, type, subType, boolean, catalogItem=null) {
    var itemId = document.getElementById(item_id);
    var itemId_wide = document.getElementById('wide_' + item_id);
    var partialId = document.getElementById('partial_' + item_id)

    var url = '';
    console.log("addToListAlbum");
    if (this.getQueryParam()) {
        console.log("addToListAlbum if");
        url = settings.configuration.env.apiUrl + 'playlist/' + this.getQueryParam() + '/' + contentType + '/' + type + '/select/' + boolean + '/' + subType + '/' + item_id;
    } else {
        console.log("addToListAlbum else");
        url = settings.configuration.env.apiUrl + 'content/' + contentType + '/' + type + '/select/' + boolean + '/' + subType + '/' + item_id;
    }

    return this.http.post(url, null)
        .toPromise().then((data: any) => {
            this.mediadriveService.checkStorageLimit().then((flag_data: boolean) => {
                if(flag_data == true){
                    catalogItem.is_selected = 0;
                    //Send url to deselect this asset in database
                    this.addToListAlbum(item_id, contentType, type, subType, false, catalogItem);
                    this.toastService.toastWarning('media_storage_limit', 'warning');
                }
                else {
                    if (catalogItem != null){
                        catalogItem.is_selected = data.is_selected;
                    }
                    if (this.getQueryParam()) {
                        this.mediadriveService.getPlaylistSize(this.getQueryParam());
                    } else {
                        this.mediadriveService.getMediaDrive();
                    }

                    if (data.is_selected) {
                        if (!!itemId && !!itemId_wide) {
                            partialId.classList.remove('partial_selection');
                            itemId.classList.add('itemSelected');
                            itemId_wide.classList.remove('partiallySelected');
                            itemId_wide.classList.add('itemSelected');
                        }
                    } else if (!data.is_selected) {
                        if (!!itemId && !!itemId_wide) {
                            itemId.classList.remove('itemSelected');
                            partialId.classList.remove('partial_selection');
                            itemId_wide.classList.remove('itemSelected');
                            itemId_wide.classList.remove('partiallySelected');
                        }
                    }
                }
            });
            return data;
        },(err: any) => {
            console.log("Server Error");
        });
  }

  addToListTrack(track, contentType: string, type: string, subType: string, boolean, tracks=null, trackIndex=null) {
      var albumId = track.album_id;
      var trackId = track.track_id;

      var itemId = document.getElementById(albumId);
      var itemId_wide = document.getElementById('wide_' + albumId);
      var itemId_partial = document.getElementById('track_' + trackId);
      var joinedId = albumId + trackId;
      var itemId_joinedId = document.getElementById('track_' + joinedId);
      var partialId = document.getElementById('partial_' + albumId)

      var countId = document.getElementById('count_' + albumId);

      var url = '';
      console.log("addToListTrack");
      if (this.getQueryParam()) {
        console.log("addToListTrack if");
          url = settings.configuration.env.apiUrl + 'playlist/' + this.getQueryParam() + '/' + contentType + '/' + type + '/' + albumId + '/select/' + boolean + '/' + subType + '/' + trackId;
      } else {
        console.log("addToListTrack else");
          url = settings.configuration.env.apiUrl + 'content/' + contentType + '/' + type + '/' + albumId + '/select/' + boolean + '/' + subType + '/' + trackId;
      }

      return this.http.post(url, null)
          .toPromise().then((data: any) => {
             this.mediadriveService.checkStorageLimit().then((flag_data: boolean) => {
                if(flag_data == true){
                    _.find(tracks, { track_index: trackIndex }).is_selected = 0;
                    //Send url to deselect this asset in database
                    this.addToListTrack(track, contentType, type, subType, false, tracks, trackIndex)
                    this.toastService.toastWarning('media_storage_limit', 'warning');
                }
                else{
                    if(tracks != null) { 
                        tracks.is_selected = data.is_selected;
                    }
                    if (this.getQueryParam()) {
                        this.mediadriveService.getPlaylistSize(this.getQueryParam());
                    } else {
                        this.mediadriveService.getMediaDrive();
                    }

                    if (data.is_selected) {
                        if (!!itemId_partial) {
                            itemId_partial.classList.add('itemSelected');
                        } else if (!!itemId_joinedId) {
                            itemId_joinedId.classList.add('itemSelected');
                        }

                        if (this.getQueryParam()) {
                            url = settings.configuration.env.apiUrl + 'playlist/' + this.getQueryParam() + '/music/album/' + albumId;
                        } else {
                            url = settings.configuration.env.apiUrl + 'content/music/album/' + albumId;
                        }

                        return this.http.get(url)
                            .toPromise()
                            .then((res: any) => {
                                var selectedStatus = res.is_selected;
                                if (selectedStatus === 2) {
                                    if (!!itemId && !!itemId_wide) {
                                        partialId.classList.remove('partial_selection');
                                        itemId.classList.add('itemSelected');
                                        itemId_wide.classList.remove('partiallySelected');
                                        itemId_wide.classList.add('itemSelected');
                                    }
                                } else if (selectedStatus === 1) {
                                    if (!!itemId && !!itemId_wide) {
                                        itemId.classList.add('itemSelected');
                                        partialId.classList.add('partial_selection');
                                        itemId_wide.classList.add('itemSelected');
                                        //itemId_wide.classList.add('partiallySelected');

                                        countId.innerHTML = res.selected_tracks_count;

                                    }
                                } else if (selectedStatus === 0) {
                                    if (!!itemId && !!itemId_wide) {
                                        partialId.classList.remove('partial_selection');
                                        itemId.classList.remove('itemSelected');
                                        itemId_wide.classList.remove('partiallySelected');
                                        itemId_wide.classList.remove('itemSelected');
                                    }
                                }
                                return data;
                            });

                    } else if (!data.is_selected) {
                        if (!!itemId_partial) {
                            itemId_partial.classList.remove('itemSelected');
                        } else if (!!itemId_joinedId) {
                            itemId_joinedId.classList.remove('itemSelected');
                        }

                        if (this.getQueryParam()) {
                            url = settings.configuration.env.apiUrl + 'playlist/' + this.getQueryParam() + '/music/album/' + albumId;
                        } else {
                            url = settings.configuration.env.apiUrl + 'content/music/album/' + albumId;
                        }

                        return this.http.get(url)
                            .toPromise()
                            .then((res: any) => {
                                var selectedStatus = res.is_selected;
                                if (selectedStatus === 2) {
                                    //console.log("this can't happen");
                                } else if (selectedStatus === 1) {
                                    if (!!itemId && !!itemId_wide) {
                                        itemId.classList.add('itemSelected');
                                        partialId.classList.add('partial_selection');
                                        itemId_wide.classList.add('itemSelected');
                                        //itemId_wide.classList.add('partiallySelected');

                                        countId.innerHTML = res.selected_tracks_count;

                                    }
                                } else if (selectedStatus === 0) {
                                    if (!!itemId && !!itemId_wide) {
                                        itemId.classList.remove('itemSelected');
                                        partialId.classList.remove('partial_selection');
                                        itemId_wide.classList.remove('itemSelected');
                                        itemId_wide.classList.remove('partiallySelected');
                                    }
                                }
                                return data;
                            });
                    }
                }
              });
              return data;
          },(err: any) => {
              console.log("Server Error");
          });
  }
}

angular
  .module('app.core')
  .factory('selectItems', downgradeInjectable(SelectItemsService));
