import { Component, OnInit, Inject } from '@angular/core';
import settings from '../../../config.json';
import { MAT_DIALOG_DATA } from '@angular/material';
import * as _ from "lodash";
import { OperatorService } from '../core/services/operator.service';
import { ToastService } from '../core/services/toast.service';

@Component({
  selector: 'app-operator-dialog',
  templateUrl: './operator-dialog.component.html',
  styleUrls: ['../aircraft/aircraft.less']
})

export class OperatorDialogComponent implements OnInit {

  public validation: any = settings.configuration.validation.rules;
  public currentTab: string = '';
  public operatorId: string = '';
  public hasOperator: boolean = false;
  public originalOperatorId: string = '';
  public operator: any = [];
  public nameExists: boolean = false;
  public accountTypes: any = [];
  public accountType: any = [];
  public accountSelected: any;
  public originalAccountType: string = '';

  constructor(public operatorService: OperatorService,
    public toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) private dialogData: any,) {
      this.operatorId = this.dialogData.operatorId;
      this.currentTab = this.dialogData.tabName ? this.dialogData.tabName : 'operator';
      this.accountTypes = this.dialogData.accountTypes;
      this.accountSelected = this.dialogData.selectedAccountType;
      this.accountType = this.accountSelected;

      if (!!this.operatorId) {
          this.originalOperatorId = this.operatorId;
          this.hasOperator = true;
          this.originalAccountType = this.accountType;
      } else {
          this.hasOperator = false;
          this.operator = {
              name: '',
              accountType: ''
          };
      }
  }

  ngOnInit() {
  }

  accountTypeSelected(){
    this.accountType = this.accountSelected;
  }

  openTab(tabName: string) {
    this.currentTab = tabName;
  }

  createOperator(name,accountSelected) {
    var isTest = false;
    if (accountSelected == "Test") {
        isTest = true;
    } else {
        isTest = false;
    }

    return this.operatorService.createOperator(name, isTest).then((res) => {
        if (res.status === 200) {
            this.toastService.toastSuccess("success_operator_created", "operator_management");
            this.operatorId = name;
            this.hasOperator = true;
            this.originalOperatorId = name;
            this.originalAccountType = isTest ? "Test" : "Customer";

            setTimeout(() => {
              this.openTab('operator');
            }, 1000);
        } else {
            this.toastService.toastError("error_create_operator", "operator_management");
        }
    }, (err) => {
        if (err.status === 409) {
            this.nameExists = true;
            this.toastService.toastError("error_name_exists", "operator_management");
        }
    });
  }

  editOperatorName(name, selectedAccountType) {
    var isTest = false;
    if (selectedAccountType == "Test") {
        isTest = true;
    } else {
        isTest = false;
    }

    return this.operatorService.editOperatorName(this.originalOperatorId, name, isTest).then((res) => {
        if (res.status === 200) {
            this.toastService.toastSuccess("success_operator_updated", "operator_management");
            this.operatorId = name;
            this.originalOperatorId = name;
            this.accountType = isTest ? 'Test' : 'Customer';
        } else {
            this.toastService.toastError("error_operator_update", "operator_management");
        }
    }, (err) => {
        if (err.status === 409) {
            this.nameExists = true;
            this.toastService.toastError("error_name_exists", "operator_management");
        }
    });
  }
}

