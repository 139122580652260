import { Component, OnInit, KeyValueDiffers } from '@angular/core';
declare var angular: angular.IAngularStatic;
import { downgradeComponent } from '@angular/upgrade/static';
import { CoreService } from '../core/services/core.service';
import { AuthorizedService } from '../core/services/authorized.service';
import { MenuService } from '../core/services/menu.service';
import { Router } from '@angular/router';

@Component({
  selector: 'mediaHeader',
  templateUrl: './media-header.component.html',
  styleUrls: ['../layout/layout.less']
})
export class MediaHeaderComponent implements OnInit {

  menuItems:Array<object> = [];
  differ:any;

  constructor(private coreservice: CoreService,
              private authorizedService: AuthorizedService,
              private menuService: MenuService,
              private differs: KeyValueDiffers,
              private router: Router) {
                menuService.getContentMenu().then((res:any) => {
                  this.menuItems = res;
                  console.log("header menu items :", this.menuItems);
                });
                this.differ = this.differs.find({}).create();
               }

  ngOnInit() {
  }

  ngDoCheck() {
    if(!!this.coreservice.aircraft) {
      const change = this.differ.diff(this.coreservice);
      if(change) {
        change.forEachChangedItem(item => {
          if (item.currentValue != item.previousValue && item.key == 'aircraft'){
           this.menuItemsCleanCall();
          }
        })
      }
    }
  }

  menuItemsCleanCall() {
    var pathname;
    return this.menuService.getContentMenu().then(function () {
        this.menuItems = this.menuService.menuItems;
        pathname = 'app.stage' + this.menuService.menuItems[0].state_name;
        //need to convert below code
        //$state.go('app.stage' + menuService.menuItems[0].state_name);
        this.router.navigate([pathname]);
    });
  }
}

angular
  .module('app.stage')
  .directive(
    'mediaHeader',
    downgradeComponent({component: MediaHeaderComponent}) as angular.IDirectiveFactory
  );