import { Injectable } from '@angular/core';
declare var angular: any;
import { downgradeInjectable } from '@angular/upgrade/static';

import { HttpClient } from '@angular/common/http';
import settings from '../../../../config.json';
import { CoreService } from './core.service';
import { MediadriveService } from './mediadrive.service';
import { AuthorizedService } from './authorized.service';


@Injectable({
  providedIn: 'root'
})

export class AircraftService {
    constructor(
      private http: HttpClient,
      private coreservice: CoreService,
      private mediadriveService: MediadriveService,
      private authorizedService: AuthorizedService
    ) { }

    getAircraft() {
      var url: string = settings.configuration.env.apiUrl + 'fleet/selected/aircraft';
      return this.http
        .get(url)
        .toPromise().then(
          (res: any) => {
            this.coreservice.aircraft = res;

            if(this.coreservice.aircraft) {
              var aircraftuuid = res.id;
              localStorage.setItem('aircraftuuid', aircraftuuid);
            } else {
              var aircraftuuid = null;
              localStorage.setItem('aircraftuuid', '');
            }
            this.mediadriveService.getMediaDrive();
            return res;
          },
          (err: any) => { }
        )
    }

    getAircraftDetails(aircraftId: string) {
      var url: string = settings.configuration.env.apiUrl + 'fleet/aircraft/' + aircraftId;
      return this.http
        .get(url)
        .toPromise().then(
          (res: any) => { return res },
          (err: any) => { console.log ("Error getting Aircraft Details: ", err) }
        );

    }

    setAircraft(aircraft: any) {
      var aircraft_id: string = aircraft.aircraft_id;
      var url: string = settings.configuration.env.apiUrl + 'fleet/select/aircraft/' + aircraft_id;
      return this.http
        .post(url, null)
        .toPromise().then(
          (res: any) => {
            this.getAircraft();
            this.authorizedService.getUserPrivileges();
          },
          null
        )
    }

    getAssignedAircrafts() {
      var url: string = settings.configuration.env.apiUrl + 'fleet/assigned';
      return this.http
        .get(url)
        .toPromise().then(
          (res: any) => { return res },
          null
        );
    }

    getAllAircrafts() {
      var url: string = settings.configuration.env.apiUrl + 'fleet?filter=all';
      return this.http
        .get(url)
        .toPromise().then(
          (res: any) => { return res },
          null
        );
    }

    deleteAircraft(aircraftId: string) {
      var url: string = settings.configuration.env.apiUrl + 'fleet/delete/aircraft/' + aircraftId;
      return this.http
        .post(url, null, {
          observe: "response"
       })
        .toPromise().then(
          (res: any) => { return res },
          null
        );
    }

    getConnectivityTypes() {
        var url: string = settings.configuration.env.apiUrl + 'fleet/connectivity_types';
        return this.http
            .get(url)
            .toPromise().then(
                (res: any) => { return res },
                (err: any) => { console.log ("Error getting connectivity types: ", err) }
            );
    }

    getDownloadPreferences(aircraftId: string) {
        var url: string = settings.configuration.env.apiUrl + 'downloadpreferences/aircraft/' + aircraftId;
        return this.http
            .get(url)
            .toPromise().then(
                (res: any) => { return res },
                (err: any) => { console.log ("Error getting download preferences: ", err) }
            );
    }

    setDownloadPreference(aircraftId: string, boolean: string, channel: string, preference: string, selectedOption: string) {
        var url: string = settings.configuration.env.apiUrl + 'downloadpreferences/aircraft/' + aircraftId + '/select/' + boolean + '/downloadpreference/' + channel + '/type/' + preference + '/selectedoption/' + selectedOption;
        return this.http
            .post(url, null, { observe: "response" })
            .toPromise().then(
                (res: any) => { return res },
                (err: any) => { console.log ("Error setting download preferences: ", err) }
            );
    }

    getAircraftConnectivityTypes(aircraftId: string) {
        var url: string = settings.configuration.env.apiUrl + 'fleet/aircraft/' + aircraftId + '/connectivity_types';
        return this.http
            .get(url)
            .toPromise().then(
                (res: any) => { return res },
                (err: any) => { console.log ("Error getting aircraft connectivity types: ", err) }
              );
    }

    getAircraftInstallationTypes() {
        var url: string = settings.configuration.env.apiUrl + 'fleet/installation_types';
        return this.http
            .get(url)
            .toPromise().then(
                (res: any) => { 
                  var sortedRes = res.sort(function(a, b){
                    if(a.name < b.name) { return -1; }
                    if(a.name > b.name) { return 1; }
                    return 0;
                });
                  return sortedRes; },
                (err: any) => { console.log ("Error getting Installation Types: ", err) }
            );
    }

    setConnectivityType(aircraftId: string, typeId: string, boolean: boolean) {
        var url: string = settings.configuration.env.apiUrl + 'downloadpreferences/aircraft/' + aircraftId + '/connectiontypes/select/' + boolean + '/connectiontype/' + typeId; 
        return this.http
            .post(url, null, { observe: "response" })
            .toPromise().then(
                (res: any) => { return res },
                (err: any) => { console.log ("Error setting connectivity type: ", err) }
            );
    }

    setInstallationType(aircraftId: string, typeId: string){
        var url: string = settings.configuration.env.apiUrl + 'downloadpreferences/aircraft/' + aircraftId + '/installationtypes/' + typeId;
        return this.http
            .post(url, null, { observe: "response" })
            .toPromise().then(
                (res: any) => { return res },
                (err: any) => { console.log ("Error setting Installation Types: ", err) }
            );
    }

    getManufacturers() {
      var url: string = settings.configuration.env.apiUrl + 'fleet/manufacturers';
      return this.http
        .get(url)
        .toPromise().then(
          (res: any) => { return res },
          null
        );
    }

    getManufacturerModels(manufacturerId: string) {
      var url: string = settings.configuration.env.apiUrl + 'fleet/manufacturer/' + manufacturerId + '/models';
      return this.http
        .get(url)
        .toPromise().then(
          (res: any) => { return res },
          (err: any) => { console.log(err) }
        );
    }

    getMsuConfiguration(aircraftId: string) {
      var url: string = settings.configuration.env.apiUrl + 'fleet/aircraft/' + aircraftId + '/activemsuconfiguration';
      return this.http
        .get(url)
        .toPromise().then(
          (res: any) => { return res },
          (err: any) => { console.log(err) }
        );
    }

    getMsuConfigurations(aircraftId: string) {
      var url: string = settings.configuration.env.apiUrl + 'fleet/aircraft/' + aircraftId + '/msuconfigurationslist';
      return this.http
        .get(url)
        .toPromise().then(
          (res: any) => { return res },
          null
        );
    }

    changeAircraftPassword(aircraftId: string, newPassword: string, confirmPassword: string, email: string) {
      var url: string = settings.configuration.env.apiUrl + 'fleet/aircraft/' + aircraftId + '/changepassword';
      return this.http
        .post(url, 
          {
            new_password: newPassword,
            new_password2: confirmPassword,
            email: email
          },{ observe: "response"
          })
          .toPromise().then(
            (res: any) => { return res },
            null
          );
    }

    getStageClients() {
        var url: string = settings.configuration.env.apiUrl + 'fleet/stageclients';
        return this.http
            .get(url)
            .toPromise().then(
                (res: any) => { return res },
                (err: any) => { console.log("Error getting stage clients" + err); }
            );
    }

    getAircraftStageClient(aircraftId: string) {
        var url: string = settings.configuration.env.apiUrl + 'fleet/aircraft/' + aircraftId + '/stageclient';
        return this.http
            .get(url)
            .toPromise().then(
                (res: any) => { return res },
                (err: any) => { console.log("Error getting stage client" + err); }
            )
    }

    setAircraftStageClient(aircraftId: string, stageClientId: string) {
        var url: string = settings.configuration.env.apiUrl + 'downloadpreferences/aircraft/' + aircraftId + '/stageclient/' + stageClientId;
        return this.http
            .post(url, null, { observe: "response"})
            .toPromise().then(
                (res: any) => { return res },
                (err: any) => { console.log ("Error setting stage client" + err)});
    }  

    msuUpgradeNotice(aircraftId: string, status: boolean, toastId: number) {
      console.log("Aircraft Id ", aircraftId);
      var url: string = settings.configuration.env.apiUrl + 'msuversionupdate/' + aircraftId + '/status/' + status + '/toastId/' + toastId;
      return this.http
          .post(url, null, { observe: "response"})
          .toPromise().then(
              (res) => { return res },
              (err) => { return err }
          );
    }

    getMSUDetails(aircraftId: string) {
      var url: string = settings.configuration.env.apiUrl + 'getmsuversionupdate/' + aircraftId;
      return this.http
          .get(url) 
          .toPromise().then(
              (res : any) => { return res; },
              (err) => { return err }
          );
    }

    getSystemDetails(aircraftId: string) {
      var url: string = settings.configuration.env.apiUrl + 'getsystemupdate/' + aircraftId;
      return this.http
          .get(url) 
          .toPromise().then(
              (res : any) => { return res; },
              (err) => { return err }
          );
    }

    getDCSLVersions() {
      var url: string = settings.configuration.env.apiUrl + 'fleet/dcslversions';
      return this.http
          .get(url)
          .toPromise().then(
              (res: any) => { return res },
              (err: any) => { console.log ("Error getting dcslversions: ", err) }
          );
  }

  setDCSLVersion(aircraftId: string, typeId: any){
    var url: string = settings.configuration.env.apiUrl + 'downloadpreferences/aircraft/' + aircraftId + '/dcslversions/' + typeId;
    return this.http
        .post(url, null, { observe: "response" })
        .toPromise().then(
            (res: any) => { return res },
            (err: any) => { console.log ("Error setting dcslversions: ", err) }
        );
}

}

angular
  .module('app.core')
  .factory('aircraftService', downgradeInjectable(AircraftService));
