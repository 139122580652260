import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(text: string, length: number, end: string): string {
    if (isNaN(length))
    length = 10;
    //console.log("length$$$", length);
    if (end === undefined)
      end = "...";
    if (text.length <= length || text.length - end.length <= length) {
      return text;   
    }
    else {  
        return String(text).substring(0, length - end.length) + end;
    }     
  }

}
