import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import settings from '../../../config.json';
import { HttpClient } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material';
import { SelectItemsService } from '../core/services/select-items.service';
import { MatDialog } from "@angular/material";
import { Config } from '../core/config';
import { CoreService } from '../core/services/core.service';
import { AuthorizedService } from '../core/services/authorized.service';
import * as _ from "lodash";
import { DashPlayer } from '../video/dashplayer.component';

@Component({
  selector: 'episode-dialog',
  templateUrl: './episode-dialog.component.html',
  styleUrls: ['./video.less']
})

export class EpisodeDialog implements OnInit {

  public episodes: any;
  public coverImage: string;
  public image: string;
  public imageCategory: string;
  public imageChannel: string;
  public movieDetails: any;
  public details: any;
  public trailerUri: string;
  public dateTime: any;
  public today: Date;
  public until: Date;
  public fromDate: Date;
  public isPlaylist: boolean;
  public blockUI: boolean;
    isUpdateAvailable: boolean;

  constructor(private route: ActivatedRoute,
    private http: HttpClient,
    private selectItems: SelectItemsService,
    public episodedialog: MatDialogRef<EpisodeDialog>,
    public coreservice: CoreService,
    public authorizedService: AuthorizedService,
    private dialog: MatDialog,
    private config: Config,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.coverImage = settings.configuration.env.apiUrl;
    this.image = settings.configuration.env.apiUrl + 'images/tvshows/' + this.config.IMAGE_FORMATS.MOVIES_DETAILS + '/';
    this.imageCategory = settings.configuration.env.apiUrl + 'images/categories/' + this.config.IMAGE_FORMATS.DETAILS_CATEGORIE_BANNER + '/';
    this.imageChannel = settings.configuration.env.apiUrl + 'images/channels/' + this.config.IMAGE_FORMATS.DETAILS_CATEGORIE_BANNER + '/';
    this.dateTime = settings.configuration.datetime_formats;
    this.isPlaylist = false;
    this.blockUI = false;
  }

  async ngOnInit() {
    this.episodes = this.data.episodeDetails;
        this.isUpdateAvailable = this.data.isUpdateAvailable;

    if (!!(this.episodes.trailer_uri && this.episodes.manifest_name)) {
      this.trailerUri = settings.configuration.env.apiUrl + 'trailers/path/' + this.episodes.manifest_name + '/' + this.movieDetails.trailer_uri;
    }

    this.details = this.episodes;
    this.today = new Date();
    this.until = new Date(this.details.valid_until);
    this.fromDate = new Date(this.details.valid_from);
    this.activate();
  }

  getQueryParam(): any {
    this.route.paramMap.subscribe(params => {
      return params.get('playlist_id');
    });
  }

  activate() {
    if (this.getQueryParam()) {
      this.isPlaylist = true;
    } else {
      this.isPlaylist = false;
    }
  }

  openMovieTrailer() {
    if (!this.blockUI) {
      this.blockUI = true;
      let promise = Promise.resolve(this.trailerUri);
      promise.then(() => {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
          trailerUri: this.trailerUri
        };
        dialogConfig.panelClass = ['matdialog-custom-dialog', 'video-width'];
        let dashDialog = this.dialog.open(DashPlayer, dialogConfig);
        dashDialog.beforeClosed().subscribe(res => {
          this.blockUI = false;
        });
      })
    }
  }

  selectItem(episode: any) {
    var tvshowId = this.episodes.tvshow_id;
    var seasonId = this.episodes.season_id;
    var episodeId = episode.episode_id;
    var episodeIndex = episode.episode_index;
    var status = episode.is_selected;
    var boolean;
    var newStatus;

    if (status == 0) {
      boolean = true;
      newStatus = 4;
    } else if (status == 4) {
      boolean = false;
      newStatus = 0;
    }

    this.selectItems.addToListEpisode(tvshowId, seasonId, episodeId, 'tvshow', 'season', 'episode', boolean, this.episodes.episodes, episodeIndex).then((res: any) => {
      //For Future use
      //if (res.status === 200) {
      //  _.find(this.episodes.episodes, { episode_index: episodeIndex }).is_selected = newStatus;
      // }
    });
  }

  autoAdd(seasonInfo: any) {
    var status = seasonInfo.autoselection_enabled;
    var tvshowId = seasonInfo.tvshow_id;
    var newStatus;
    var url;

    if (status) {
      newStatus = false;
      url = settings.configuration.env.apiUrl + 'content/tvshows/autoupdate/' + newStatus + '/tvshow/' + tvshowId;
      this.setStatus(url);
    } else {
      newStatus = true;
      url = settings.configuration.env.apiUrl + 'content/tvshows/autoupdate/' + newStatus + '/tvshow/' + tvshowId;
      this.setStatus(url);
    }
  }

  setStatus(url: any) {
    return this.http.post(url, null)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }

  closeThisDialog() {
    this.episodedialog.close();
  }
}
