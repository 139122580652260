import { Injectable } from '@angular/core';
declare var angular: any;
import { downgradeInjectable } from '@angular/upgrade/static';

import { HttpClient } from '@angular/common/http';
import settings from '../../../../config.json';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  initMovieData: boolean = false;
  constructor(private http: HttpClient) { }

  getFolder(id: string) {
    var url: string = settings.configuration.env.apiUrl + 'content/documents/folder/' + id + '/files';
    return this.http.get(url)
        .toPromise()
        .then((res: any) => {
            return res;
        });
  }

  getMediaItems(contentType: string, callName: string, skip, take, filter: string, sort: string, language: string,audience:string="") {
    var url: string = settings.configuration.env.apiUrl + 'content/' + contentType + '/' + callName + '?' + 'skip=' + skip + '&take=' + take + '&filter=' + filter + '&sort=' + sort + '&language=' + language+ '&audience=' + audience;
    console.log("getMediaItems Url:", url);
    return this.http.get(url)
        .toPromise()
        .then((res: any) => {
            this.initMovieData = true;
            console.log("initMovieData", this.initMovieData);
            return res;
        });
  }

  getVariaItems(callName: string, skip, take, filter: string, sort: string) {
    var url: string = settings.configuration.env.apiUrl + 'content/' + callName + '?' + 'skip=' + skip + '&take=' + take + '&filter=' + filter + '&sort=' + sort;
    return this.http.get(url)
        .toPromise()
        .then((res: any) => {
            return res;
        });
  }

  getChanneledData(contentType: string, callName: string, skip, take, filter: string, sort: string, language: string, channelname: string,audience:string="") {
    var url: string = settings.configuration.env.apiUrl + 'content/' + contentType + '/' + callName + '?' + 'skip=' + skip + '&take=' + take + '&filter=' + filter + '&sort=' + sort + '&language=' + language + '&channel=' + channelname+'&audience=' + audience;
    return this.http.get(url)
        .toPromise()
        .then((res: any) => {
            this.initMovieData = true;
            return res;
        });
  }
}

angular
  .module('app.core')
  .factory('dataService', downgradeInjectable(DataService));
