import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDuration'
})
export class FormatDurationPipe implements PipeTransform {

  transform(value: any): any {
    var out = "";
    var seconds = parseInt(value);
    var minutes = Math.ceil(seconds / 60);
    out += minutes;

    out += ' min';
    return out;
  }

}
