import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accounttypefilterInOperator'
})
export class AccounttypefilterInOperatorPipe implements PipeTransform {

  transform(items:any, filterAccountType:any): any {
    if(filterAccountType == "All") {
        return items;
    }

    var filtered = [];
    for (var i = 0; i < items.length; i++) {
        var item = items[i];
        if(item.accountType == filterAccountType) {
            filtered.push(item);
        }   
    }
    return filtered;
  }
}
