import { Injectable } from '@angular/core';
declare var angular: any;
import { downgradeInjectable } from '@angular/upgrade/static';

import { HttpClient } from '@angular/common/http';
import settings from '../../../../config.json';

@Injectable({
  providedIn: 'root'
})
export class ChannelService {

  constructor(private http: HttpClient) { }

  getChannels(channelType) {
    var url: string = settings.configuration.env.apiUrl + 'filter_options/channel/' + channelType;
    return this.http.get(url)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  };
}
angular
  .module('app.core')
  .factory('channelService', downgradeInjectable(ChannelService));