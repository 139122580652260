import { Component, OnInit, Inject } from '@angular/core';
import { AircraftService } from '../core/services/aircraft.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material';
import { ToastService } from '../core/services/toast.service';
//import { AircraftsComponent } from './aircrafts.component';

@Component({
  selector: 'app-aircraft-confirm-dialog',
  templateUrl: '../layout/confirm-dialog.html',
  styleUrls: ['./aircraft.less']
})
export class AircraftConfirmDialogComponent implements OnInit {

  public option;
  public deleteName;

  constructor(private aircraftService: AircraftService,
              private toastService: ToastService,
              private AircraftConfirmDialog: MatDialogRef<AircraftConfirmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private dialogData: any)
               { 
                this.option = 'aircraft';
                this.deleteName = this.dialogData.aircraftId;
              }

  ngOnInit() {
  }

  deleteConfirmed() {
    return this.aircraftService.deleteAircraft(this.dialogData.aircraftId).then((res) => {
        if (res.status == 200) {
            this.toastService.toastSuccess("success_aircraft_delete", "aircraft_management");
            //this.dialogData.cleanCall;
            //this.cleanCall();
            //this.aircraftService.getAircraft();
            //this.AircraftConfirmDialog.
            this.AircraftConfirmDialog.close();
        } else {
            this.toastService.toastError("error_aircraft_delete", "aircraft_management");
        }
    });
  }
  closeThisDialog() {
    this.AircraftConfirmDialog.close();
  }

}
