export class Config {

    AIRCRAFT_DETAILS = {
        MEDIA_DRIVE_SIZE: 1.7
    };

    IMAGE_FORMATS = {
        APPS_GRID: 'Portal_GridCatalog_square_Apps', // 140 X 140
        DESTINATIONS_GRID: 'Portal_GridCatalog_landscape_Destinations', // 140 X 105
        DESTINATIONS_LIST: 'Portal_GridCatalog_landscape_Destinations', // 85 X 64
        DESTINATIONS_LIST_SMALL: 'Portal_GridCatalog_landscape_Destinations', // 64 X 48
        MAGAZINES_GRID: 'Portal_GridCatalog_portrait_Magazines', // 140 X 192
        MAGAZINES_LIST: 'Portal_ListCatalog_portrait_Magazines', // 85 X 116
        MAGAZINES_LIST_SMALL: 'Portal_ListCatalog_portrait_small_Magazines', // 64 X 88
        MAGAZINES_DETAILS: 'Portal_Detail_portrait_Magazines', // 469 X 643
        MOVIES_GRID: 'Portal_GridCatalog_portrait_Movies', // 140 X 203
        MOVIES_LIST: 'Portal_ListCatalog_portrait_Movies', // 85 X 123
        MOVIES_LIST_SMALL: 'Portal_ListCatalog_portrait_small_Movies', // 64 X 93
        MOVIES_DETAILS: 'Portal_Detail_portrait_Movies', // 469 X 680
        MUSIC_GRID: 'Portal_GridCatalog_square_Music', // 140 X 140
        MUSIC_DETAILS: 'Portal_Detail_square_Music', // 458 X 458
        TV_GRID: 'Portal_GridCatalog_landscape_Tv', // 140 X 79
        TV_LIST: 'Portal_ListCatalog_landscape_Tv', // 85 X 48
        TV_LIST_SMALL: 'Portal_ListCatalog_landscape_small_Tv', // 64 X 36
        TV_DETAILS: 'Portal_Detail_landscape_Tv', // 458 X 257
        DETAILS_CATEGORIE_BANNER: 'Portal_Banner_Detail_Portrait', //469 x 235
        DETAILS_CATEGORIE_BANNER_LANDSCAPE: 'Portal_Banner_Detail_Landscape', //458 x 235
        CHANNEL_BANNER: 'Portal_Banner_Catalog',//140x70
        DEFAULT_SQUARE: 'Square'
    }
}
