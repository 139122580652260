import { Component, OnInit, KeyValueDiffers} from '@angular/core';
declare var angular: angular.IAngularStatic;
import { downgradeComponent } from '@angular/upgrade/static';
import settings from '../../../config.json';
import { OperatorService } from '../core/services/operator.service';
import { AircraftService } from '../core/services/aircraft.service';
import { AuthorizedService } from '../core/services/authorized.service';
import { Router, ActivatedRoute, UrlSegment, NavigationEnd } from '@angular/router';
import { CoreService } from '../core/services/core.service';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { AircraftDialogComponent } from '../aircraft/aircraft-dialog.component';
import { AircraftConfirmDialogComponent } from '../aircraft/aircraft-confirm-dialog.component';

@Component({
    selector: 'aircrafts',
    templateUrl: './aircrafts.component.html',
    styleUrls: ['./aircraft.less', '../account/account.less'],
    styles: [':host { width: -webkit-fill-available; }']
})

export class AircraftsComponent implements OnInit {

  public image: string;
  private contentItems: any = [];
  public operators: any = [];
  private params;
  public catalog: any = [];
  public filterOperator;
  public placeholder;
  public timeStamp;
  private blockUI: boolean;
  public message: string;
  private differ;
  public loadCatalog: boolean;
  public loadOperators: boolean;
  public loadParams: boolean
  public content_search: string;
  public secondaryAircraftsList:any = [];
  public secondaryFleetConst;

  constructor(private operatorService: OperatorService, 
              private aircraftService: AircraftService,
              public authorizedService: AuthorizedService,
              private route: ActivatedRoute,
              public coreservice: CoreService,
              private router: Router,
              private differs: KeyValueDiffers,
              private dialog: MatDialog) {

    this.differ = this.differs.find({}).create();
    this.image = settings.configuration.env.apiUrl + 'images/fleet/';
    this.placeholder = '../mediacontent/icons/aircraft_default.png';
    this.loadCatalog = false;
    this.loadOperators = false;
    this.loadParams = false;
    this.secondaryFleetConst = 1;
    this.getAircraftDetail();
    
    if (!!this.getQueryParam('operator_id')) {
        this.filterOperator =this.getQueryParam('operator_id');
    } else {
        this.filterOperator = '';
    }

    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };

    /* Set filterOperator as selected Opertor of "Operators page",
     this is temporary work around till Operators page get converted into Angular*/
    if(this.coreservice.operatorName!= null) {
      this.filterOperator = this.coreservice.operatorName;
      this.coreservice.operatorName = null;
    }
    this.timeStamp = '?=' + new Date().getTime();
    this.blockUI = false;
  }

  ngOnInit(){
  }

  ngDoCheck() {
    if (!!this.coreservice.operator) {
      const change = this.differ.diff(this.coreservice.operator);
      if (change) {
        change.forEachChangedItem(item => {
          if (item.currentValue != item.previousValue && item.key == 'name') {
            this.catalog = [];
            this.cleanCall();
          }
        })
      }
    }
  }

  getAircraftDetail(){
    this.aircraftService.getAllAircrafts().then((res: any) => {
       this.contentItems = res;
       this.catalog = this.contentItems;
       this.loadCatalog = true;
    });
    this.operatorService.getAllOperators().then((res: any) => {
       this.operators = res;
       this.loadOperators = true;
    });
    this.operatorService.getOperators().then((res: any) => {
        this.params = res;
        this.loadParams = true;
     });
  }

  getQueryParam(param): any {
    this.route.paramMap.subscribe(params => {
        return params.get(param);
        });
  }

  selectAircraft(aircraft) {
    this.aircraftService.setAircraft(aircraft);
    this.router.navigateByUrl('/content/movies/all');
  }

  openDialog(aircraft, tabName) {
    if (!this.blockUI) {
        this.blockUI = true;
        var dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
          //  aircraft: this.aircraftService.getAircraftDetails(aircraft.aircraft_id),
          //  manufacturers: this.aircraftService.getManufacturers(),
          //  manufacturerModels: this.aircraftService.getManufacturerModels(aircraft.manufacturer),
          //  connectivityTypes: this.aircraftService.getAircraftConnectivityTypes(aircraft.aircraft_id),
          //  operators: this.operatorService.getAllOperators(),
          //  downloadPreferences: this.aircraftService.getDownloadPreferences(aircraft.aircraft_id),
          //  selectedSubscriptionPlan: this.subscriptionService.getCurrentSubscriptionPlan(aircraft.aircraft_id),
          //  configuration: this.aircraftService.getMsuConfiguration(aircraft.aircraft_id),
          aircraft_id: aircraft.aircraft_id,
          manufacturer: aircraft.manufacturer,
          model: aircraft.model,
          tabName: tabName
        };
        dialogConfig.panelClass = ['matdialog-custom-dialog', 'edit-aircraft-width', 'dialog'];
        let AircraftDialog = this.dialog.open(AircraftDialogComponent, dialogConfig);
        AircraftDialog.beforeClosed().subscribe(res => {
          this.timeStamp = '?=' + new Date().getTime();
          this.cleanCall();
          this.blockUI = false;
        });
    }
  }

  async addAircraft() {
    if (!this.blockUI) {
        this.blockUI = true;
        var dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            aircraft: {
                aircraft_id: '',
                manufacturer: '',
                model: '',
                serial_number: '',
                content_disk_space: '',
                operator_id: '',
                size: ''
            },
            //manufacturers: this.aircraftService.getManufacturers(),
            // manufacturerModels: this.aircraftService.getManufacturers().then((res) => {
            //     var currentManufacturer = res[0].id;
            //     return this.aircraftService.getManufacturerModels(currentManufacturer);
            // }),
            //connectivityTypes: this.aircraftService.getConnectivityTypes();                         
            operators: await this.operatorService.getAllOperators(),
            //downloadPreferences: this.aircraftService.getDownloadPreferences(aircraft.aircraft_id),
            //selectedSubscriptionPlan: this.subscriptionService.getCurrentSubscriptionPlan(aircraft.aircraft_id),
            //configuration: this.aircraftService.getMsuConfiguration(aircraft.aircraft_id),
            createNew: true,
            tabName: 'aircraft',
            operator: this.filterOperator ? this.filterOperator : ( this.coreservice.operator ? this.coreservice.operator.name : '')
        };

        dialogConfig.panelClass = ['matdialog-custom-dialog', 'edit-aircraft-width', 'dialog'];
        let AircraftDialog = this.dialog.open(AircraftDialogComponent, dialogConfig);
        AircraftDialog.beforeClosed().subscribe(res => {
          this.cleanCall();
          this.blockUI = false;
        });
    }
  }
  
  deleteAircraft(aircraftId, index) {
    if (!this.blockUI) {
        this.blockUI = true;
        var dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
          aircraftId: aircraftId
        }
        dialogConfig.panelClass = ['matdialog-custom-dialog', 'confirm-dialog', 'dialog'];
        let AircraftConfirmDialog = this.dialog.open(AircraftConfirmDialogComponent, dialogConfig);
        AircraftConfirmDialog.beforeClosed().subscribe(res => {
            this.blockUI = false;
            this.aircraftService.getAircraft();
            this.cleanCall();
        });
    }
  }

  cleanCall() {
    this.message = '';
    return this.aircraftService.getAllAircrafts()
        .then((res) => {
            if (res.length >= 1) {
                return this.catalog = res;
            } else {
                this.message = 'no_results';
                return this.catalog = [];
            }
        });
  }
   
}

angular
  .module('app.aircraft')
  .directive(
    'aircrafts',
    downgradeComponent({component: AircraftsComponent}) as angular.IDirectiveFactory
  );


