import { Component, OnInit, KeyValueDiffers } from '@angular/core';
declare var angular: angular.IAngularStatic;
import { downgradeComponent } from '@angular/upgrade/static';
import settings from '../../../config.json';
import { AuthorizedService } from '../core/services/authorized.service';
import { ActivatedRoute } from '@angular/router';
import { CoreService } from '../core/services/core.service';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { EditUserDialogComponent } from './edit-user-dialog.component';
import { UserService } from '../core/services/user.service';
import { UserConfirmDialog } from './user-confirm-dialog';
import { OperatorService } from '../core/services/operator.service';
import { AssignPrivilegesService } from '../core/services/assign-privileges.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.less'],
  styles: [':host { width: -webkit-fill-available; }']
})

export class UserComponent implements OnInit {

  public image: string = settings.configuration.env.apiUrl + 'images/users/';
  public filterOperator;
  public timeStamp;
  public blockUI: boolean;
  public message: string;
  public catalog: any = [];
  private differ;
  public selectedOperator: any;
  private contentItems: any = [];
  public loadCatalog: boolean;
  public operators: any = [];
  public loadOperators: boolean;
  public content_search;
  public globalPrivileges: any = [];
  public getOperators: any = [];
  public getGlobalPrivilege: boolean = false;

  constructor(public authorizedService: AuthorizedService,
    private route: ActivatedRoute,
    public coreservice: CoreService,
    private dialog: MatDialog,
    private userService: UserService,
    private differs: KeyValueDiffers,
    private operatorService: OperatorService,
    private privilegesService: AssignPrivilegesService) {

    this.differ = this.differs.find({}).create();

    if (!!this.getQueryParam('operator_id')) {
        this.filterOperator =this.getQueryParam('operator_id');
    } else {
        this.filterOperator = '';
    }

    this.loadCatalog = false;
    this.loadOperators = false;
    this.getUserDetail();

    /* Set filterOperator as selected Opertor of "Operators page",
    this is temporary work around till Operators page get converted into Angular*/
    if(this.coreservice.operatorName!= null) {
        this.filterOperator = this.coreservice.operatorName;
        this.coreservice.operatorName = null;
    }

    this.timeStamp = '?=' + new Date().getTime();
    this.blockUI = false;
  }

  ngOnInit() {
  }

  ngDoCheck() {
    if (!!this.coreservice.user) {
      const change = this.differ.diff(this.coreservice);
      if (change) {
        change.forEachChangedItem(item => {
          if (item.currentValue != item.previousValue && item.key == 'name') {
            this.catalog = [];
            this.cleanCall();
          }
        })
      }
    }
  }

  getQueryParam(param): any {
    this.route.paramMap.subscribe(params => {
       return params.get(param);
    });
  }

  getUserDetail(){
    this.userService.getUsers(this.coreservice.operatorName).then((res: any) => {
       this.contentItems = res;
       this.catalog = this.contentItems;
       this.loadCatalog = true;
    });
    this.operatorService.getAllOperators().then((res: any) => {
       this.operators = res;
       this.loadOperators = true;
    });
  }

  async editUser(userId: any, tabName: any) {
    if (!this.blockUI) {
      this.blockUI = true;
      var dialogConfig = new MatDialogConfig();

      if (this.authorizedService.checkIfAuthorized(['Global Privileges.Read'], 'onlyOne')) {
        this.globalPrivileges = await this.privilegesService.getGlobalPrivileges(userId);
      }
      if (this.authorizedService.checkIfAuthorized(['Operators.Read'], 'onlyOne')) {
        this.getOperators = await this.operatorService.getAllOperators();
      } else if (!this.authorizedService.checkIfAuthorized(['Global Privileges.Read'], 'onlyOne')) {
        this.getGlobalPrivilege = true;
      }

      dialogConfig.data = {
        userId: userId,
        userInfo: await this.userService.getUserDetails(userId),
        assignedOperators: await this.privilegesService.getAssignedOperators(userId),
        operators: this.getOperators,
        assignedAircrafts: await this.privilegesService.getAssignedAircrafts(userId),
        globalPrivileges: this.globalPrivileges,
        tabName: tabName,
        getGlobalPrivilege: this.getGlobalPrivilege
      };
      console.log("Users assigned Aircrafts ",dialogConfig.data.assignedAircrafts);
      dialogConfig.panelClass = ['matdialog-custom-dialog', 'edit-aircraft-width', 'dialog'];
      let UserDialog = this.dialog.open(EditUserDialogComponent, dialogConfig);
      UserDialog.beforeClosed().subscribe(res => {
        this.cleanCall();
        this.blockUI = false;
      });
    }
  }

  async createUser() {
    console.log("in create user...");
    var tabName = 'user';
    if (!this.blockUI) {
      this.blockUI = true;
      var dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
       userInfo: {
        company: null,
        email: null,
        first_name: null,
        last_name: null,
        phone_number: null,
        user_name: null,
        is_subscribed_for_news_letter: true,
        operator_id: !!this.filterOperator ? this.filterOperator : (this.coreservice.operator ? this.coreservice.operator.name : '')
       },
       operators: await this.operatorService.getAllOperators(),
       tabName: tabName
      };

      dialogConfig.panelClass = ['matdialog-custom-dialog', 'edit-aircraft-width', 'dialog'];
      let UserDialog = this.dialog.open(EditUserDialogComponent, dialogConfig);
      UserDialog.beforeClosed().subscribe(res => {
        this.cleanCall();
        this.blockUI = false;
      });
    }
  }

  deleteUser(userId) {
    if (!this.blockUI) {
        this.blockUI = true;
        var dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
          userId: userId
        }
        dialogConfig.panelClass = ['matdialog-custom-dialog', 'confirm-dialog', 'dialog'];
        let userConfirmDialog = this.dialog.open(UserConfirmDialog, dialogConfig);
        userConfirmDialog.beforeClosed().subscribe(res => {
            this.blockUI = false;
            this.cleanCall();
        });
    }
  }

  watchOperator(operator) {
    if (!!operator) {
        this.selectedOperator = operator;
        this.cleanCall();
    } else {
        this.selectedOperator = null;
        this.cleanCall();
    }
}

  cleanCall() {
    this.message = '';
    return this.userService.getUsers(this.selectedOperator)
        .then((res) => {
            if (res.length >= 1) {
                this.timeStamp = '?=' + new Date().getTime();
                this.catalog = res;
            } else {
                this.message = 'no_results';
                return this.catalog = [];
            }
        });
  }

}

angular
  .module('app.user')
  .directive(
    'user',
    downgradeComponent({component: UserComponent}) as angular.IDirectiveFactory
  );
