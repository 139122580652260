import { Component, OnInit, DoCheck, KeyValueDiffers } from '@angular/core';
//import { MoviecontentComponent } from '../../video/moviecontent.component';
import { CoreService } from '../../core/services/core.service';
import { DataService } from '../../core/services/data.service';		

@Component({
  selector: 'wisespinnerng8',
  template: '<div id="wisespinner8" class="spinner-container"><div class="spinner"></div></div>',
  //styleUrls: ['./wise-spinner.component.less']
})
export class WiseSpinnerComponent implements OnInit,DoCheck{

  differ;
  hideSpin;
  filterFlag;
  langFlag;
  sortFlag;
  channelFlag;
  contentFlag;

  constructor(
              private coreservice: CoreService,
              private differs: KeyValueDiffers,
              private dataservice: DataService) {

    this.differ = this.differs.find({}).create();
  
  }

  ngOnInit() {
  }

  ngDoCheck(){
    
    if(this.coreservice.hideSpinner == false){
      //console.log("coresService", this.coreservice.hideSpinner);
      this.showSpinner();
      //this.coreservice.hideSpinner = null;
    }
    else if (this.coreservice.hideSpinner == true){
      //console.log("coresService", this.coreservice.hideSpinner);
      this.hideSpinner();
      //this.coreservice.hideSpinner = null;
    }
  //   this.showSpinner();
  //   if (!!this.coreservice.aircraft) {
  //     const change = this.differ.diff(this);
  //     console.log("chnage**");
  //     this.contentFlag = this.movie.contentFlag;
  //     this.filterFlag = this.movie.filterFlag;
  //     this.langFlag = this.movie.langFlag;
  //     this.channelFlag = this.movie.channelFlag;
  //     this.sortFlag = this.movie.sortFlag;
  //     this.hideSpin = this.dataservice.initMovieData;
  //     if (change) {
  //       change.forEachChangedItem(item => {
  //         console.log('item changed####', item);
  //         if (item.key == 'hideSpin') {
  //           console.log("key", item.currentValue);
  //            this.hideSpinner();
  //          }
  //       })
  //     }
  //     this.hideSpin = false;
  //   }
  //   else {
  //     this.hideSpinner();
  //   }
  }

  // setTimer() {
  //   this.showSpinner();
  //   //await new Promise(resolve => setTimeout(resolve, 1500));
  //   console.log("this.contentFlag",  this.movie.contentFlag);
  //   if (this.movie.contentFlag == true) {
  //     this.hideSpinner();
  //   }

  // };

  showSpinner() {
    //console.log("spinner");
    var spinneredContainer = document.getElementById('wisespinner8');
    var mediaContainer = document.getElementsByClassName('media-files')[0];
    var spinnerArea = document.getElementsByClassName('show-spinner')[0];

    if (typeof(mediaContainer) != 'undefined' && mediaContainer != null) {
        spinneredContainer.style.display = 'block';
        //mediaContainer.style.overflow = 'hidden';
    } else if (typeof(spinnerArea) != 'undefined' && spinnerArea != null) {
        spinneredContainer.style.display = 'block';
        //spinnerArea.style.overflow = 'hidden';
    }
 };

 hideSpinner() {
   //console.log("hidespinner");
   var spinneredContainer = document.getElementById('wisespinner8');
   var mediaContainer = document.getElementsByClassName('media-files')[0];
   var spinnerArea = document.getElementsByClassName('show-spinner')[0];

   if (typeof(mediaContainer) != 'undefined' && mediaContainer != null) {
      spinneredContainer.style.display = 'none';
      //mediaContainer.style.overflow = 'auto';
   } else if (typeof(spinnerArea) != 'undefined' && spinnerArea != null) {
      spinneredContainer.style.display = 'none';
      //spinnerArea.style.overflow = 'auto';
   }
 };

}
