import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatsecDuration'
})
export class SecdurationPipe implements PipeTransform {

  transform(input: any): any {
      input = input || '';
      if (!!input) {
          var out = "";
          var seconds = parseInt(input);
          var minutes = Math.ceil(seconds / 60);
          var remaining = seconds % 60;
          if (minutes > 0) {
              out += Math.floor(seconds / 60) + ':'
              out += (remaining < 10) ? '0' + remaining : remaining;
          } else {
              out += remaining;
          }
      } else {
          out = "0";
      }
      return out;
  }
}


