import { EventEmitter, Injectable, Output } from '@angular/core';
declare var angular: any;
import { downgradeInjectable } from '@angular/upgrade/static';

import { HttpClient } from '@angular/common/http';
import settings from '../../../../config.json';
import { CoreService } from './core.service';
import { AuthorizedService } from './authorized.service';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

export class MediadriveService {

  public today: Date;
  public timer: any;
  public isTimerOn: boolean = false;
  public timeInterval: any = 15000;
  @Output() chartRefreshed: EventEmitter<any>;

  constructor(
    private http: HttpClient, 
    private coreservice: CoreService,
    public authorizedService: AuthorizedService,
  ) {
    this.chartRefreshed = new EventEmitter<any>();
   }

  checkStorageLimit() {
    var url: string = settings.configuration.env.apiUrl + 'fleet/aircraftsummary';
    return this.http.get(url)
      .toPromise()
      .then((res: any) => {
        if (res != null) {
            if (res.used_space > res.content_disk_space) {
                return true;
            }
            else {
                return false;
            }
        } else {
            // NULL data in response (proper data is not received)
            return false;
        }
    });
  }

  startTimer() {
    this.isTimerOn = true;
    this.timer = setTimeout(() => {
      this.stopTimer();
      this.getCurrentManifestTimestemp();
      this.chartRefreshed.emit();
    }, this.timeInterval);
  }

  stopTimer() {
    if (this.isTimerOn) {
      clearTimeout(this.timer);
      this.isTimerOn = false;
    }
  }

  getCurrentManifestTimestemp() {
    if (this.authorizedService.checkIfAuthorized(['Global Privileges.Read'], 'onlyOne')) {
      this.getAircraftManifestLastCreatedDate(this.coreservice.aircraft.aircraft_id).then((res: any) => {
        this.coreservice.lastAircraftManifestTime = res;
      });
    }
  }

  getMediaDrive() {
    var url: string = settings.configuration.env.apiUrl + 'fleet/aircraftsummary';
    return this.http
      .get(url)
      .subscribe(
        (res: string) => { 
          this.coreservice.mediaDrive = res;
          if (this.authorizedService.checkIfAuthorized(['Global Privileges.Read'], 'onlyOne')) {
            if (this.isTimerOn) {
              this.stopTimer();
            }
            this.startTimer();
          }
         },
        (error: string) => { this.generateZeroData }
      )
  }

  getAircraftManifestLastCreatedDate(aircraftId: string) {
    console.log("getAircraftManifestLastCreatedDate Called...");
      var url: string = settings.configuration.env.apiUrl + 'fleet/aircraft/lastManifestUpdatedDate/' + aircraftId;
      return this.http
        .get(url)
        .toPromise().then(
          (res: any) => { return res.aircraft_manifest_last_created_date },
          (err: any) => { console.log ("Error getting Aircraft Manifest Last Created Date: ", err) }
        );
    }

  getPlaylistSize(playlistId: string) {
    var url: string = settings.configuration.env.apiUrl + 'playlists/playlist/' + playlistId + '/summary';
    return this.http
      .get(url)
      .subscribe(
        (res: string) => { this.coreservice.currentPlaylistSize = res },
        null
      )
  }


  generateZeroData() {
    var zeroData = [{
      content_disk_space: 0,
      document_count: 0,
      document_size: 0,
      destinations_count: 0,
      destinations_size: 0,
      episodes_count: 0,
      episodes_size: 0,
      free_disk_space: 0,
      games_count: 0,
      games_size: 0,
      magazines_count: 0,
      magazines_size: 0,
      movies_count: 0,
      movies_size: 0,
      tracks_count: 0,
      tracks_size: 0,
      used_space: 0
    }]
    
    return zeroData;
  }
}

angular
  .module('app.core')
  .factory('mediadriveService', downgradeInjectable( MediadriveService));
