import { Component, OnInit, Input, SimpleChange, SimpleChanges, KeyValueDiffers, Inject } from '@angular/core';
import settings from '../../../config.json';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material';
import { SelectItemsService } from '../core/services/select-items.service';
import { MatDialog } from "@angular/material";
import { Config }  from '../core/config';
import { DashPlayer } from '../video/dashplayer.component';
import { CoreService } from '../core/services/core.service';
import { AuthorizedService } from '../core/services/authorized.service';

@Component({
    selector: 'movie-dialog',
    templateUrl: './movie-dialog.component.html',
    styleUrls: ['./video.less']
})
  
export class MovieDialog implements OnInit {
    
    coverImage: string;
    image: string;
    imageCategory: string;
    imageChannel: string;
    movieDetails: any;
    details: any;
    trailerUri: string;
    dateTime: any;
    today: Date;
    until: Date;
    fromDate: Date;
    isPlaylist: boolean;
    blockUI: boolean;
    isUpdateAvailable: boolean;

    constructor(
        private dialog: MatDialog,
        private selectItems: SelectItemsService,
        public authorizedService: AuthorizedService,
        public coreservice: CoreService,
        private http: HttpClient,
        private route: ActivatedRoute,
        private moviedialog: MatDialogRef<MovieDialog>,
        private config: Config,
        @Inject(MAT_DIALOG_DATA) private dialogData: any,) {

        this.coverImage = settings.configuration.env.apiUrl;
        this.image = settings.configuration.env.apiUrl + 'images/movies/' + this.config.IMAGE_FORMATS.MOVIES_DETAILS + '/';
        this.imageCategory = settings.configuration.env.apiUrl + 'images/categories/' + this.config.IMAGE_FORMATS.DETAILS_CATEGORIE_BANNER + '/';
        this.imageChannel = settings.configuration.env.apiUrl + 'images/channels/' + this.config.IMAGE_FORMATS.DETAILS_CATEGORIE_BANNER + '/';
        this.dateTime = settings.configuration.datetime_formats;
        this.isPlaylist = false;
        this.blockUI = false;
    }

    ngOnInit() {
        this.movieDetails = this.dialogData.movieDetails;
        this.isUpdateAvailable = this.dialogData.isUpdateAvailable;

        if (!!(this.movieDetails.trailer_uri && this.movieDetails.manifest_name)) {
            this.trailerUri = settings.configuration.env.apiUrl + 'trailers/path/' + this.movieDetails.manifest_name + '/' + this.movieDetails.trailer_uri;
        }
        this.details = this.movieDetails;
        console.log("details...", this.dialogData);
        this.today = new Date();
        this.until = new Date(this.details.valid_until);
        this.fromDate = new Date(this.details.valid_from);

        this.activate();
    }

    getQueryParam(): any {
        this.route.paramMap.subscribe(params => {
        return params.get('playlist_id');
        });
    }
    
    activate() {
        if (this.getQueryParam()) {
            this.isPlaylist = true;
        } else {
            this.isPlaylist = false;
        }
    }

    selectItem(movie: any) {
        var movie_id: string = movie.movie_id;
        var status: number = movie.is_selected;
        var flag: boolean;
        if (status == 0) {
            flag = true;
        } else if (status == 4) {
            flag = false;
        }

        this.selectItems.addToList(movie_id, 'movie', 'video', flag, null)
            .then((isSelectable: any) => {
                this.moviedialog.close();
        });
    }

    openMovieTrailer() {
        if(!this.blockUI) {
            this.blockUI = true;
            let promise = Promise.resolve(this.trailerUri);
            promise.then(() => {
                var dialogConfig = new MatDialogConfig();
                dialogConfig.data = {
                    trailerUri: this.trailerUri
                };
                dialogConfig.panelClass = ['matdialog-custom-dialog', 'video-width'];
                let dashDialog = this.dialog.open(DashPlayer, dialogConfig);
                dashDialog.beforeClosed().subscribe(res => {
                    this.blockUI = false;
                });
            })
        }
    }

    closeThisDialog() {
        this.moviedialog.close();
    }
}

