import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CoreService } from '../core/services/core.service';

@Component({
  selector: 'app-aircraft-confirm-installation-type-dialog',
  templateUrl: '../aircraft/aircraft-general-confirm-dialog.html',
  styleUrls: ['./aircraft.less', './../layout/dialog-main.less']
})
export class AircraftGeneralConfirmDialogComponent implements OnInit {

  public heading: string = '';
  public message: string = '';
  public warningmessage: string = '';
  public action: string = '';
  public cancel: string = '';
  public showCarryOnWarning: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<AircraftGeneralConfirmDialogComponent>,
    public coreservice: CoreService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.heading = this.data.heading
    this.message = this.data.message
    this.warningmessage = this.data.messagewarning
    this.action = this.data.action
    this.cancel = this.data.cancel
    this.showCarryOnWarning = this.data.showCarryOnWarnig;
  }

  ngOnInit() { }

  actionClicked() { this.dialogRef.close({ event: 'action' }); }

  cancelClicked() { this.dialogRef.close({ event: 'cancel' }); }
}
