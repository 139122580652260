import { Component, OnInit } from '@angular/core';
declare var angular: angular.IAngularStatic;
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
  selector: 'stage',
  templateUrl: './stage.component.html',
  styleUrls: ['../layout/layout.less']
})
export class StageComponent implements OnInit {

  //showingList:boolean;
  constructor() { 
    //this.activate();
  }

  ngOnInit() {
  }

  /* This code is already implmented in ".ts" file of every html file for each asset */

  // activate() {
  //   this.listwatch();
  // }

  // listwatch() {
  //   this.showingList = false;
  //   this.showList();
  //   this.showTiles();
  // }
  // showList() {
  //   if (!this.showingList) {
  //       this.showingList = true;
  //   }
  // }
  // showTiles() {
  //   if (this.showingList) {
  //       this.showingList = false;
  //   }
  // }

}
angular
  .module('app.stage')
  .directive(
    'stage',
    downgradeComponent({component: StageComponent}) as angular.IDirectiveFactory
  );