import { Injectable } from '@angular/core';
import settings from '../../../../config.json';
import { AuthService } from './auth.service';
import { CoreService } from './core.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { encode as base64encode } from "base64-arraybuffer";
import { Config } from '../config';

// SSO_Branding: this service is for getting access token from the Keycloak server
@Injectable()
export class SSOConfigurationService {

  public discoveryURL: string;
  public code: string;
  public authUrl: string = '';
  public tokenUrl: string = '';
  public clientId: string = '';
  public redirectUri: string = '';

  constructor(
    public coreservice: CoreService,
    public authService: AuthService,
    private http: HttpClient,
  ) {

  }



  InitSSOConfig(_authUrl, _tokenUrl, _clientID, _redirectURL) {

    //this.discoveryURL = _discoveryURL;

    this.clientId = _clientID;
    this.redirectUri = _redirectURL;

    // this.authUrl = localStorage.getItem('authUrl');
    // this.tokenUrl = localStorage.getItem('tokenUrl');

    this.authUrl = _authUrl;
    this.tokenUrl = _tokenUrl;

  }

  discoveryConfigurationAPI() {

    var url: string = this.discoveryURL;
    return this.http.get(url)
      .toPromise().then((res: any) => {
        this.authUrl = res.authorization_endpoint;
        this.tokenUrl = res.token_endpoint;
        localStorage.setItem('authUrl', this.authUrl);
        localStorage.setItem('tokenUrl', this.tokenUrl);
      },
        (error: string) => {
          console.log("Error in getting Discovery Response" + error);
        }
      );
  }

  randomstring(L) {
    var s = '';
    var randomchar = function () {
      var n = Math.floor(Math.random() * 62);
      if (n < 10) return n; //1-10
      if (n < 36) return String.fromCharCode(n + 55); //A-Z
      return String.fromCharCode(n + 61); //a-z
    }
    while (s.length < L) s += randomchar();
    return s;
  }

  async generateCodeChallenge(codeVerifier) {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await window.crypto.subtle.digest("SHA-256", data);
    const base64Digest = base64encode(digest);
    // you can extract this replacing code to a function
    return base64Digest
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=/g, "");
  }


  //To redirect to SSO server
  redirectToSSO() {
    const code_verifier = this.randomstring(43);
    localStorage.setItem('code_verifier', code_verifier);
    this.generateCodeChallenge(code_verifier).then(challenge => {
      window.location.href = this.authUrl + '?client_id=' + this.clientId + '&code_challenge=' + challenge + '&code_challenge_method=S256&redirect_uri=' + this.redirectUri + '&scope=openid&response_type=code&response_mode=form_post&nonce=ttkr7xe5bfm';
      console.log("in redirectToSSO()");
    });
  }


  SSOServerRedirect() {
    if (this.authUrl == null || this.authUrl == '') {
      var url: string = this.discoveryURL;
      console.log("Discovery request ");
      this.http.get<Config>(url)
        .toPromise().then((res: any) => {
          console.log("Discovery Response ");
          this.authUrl = res.authorization_endpoint;
          this.tokenUrl = res.token_endpoint;
          localStorage.setItem('authUrl', this.authUrl);
          localStorage.setItem('tokenUrl', this.tokenUrl);
          setTimeout(() => {
            this.redirectToSSO();
          }, 300);
        },
          (error: string) => {
            console.log("Error in getting Discovery Response" + error);
          }
        );
    }
    else {
      this.redirectToSSO();
    }

  }


  getToken(code: string) {
    if (this.authUrl == null || this.authUrl == '') {
      this.discoveryConfigurationAPI()
        .then(
          (res: any) => {
            return this.tokenAPI(code);
          },
          (error: any) => {
            // SSO_Branding: error
            console.log("error in code request", error);
          });
    }
    else {
      return this.tokenAPI(code);
    }
  }


  // For getting Access Token
  tokenAPI(code: string) {
    var url: string = this.tokenUrl;
    var code_verifier = localStorage.getItem('code_verifier');

    var params = new HttpParams()
      .append('grant_type', 'authorization_code')
      .append('client_id', this.clientId)
      .append('code', code)
      .append('redirect_uri', this.redirectUri)
      .append('code_verifier', code_verifier);

    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    let options = {
      headers: httpHeaders
    };
    return this.http
      .post(url, params, options)
      .toPromise().then(
        (res: any) => { return res },
        (err: any) => { console.log("Error in getting Access Token" + err) });
  }
}
