import { Injectable } from '@angular/core';
declare var angular: any;
import settings from '../../../../config.json';
import { HttpClient } from '@angular/common/http';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
  providedIn: 'root'
})
export class SelectedContentService {

  constructor(private http: HttpClient) { }

  getSelectedMovies() {
    console.log("getSelectedMovies");
    var url: string = settings.configuration.env.apiUrl + 'content/video/selected/movies';
    return this.http.get(url)
    .toPromise().then((res: any) => {
      return res;
    }); 
  }

  getSelectedEpisodes() {
    console.log("getSelectedEpisodes");
    var url: string = settings.configuration.env.apiUrl + 'content/tvshows/seasons/selected/episodes';
    return this.http.get(url)
    .toPromise().then((res: any) => {
      return res;
    }); 
  }

  getSelectedTrackes() {
    console.log("getSelectedTrackes");
    var url: string = settings.configuration.env.apiUrl + 'content/music/selected/tracks';
    return this.http.get(url)
    .toPromise().then((res: any) => {
      return res;
    }); 
  }

  getSelectedGames() {
    console.log("getSelectedGames");
    var url: string = settings.configuration.env.apiUrl + 'content/games/selected/games';
    return this.http.get(url)
      .toPromise().then((res: any) => {
        return res;
      });
  }

    getSelectedGamesPlaylist(playlistId) {
        console.log("getSelectedGamesPlaylist");
        var url: string = settings.configuration.env.apiUrl + 'playlist/' + playlistId + '/content/selected/games';
        return this.http.get(url)
        .toPromise().then((res: any) => {
          return res;
        });
    }

    getSelectedMagazines() {
      console.log("getSelectedMagazines");
        var url: string = settings.configuration.env.apiUrl + 'content/magazines/selected/magazines';
        return this.http.get(url)
        .toPromise().then((res: any) => {
          return res;
        });
    }

    getSelectedDestinations() {
      console.log("getSelectedDestinations");
        var url: string = settings.configuration.env.apiUrl + 'content/destinations/selected/destinations';
        return this.http.get(url)
        .toPromise().then((res: any) => {
          return res;
        });
    }

    getSelectedFolders() {
      console.log("getSelectedFolders");
        var url: string = settings.configuration.env.apiUrl + 'content/documents/selected/folders';
        return this.http.get(url)
        .toPromise().then((res: any) => {
          return res;
        });
    }

    getSelectedStocks() {
      console.log("getSelectedStocks");
        var url: string = settings.configuration.env.apiUrl + 'content/stocks/allselected/stocks';
        return this.http.get(url)
        .toPromise().then((res: any) => {
          return res;
        });
    }

    getSelectedNews() {
      console.log("getSelectedNews");
      var url: string = settings.configuration.env.apiUrl + 'content/feeds/selected/news';
      return this.http.get(url)
      .toPromise().then((res: any) => {
        return res;
      });
    }

    getSelectedSports() {
      console.log("getSelectedSports");
        var url: string = settings.configuration.env.apiUrl + 'content/feeds/selected/sports';
        return this.http.get(url)
        .toPromise().then((res: any) => {
          return res;
        });
    }
}
angular
  .module('app.core')
  .factory('selectedContent', downgradeInjectable(SelectedContentService));
