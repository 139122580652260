import { Injectable, Output, EventEmitter } from '@angular/core';
declare var angular: any;
import { downgradeInjectable } from '@angular/upgrade/static';

import { 
  HttpInterceptor, 
  HttpHandler, 
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
  HttpClient,
} from '@angular/common/http';
import { Observable, throwError, from, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { CoreService } from './core.service';
import { ToastService } from './toast.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class AuthInterceptorService implements HttpInterceptor {
 
  @Output() readyToRecallPendings: EventEmitter<any> = new EventEmitter();
  private hasPublished401: boolean;

  constructor(
    private coreservice: CoreService,
    private toastService: ToastService,
    private authService: AuthService,
    private router: Router,
    private http: HttpClient
  ) {
      this.hasPublished401 = false;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const newRequest: HttpRequest<any> = this.addToken(request);
    return next.handle(newRequest)
      .pipe(
        catchError( (error: HttpErrorResponse) => {
          if (error.error instanceof ErrorEvent) {
            console.error('****An error occurred****', error.message);
          } else {
            switch(error.status) {
              case 401:
                if(!this.coreservice.user) {
                  console.error("****Unknown user****" + error.message);
                } else if (!this.hasPublished401) {
                    this.hasPublished401 = true;
                    this.toastService.toastError('error_not_authenticated', 'error');
                    setTimeout( () => {
                      this.hasPublished401 = false;
                    }, 2000);

                    this.authService.removeToken();
                    setTimeout( () => {
                        this.router.navigateByUrl("/login");
                    }, 1500);
                }
              break;
    
              case 403:
                this.toastService.toastError('error_not_authorized', 'error');
              break;
    
              case 440:
                return this.handleError440(request, next);
                break;

              default:
                break;
          }
        }

        return throwError(error);
      }));
  }

  addToken(request: HttpRequest<any>) {
    var authData: any = null;
    var localStorageAuth: any = localStorage.getItem('authorizationData');
    var sessionStorageAuth: any = sessionStorage.getItem('authorizationData');

    if(!!localStorageAuth) {
      authData = localStorageAuth;
      return request.clone({ setHeaders: { Authorization: 'Bearer ' + JSON.parse(authData).token } });
    } else if (!!sessionStorageAuth) {
      authData = sessionStorageAuth;
      return request.clone({ setHeaders: { Authorization: 'Bearer ' + JSON.parse(authData).token } });
    }

    return request;
  }

  private handleError440(request: HttpRequest<any>, next: HttpHandler) {
    return new Promise<any> ((resolve, reject) => {
      if (!this.authService.isrefreshTokenInProgress()) {
          this.authService.refreshToken().then( (res: any) => {
          if (res.privileges_changed) {
              localStorage.setItem('privilegesChanged', 'true');
             // this.refresh();
             this.http.request(request).toPromise().then(
              (res: any) => { resolve(res) },
              (err: any) => { reject(err) }
            );
            
          } else {
              this.http.request(request).toPromise().then(
              (res: any) => { resolve(res) },
              (err: any) => { reject(err) }
            );
          }
          this.authService.ontokenRefreshed();
        }, null);
      } else {
        this.authService.tokenRefreshed.subscribe( () => {
            this.http.request(request).toPromise().then(
              (res: any) => { resolve(res) },
              (err: any) => { reject(err) }
            );
        });
      }
    });
  }

  refresh() {
    var currentPolicy: "reload" | "ignore" = this.router.onSameUrlNavigation;
    var currentUrl: string = this.router.url;
    this.router.onSameUrlNavigation = "reload";
    this.router.navigate([currentUrl])
      .then(
        (value: boolean) => { this.router.onSameUrlNavigation = currentPolicy},
        (reason: any) => { this.router.onSameUrlNavigation = currentPolicy });
  }
}

angular
  .module('app.core')
  .factory('authInterceptorServiceng', downgradeInjectable(AuthInterceptorService));
