import { Injectable } from '@angular/core';
declare var angular: any;
import { downgradeInjectable } from '@angular/upgrade/static';

import { HttpClient } from '@angular/common/http';
import settings from '../../../../config.json';
@Injectable({
  providedIn: 'root'
})
export class PlaylistService {

  constructor(private http: HttpClient) { }

  getPlaylists(sort) {
    var url: string = settings.configuration.env.apiUrl + 'playlists' + '?sort=' + sort;
    return this.http.get(url)
        .toPromise()
        .then((res: any) => {
            return res;
        });
  }

  getPlaylist(id) {
    var url: string = settings.configuration.env.apiUrl + 'playlists/playlist/' + id;
    return this.http.get(url)
        .toPromise()
        .then((res: any) => {
            return res;
        });
  }

  getPlaylistItems(playlistId, contentType, type, skip, take, filter, sort, language) {
    var url: string = settings.configuration.env.apiUrl + 'playlist/' + playlistId + '/' + contentType + '/' + type + '?' + 'skip=' + skip + '&take=' + take + '&filter=' + filter + '&sort=' + sort + '&language=' + language;
    return this.http.get(url)
        .toPromise()
        .then((res: any) => {
            return res;
        });
  }

  getChanneledPlaylistItems(playlistId, contentType, type, skip, take, filter, sort, language, channelname) {
    var url: string = settings.configuration.env.apiUrl + 'playlist/' + playlistId + '/' + contentType + '/' + type + '?' + 'skip=' + skip + '&take=' + take + '&filter=' + filter + '&sort=' + sort + '&language=' + language + '&channel=' + channelname;
    return this.http.get(url)
        .toPromise()
        .then((res: any) => {
            return res;
        });
  }

  addToListPlaylist(item_id, type, contentType, boolean) {
    var url: string = settings.configuration.env.apiUrl + contentType + '/select/' + boolean + '/' + type + '/' + item_id;
    return this.http
      .post(url, null)
      .toPromise().then((res) => {
          return res 
       });
  }
}

angular
  .module('app.core')
  .factory('playlistService', downgradeInjectable(PlaylistService));
