import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'operatorfilterInAircraft'
})
export class OperatorfilterInAircraftPipe implements PipeTransform {

  transform(items:any, filterOperator:any): any {
    if(filterOperator == "")
    {
        return items;
    }
    var filtered = [];
    for (var i = 0; i < items.length; i++) {
        var item = items[i];
        if(item.operator_id == filterOperator)
        {
            filtered.push(item);
        }   
    }
    return filtered;
  }

}
