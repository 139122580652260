import { Component, OnInit, DoCheck, KeyValueDiffers } from '@angular/core';
declare var angular: angular.IAngularStatic;
import * as _ from "lodash";
import settings from '../../../config.json';
import { Config }  from '../core/config';
import { DataService } from '../core/services/data.service';		
import { ChannelService } from '../core/services/channel.service';		
import { SortingService } from '../core/services/sorting.service';		
import { PlaylistService } from '../core/services/playlist.service';
import { ActivatedRoute } from '@angular/router';
import { MediadriveService } from '../core/services/mediadrive.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { SelectItemsService } from '../core/services/select-items.service';
import { CoreService } from '../core/services/core.service';
import { AuthorizedService } from '../core/services/authorized.service';
import { downgradeComponent } from '@angular/upgrade/static';
import { AlbumDialogComponent } from './album-dialog.component';

@Component({
  selector: 'musiccontent',
  templateUrl: './musiccontent.component.html',
  styleUrls: ['./musiccontent.component.less', '../video/video.less', '../layout/layout.less']
})
export class MusiccontentComponent implements OnInit, DoCheck {

  public coverImage;
  public image;
  public imageSml;
  public imageCategory;
  public imageChannel;
  public checkLoading: boolean;
  public getMore: boolean;
  public catalog: any = [];
  public filtering;
  public genreFilter;
  public filterFlag: boolean;
  public langFlag: boolean;
  public sortFlag: boolean;
  public channelFlag: boolean;
  public contentFlag: boolean;
  private filter;
  public sorting;
  public sortBy;
  private sort;
  public languages;
  public languageFilter;
  public contentItems;
  public channelItems;
  public dateTime = settings.configuration.datetime_formats;
  public isPlaylist;
  public message;
  public isLoading;
  public differ;
  public dataLimit;
  public content_search;
  public callName = "artistalbums";
  private blockUI = false;

  constructor(private config: Config,
              private dataService: DataService,
              private channelService: ChannelService,
              private sortingService: SortingService,
              private playlistService: PlaylistService,
              private route: ActivatedRoute,
              private mediadriveService: MediadriveService,
              private http: HttpClient,
              private dialog: MatDialog,
              private selectItems: SelectItemsService,
              public coreservice: CoreService,
              public authorizedService: AuthorizedService,
              private differs: KeyValueDiffers) { 

      this.differ = this.differs.find({}).create();
      this.getMusicData();

      this.coverImage = settings.configuration.env.apiUrl;
      this.image = settings.configuration.env.apiUrl + 'images/albums/' + this.config.IMAGE_FORMATS.MOVIES_GRID + '/';
      this.imageCategory = settings.configuration.env.apiUrl + 'images/categories/' + this.config.IMAGE_FORMATS.DEFAULT_SQUARE + '/';
      this.imageChannel = settings.configuration.env.apiUrl + 'images/channels/' + this.config.IMAGE_FORMATS.CHANNEL_BANNER + '/';
      this.checkLoading = true;
      this.getMore = true;
      this.catalog = null;
      this.filterFlag = false;
      this.langFlag = false;
      this.sortFlag = false;
      this.channelFlag = false;
      this.contentFlag = false;
      this.dataLimit = 30;
      this.coreservice.spinner = true;
  }

  ngOnInit() {}

  ngDoCheck(){
      if (!!this.coreservice.aircraft) {
          const change = this.differ.diff(this.coreservice);
          if (change) {
              change.forEachChangedItem(item => {
                  if (item.currentValue != item.previousValue && item.key == 'aircraft') {
                      this.cleanCall();
                  }
              })
          }
      }
  }

  trackByFn(index, album){
      return album.album_id;
  }

  async getMusicData() {
      this.channelService.getChannels('album').then((res: any) => {
          this.channelItems = res; 
          this.channelFlag = true;
      });
      this.sortingService.getFiltering('music').then((res: any) => {
          this.filtering = res; 
          this.filterFlag = true;
          this.genreFilter = this.filtering[0].id;
          this.filter = this.genreFilter;
      });
      await this.sortingService.getSorting('artists').then((res: any) => {
          this.sorting = res; 
          this.sortFlag = true;
          this.sortBy = this.sorting[0].id;
          for(var f in this.sorting){
            if(this.sorting[f].id == "Added Date") {
                this.sortBy = this.sorting[f].id;
            }
          }
          this.sort = this.sortBy;
      });
      if (window.location.pathname == '/content/music/all') {
        this.dataService.getMediaItems('music', 'artistalbums', 0, 30, '', 'Added Date','').then((res: any) => {
            this.contentItems = res;
            this.catalog = this.contentItems;
            this.contentFlag = true;
        });
      }
      else {
        this.dataService.getChanneledData('music', 'artistalbums', 0, 30, '', 'Added Date','', window.location.pathname.substr(window.location.pathname.lastIndexOf('/') + 1, window.location.pathname.length)).then((res: any) => {
          this.contentItems = res;
          this.catalog = this.contentItems;
          this.contentFlag = true;
        });
      }
  }y

  getQueryParam(param: any): any {
      this.route.paramMap.subscribe(params => {
          return params.get(param);
      });
  }

  finished(checkLoading: boolean = true) {
      if (checkLoading == true){
          this.increaseLimit();
          this.checkLoading = false; 
      }
  };

  activate() {
      this.isPlaylist = !!this.getQueryParam('playlist_id') ? true : false;
      this.watchDatalimit();
  }

  showList() {
      if (!this.coreservice.showingList) {
          this.coreservice.showingList = true;
      }
  }
  showTiles() {
    if (this.coreservice.showingList) {
        this.coreservice.showingList = false;
    }
  }

  watchDatalimit() {
      this.dataLimit = 30;
      this.increaseLimit();
  }

  increaseLimit() {
      if (this.getMore) {
          var oldLimit = this.dataLimit;
          this.dataLimit += 30;
          this.getMoreItems(oldLimit, 30);
      }
  }
  
  getMoreItems(skip: any, take: any) {
      if (this.getMore) {
          if (!!this.getQueryParam('playlist_id')) {
              return this.playlistService.getPlaylistItems(this.getQueryParam('playlist_id'), 'music', this.callName, skip, take, this.filter, this.sort, '')
                  .then((res: any) => {
                      if (res.length >= 1) {
                          return this.catalog = this.catalog.concat(res);
                      } else {
                          this.mediadriveService.getMediaDrive();
                          this.getMore = false;
                      }
                  });
          } else {
              var channelname = window.location.pathname.substr(window.location.pathname.lastIndexOf('/') + 1, window.location.pathname.length)
              if(channelname != '') {
                  return this.dataService.getMediaItems('music', this.callName, skip, take, this.filter, this.sort, '')
                  .then((res) => {
                      if (res.length >= 1) {
                          this.catalog = this.catalog.concat(res);
                          this.finished();
                      } else {
                          this.mediadriveService.getMediaDrive();
                          this.getMore = false;
                      }
                  }); 
              } else {
                return this.dataService.getChanneledData('music', this.callName, skip, take, this.filter, this.sort, '', channelname)
                      .then((res) => {
                          if (res.length >= 1) {
                              this.catalog = this.catalog.concat(res);
                              this.finished();
                          } else {
                              this.mediadriveService.getMediaDrive();
                              this.getMore = false;
                          }
                      }); 
              }
          } 
      }
  }

  filterSelected() {
      this.cleanCall();
  }

  sortSelected() {
      this.cleanCall();
  }

  callNameSelected() {
      this.cleanCall();
  }

  cleanCall() {
      var skip = 0;
      var take = 30;
      this.sort = this.sortBy;
      this.filter = this.genreFilter;
      this.dataLimit = 30;
      this.getMore = false;
      this.message = '';
      this.catalog = [];

      var mediaContainer = document.getElementsByClassName('media-files')[0];
      var mediaListContainer = document.getElementsByClassName('double-small')[0];

      if (!!mediaContainer && !!mediaListContainer) {
          mediaContainer.scrollTop = 0;
          mediaListContainer.scrollTop = 0;
      } else if (!!mediaContainer) {
          mediaContainer.scrollTop = 0;
      }

      this.isLoading = true;

      this.channelService.getChannels('album').then((res) => {
          return this.channelItems = res;
      })

      if (!!this.getQueryParam('playlist_id')) {
          return this.playlistService.getPlaylistItems(!!this.getQueryParam('playlist_id'), 'music', this.callName, skip, take, this.filter, this.sort, channelname)
              .then((res) => {
                  if (res.length >= 1) {
                      this.getMore = true;
                      this.message = '';
                      return this.catalog = res;
                  } else {
                      this.getMore = false;
                      this.mediadriveService.getMediaDrive();
                      this.message = 'no_results';
                      return this.catalog = [];
                  }
              });
      } else {
          var channelname = window.location.pathname.substr(window.location.pathname.lastIndexOf('/') + 1, window.location.pathname.length)
          if(channelname != '') {
              return this.dataService.getChanneledData('music', this.callName, skip, take, this.filter, this.sort, '', channelname)
              .then((res) => {
                  if (res.length >= 1) {
                      this.getMore = true;
                      this.message = '';
                      this.finished();
                      return this.catalog = res;
                  } else {
                      this.getMore = false;
                      this.mediadriveService.getMediaDrive();
                      this.message = 'no_results';
                      return this.catalog = [];
                  }
              });
          } 
      }
  }

  getDetails(album: any) {
      var albumId = album.album_id;
      this.getAlbumDetails(albumId);
  }

  getAlbums(albumId: any) {
      var url: string = '';
      var params: string = this.getQueryParam('playlist_id');
      if (params) {
          url = settings.configuration.env.apiUrl + 'playlist/' + params + '/music/album/' + albumId;
      } else {
          url = settings.configuration.env.apiUrl + 'content/music/album/' + albumId;
      }

      return this.http
          .get(url)
          .toPromise().then(
              (res: any) => { return res }
          )
  }
  
  async getAlbumDetails(albumId: any) {
      var albumDetails;
      if (!this.blockUI) {
          this.blockUI = true;
          await this.getAlbums(albumId).then((res: any) => {
              albumDetails = res;
          });

          var dialogConfig = new MatDialogConfig();
          dialogConfig.data = {
              albumDetails: albumDetails
          };

          dialogConfig.panelClass = ['matdialog-custom-dialog', 'episode-width'];
          let albumdialog = this.dialog.open(AlbumDialogComponent, dialogConfig);
          albumdialog.beforeClosed().subscribe(res => {
              this.blockUI = false;
          })
      }
  }

  selectItem(album: any, index: any) {
      var album_id = album.album_id;
      var status = album.is_selected;
      var albumIndex = index;
      var boolean;

      switch (status) {
          case 0:
              boolean = true;
              break;
          case 1:
              boolean = false;
              break;
          case 4:
              boolean = false;
              break;
          default:
              boolean = true;
              break;
      }

      this.selectItems.addToListAlbum(album_id, 'music', 'albums', 'album', boolean, this.catalog[albumIndex]).then((res) => {
          var newStatus = res.is_selected;
          //this.catalog[albumIndex].is_selected = newStatus;
      });
  }

  selectTrack(track: any, index: any) {
      var status = track.is_selected;
      var trackIndex = index;
      var boolean;
      var newStatus;
      
      if (status == 0) {
          boolean = true;
          newStatus = 4;
      } else if (status == 4) {
          boolean = false;
          newStatus = 0;
      }
    
      this.selectItems.addToListTrack(track, 'music', 'album', 'track', boolean, this.catalog[trackIndex]).then( () => {
        //this.catalog[trackIndex].is_selected = newStatus;
      });
  }

  selectedStatus(albumId: any) {
      var url;
      var newStatus;
      var params: string = this.getQueryParam('playlist_id');
      if (params) {
          url = settings.configuration.env.apiUrl + 'playlist/' + params + '/music/album/' + albumId;
      } else {
          url = settings.configuration.env.apiUrl + 'content/music/album/' + albumId;
      }

      return this.http
          .get(url)
          .toPromise().then(
              (res: any) => {
                  newStatus = res.data.is_selected;
                  _.find(this.catalog, { album_id: albumId }).is_selected = newStatus; 
              }
          )
  }
}

angular
  .module('app.music')
  .directive(
    'musiccontent',
    downgradeComponent({component: MusiccontentComponent}) as angular.IDirectiveFactory
  );
  