import { Component, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import {MatDialog, MatDialogConfig} from "@angular/material";

import settings from 'src/config.json';
import { FooterUserAgreementComponent } from '../user-agreement/footer-user-agreement.component';
declare var angular: angular.IAngularStatic;

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./layout.less']
})
export class FooterComponent implements OnInit {

    constructor(private dialog: MatDialog) { }

    ngOnInit() {
    }
  // var vm = this;
  // $scope.$state = $state;

  // vm.showTerms = showTerms;
  // $rootScope.blockUI = false;

  // vm.versionNumber = settings.configuration.version;
    versionNumber = settings.configuration.version;

    showTerms() {
        this.dialog.open(FooterUserAgreementComponent);
            // Migration is remaining for below controller code
            // This controller code would be migrated into FooterUserAgreementComponent file
            // if (!$rootScope.blockUI) {
            //     $rootScope.blockUI = true;
            //     ngDialog.open({
            //         template: 'app/login/user-agreement-dialog.html',
            //         className: 'ngdialog-custom-dialog agreement-dialog',
            //         closeByDocument: true,
            //         closeByEscape: true,
            //         showClose: false,
            //         controller: ['$scope', 'userService', 'userAgreement', 'settings', '$timeout', function ($scope, userService, userAgreement, settings, $timeout) {
            //             $scope.withActions = false;
            //             var userAgreementAppName = userAgreement.data.name;
            //             var userAgreementAppFilePath = userAgreement.data.url;

            //             $scope.terms = settings.configuration.env.apiUrl + 'terms/term/' + userAgreementAppName + '/' + userAgreementAppFilePath + '?rc-host-origin=' + settings.configuration.env.hostOrigin;
            //             $scope.showAgreementLoading = true;

            //             RCHOST.handleSubscribe = function (callback) {
            //                 function getApplicationsIFrame() {
            //                     return document.getElementById('rc-app-iframe');
            //                 }

            //                 function getApplicationIFrameContent() {
            //                     return getApplicationsIFrame() ? getApplicationsIFrame().contentWindow : null;
            //                 }

            //                 function pushEvents() {
            //                     var applications_iframe = getApplicationsIFrame();
            //                     if (applications_iframe) {
            //                         var app = getApplicationIFrameContent();
            //                         if (app) {
            //                             var settings = RCHOST.createSystemEvents();
            //                             settings.push(new RCHOST.SystemEvent('metadata', {'app_data': userAgreement.data.app_data}, null));
            //                             RCHOST.pushSystemEventsToClient(app, settings);
            //                             callback(app);
            //                             return;

            //                         }
            //                     }
            //                 }

            //                 pushEvents();
            //             };
            //             RCHOST.handleSubscribe();

            //             RCHOST.onSystemEvents = function (events) {
            //                 var systemEvents = JSON.parse(events);
            //                 for (var i = 0; i < systemEvents.length; i++) {
            //                     var systemEvent = systemEvents[i];
            //                     var value = systemEvent['value'];
            //                     switch (systemEvent["name"]) {
            //                         case "contentLoaded":
            //                             $timeout(function () {
            //                                 $scope.showAgreementLoading = false;
            //                             }, 1000);
            //                             break;
            //                         default:
            //                             // TODO - HANDLE
            //                             break;
            //                     }
            //                 }
            //             };
            //         }],
            //         resolve: {
            //             userAgreement: ['userService', function (userService) {
            //                 return userService.getUserAgreement();
            //             }]
            //         },
            //         preCloseCallback: function (value) {
            //             $rootScope.blockUI = false;
            //         }
            //     });
            // }
    }
}

angular
  .module('app.layout')
  .directive(
    'footer',
    downgradeComponent({component: FooterComponent}) as angular.IDirectiveFactory
  );