import { Component, OnInit } from '@angular/core';
import * as _ from "lodash";
import { downgradeComponent } from '@angular/upgrade/static';
import { DownloadService } from '../core/services/download.service';
import { ToastService } from '../core/services/toast.service';
import { Location } from '@angular/common';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
declare var angular: angular.IAngularStatic;

@Component({
  selector: 'sync',
  templateUrl: './sync.component.html',
  styleUrls: ['./sync.component.less'],
  styles: [':host { width: -webkit-fill-available; }']
})

export class SyncComponent implements OnInit {

  public originalFiles: any = [];
  public files: any = [];
  public filesGrouping: any = [];
  public filesnotGrouping: any = [];
  public groupCount: any = [];
  public getListFlag: boolean = true;
  public showLoaderFlag: boolean = true;
  private syncQueuePath:string = '/download';

  private syncList: any = [];
  private sportsScoresList: any = []; // 0-25-sportsscores
  private newsList: any = []; // 0-25-news
  private financialQuotesList: any = []; // 0-28-
  private weatherList: any = []; // 0-29-
  private weatherCityList: any = []; // 0-30-

  private contentTypes: any = [
      { "name": "Weather Forecasts", "order": 1, "prefix": "0-29-"},
      { "name": "Weather Forecasts", "order": 1, "prefix": "0-30-"},
      { "name": "Financial Quotes",  "order": 1, "prefix": "0-28-"},
      { "name": "Sports Scores",     "order": 1, "prefix": "0-25-sportsscores"},
      { "name": "News Articles",     "order": 1, "prefix": "0-25-news"}
  ];


  constructor(private downloadService: DownloadService,
              private toastService: ToastService,
              private _location: Location) {
      this.originalFiles = angular.extend({}, this.files);
  }

  ngOnInit() { }

  ngDoCheck() {
      var aircraft_id = window.location.pathname.split(this.syncQueuePath).pop();
      if (aircraft_id != '' && this.getListFlag) {
          this.getSyncList(aircraft_id.split('/').pop());
          this.getListFlag = false;
      }
  }

  onDrop(event: CdkDragDrop<string[]>) {
      if (event.previousContainer === event.container) {
          moveItemInArray(event.container.data,
              event.previousIndex,
              event.currentIndex);

              var aircraftId: any = window.location.pathname.substr(window.location.pathname.lastIndexOf('/') + 1);
              var newIndex: any = event.currentIndex;
              var fileName: any = this.filesnotGrouping[newIndex].name;

              this.downloadService.setSyncOrder(aircraftId, fileName, newIndex).then((res: any) => {
                if (res.status === 200) {
                    this.originalFiles = angular.extend({}, this.files);
                } else {
                    this.files = this.originalFiles;
                }
              });
      } else {
      transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex, event.currentIndex);
      }
  }

  fileTop(index: any) {
      var aircraftId: any = window.location.pathname.substr(window.location.pathname.lastIndexOf('/') + 1);
      var fileName: any = this.filesnotGrouping[index].name;
      var newIndex: any = 0;

      this.filesnotGrouping.splice(newIndex, 0, this.filesnotGrouping.splice(index, 1)[0]);

      this.downloadService.setSyncOrder(aircraftId, fileName, newIndex).then((res: any) => {
          if (res.status === 200) {
              this.originalFiles = angular.extend({}, this.files);
          } else {
              this.files = this.originalFiles;
          }
      });
  }

  fileUp(index: any) {
      if (index <= 0 || index >= this.files.length) {
          return;
      }

      var aircraftId: any = window.location.pathname.substr(window.location.pathname.lastIndexOf('/') + 1);
      var fileName: any = this.files[index].name;
      var newIndex: any = index - 1;

      var temp = this.files[index];
      this.files[index] = this.files[index - 1];
      this.files[index - 1] = temp;

      this.downloadService.setSyncOrder(aircraftId, fileName, newIndex).then((res: any) => {
          if (res.status === 200) {
              this.originalFiles = angular.extend({}, this.files);
          } else {
              this.files = this.originalFiles;
          }
      });
  }

  fileDown(index: any) {
      if (index < 0 || index >= (this.files.length - 1)) {
        return;
      }

      var aircraftId: any = window.location.pathname.substr(window.location.pathname.lastIndexOf('/') + 1);
      var fileName: any = this.files[index].name;
      var newIndex: any = index + 1;

      var temp = this.files[index];
      this.files[index] = this.files[index + 1];
      this.files[index + 1] = temp;

      this.downloadService.setSyncOrder(aircraftId, fileName, newIndex).then((res: any) => {
          if (res.status === 200) {
              this.originalFiles = angular.extend({}, this.files);
          } else {
              this.files = this.originalFiles;
          }
      });
  }

  getSyncList(aircraft_id: any) {
      this.downloadService.getSyncList(aircraft_id).then((res: any) => {
          this.files = this.getOrderedList(res);
          this.showLoaderFlag = false;
          this.removeOtherAsset();
          this.GroupingFileList();
    });
  }

  GroupingFileList() {
      for (var i = 0; i < this.files.length; i++) {
          if (this.files[i].asset_type == 'Financial Quotes' || this.files[i].asset_type == 'Sports Scores' || this.files[i].asset_type == 'News Articles' || this.files[i].asset_type == 'Weather Forecasts')
              this.filesGrouping.push(this.files[i]);
          else
              this.filesnotGrouping.push(this.files[i]);
      }
  }

  removeOtherAsset() {
    var filteredFiles: any = [];
      for (var i = 0; i < this.files.length; i++) {
          if (this.files[i].asset_type != "Other") {
              filteredFiles.push(this.files[i]);
          }
      }
      this.files = filteredFiles;
  }

  setDownloadMethod(file: any, method: any) {
      var connectionTypeId: any = method.id;
      var fileId = file.name;
      var aircraftId: any = window.location.pathname.substr(window.location.pathname.lastIndexOf('/') + 1);
      var methodStatus: any = method.is_selected;

      var fileNames = this.getFileNames(fileId);
      return this.downloadService.setDownloadMethod(aircraftId, fileNames, connectionTypeId, methodStatus).then(
        (res: any) => {
            if (res.status === 200) {
                var newStatus = res.body.is_selected;
                _.each(this.files, (file: { name: any; download_methods: any; }) => {
                    if (file.name === fileId) {
                        _.find(file.download_methods, { id: connectionTypeId }).is_selected = newStatus;
                    }
                });
              this.toastService.toastSuccess("success_aircraft_download_method_updated", "sync_download_preferences");
            } else {
            if (file.name === fileId) {
                _.find(file.download_methods, { id: connectionTypeId }).is_selected = methodStatus;
            }
            this.toastService.toastError("error_aircraft_download_method_update", "sync_download_preferences");
          }
        },
        (err: any) => { this.toastService.toastError("error_aircraft_download_method_update", "sync_download_preferences") }
      );
  }

  back() {
      this._location.back();
  }

    getOrderedList(elements: any) {
        this.syncList.length = 0;
        this.weatherList.length = 0;
        this.weatherCityList.length = 0;
        this.financialQuotesList.length = 0;
        this.sportsScoresList.length = 0;
        this.newsList.length = 0;

        for(var i=0; i < elements.length ; i++) {
            if      (elements[i].name.startsWith(this.contentTypes[0].prefix)) { this.weatherList.push(elements[i]) }
            else if (elements[i].name.startsWith(this.contentTypes[1].prefix)) { this.weatherCityList.push(elements[i]) }
            else if (elements[i].name.startsWith(this.contentTypes[2].prefix)) { this.financialQuotesList.push(elements[i]) }
            else if (elements[i].name.startsWith(this.contentTypes[3].prefix)) { this.sportsScoresList.push(elements[i]) }
            else if (elements[i].name.startsWith(this.contentTypes[4].prefix)) { this.newsList.push(elements[i]) }
            else { this.syncList.push(elements[i]) }
        }

        if(this.newsList.length > 0)            { this.syncList.unshift(this.createElement(this.contentTypes[4].name, 1, this.newsList[0])) }
        if(this.sportsScoresList.length > 0)    { this.syncList.unshift(this.createElement(this.contentTypes[3].name, 1, this.sportsScoresList[0]) ) }
        if(this.financialQuotesList.length > 0) { this.syncList.unshift(this.createElement(this.contentTypes[2].name, 1, this.financialQuotesList[0]) ) }
        if(this.weatherCityList.length > 0)     { this.syncList.unshift(this.createElement(this.contentTypes[1].name, 1, this.weatherCityList[0]) ) }
        if(this.weatherList.length > 0)         { this.syncList.unshift(this.createElement(this.contentTypes[0].name, 1, this.weatherList[0]) ) }

        return this.syncList;
    }

    createElement(elementName: string, elementOrder: number, element: any) {
        var element: any = 
        {
            asset_type: elementName,
            download_methods: element.download_methods,
            downloadStatus: 0,
            is_usb: false,
            name: elementName,
            order: elementOrder,
            title: elementName,
            validFrom: null,
            validUntil: null,
            sync_status: null,
            sync_message: null,
            sync_progress: 0
        }

        return element;
    }


    getFileNames(fileName: string) {
        var list: any = null;
        var fileNames: string = "";
        if      (fileName===this.contentTypes[0].name) { list = this.weatherList }
        else if (fileName===this.contentTypes[1].name) { list = this.weatherCityList }
        else if (fileName===this.contentTypes[2].name) { list = this.financialQuotesList }
        else if (fileName===this.contentTypes[3].name) { list = this.sportsScoresList }
        else if (fileName===this.contentTypes[4].name) { list = this.newsList }
        else { list = null }

        if(list!==null) {
            if(list.length > 0) {
                for (var i=0; i < list.length; i++) { 
                    if(fileNames==="") {
                        fileNames = list[i].name;
                    } else {
                        fileNames = fileNames.concat(";", list[i].name);
                    }
                }

                if(fileNames.substring(0, fileNames.length)===";") {
                    fileNames.slice(0,-1);
                }

                return fileNames;
            }
        } else {
            return fileName;
        }
    }
}

angular
  .module('app.download')
  .directive(
    'sync',
    downgradeComponent({ component: SyncComponent }) as angular.IDirectiveFactory
  );
