import { Component, OnInit } from '@angular/core';
import settings from '../../../config.json';
import { UserService } from '../core/services/user.service';
import { BridgeService } from '../core/services/bridge.service';

var _app_data: any;

@Component({
  //selector: 'app-user-agreement',
  templateUrl: './user-agreement.component.html',
  styleUrls: ['./user-agreement.component.less']
})
export class FooterUserAgreementComponent implements OnInit {

  showAgreementLoading = false;
  withActions = false;
  userAgreement: any;
  userAgreementAppName: string;
  userAgreementAppFilePath: string;
  terms: string;
  constructor(private userService: UserService,
              private bridgeService: BridgeService) { }

  async ngOnInit() {
    this.userAgreement = await this.userService.getUserAgreement();
    _app_data = this.userAgreement.app_data;
    this.userAgreementAppName = this.userAgreement.name;
    this.userAgreementAppFilePath = this.userAgreement.url;
    this.terms = settings.configuration.env.apiUrl + 'terms/term/' + this.userAgreementAppName + '/' + this.userAgreementAppFilePath + '?rc-host-origin=' + settings.configuration.env.hostOrigin;
    //this.terms="http://localhost:8000/terms.html";

    this.bridgeService.RCHOST.handleSubscribe = this.handleSubscribe;
    this.bridgeService.RCHOST.onSystemEvents = this.onSystemEvents.bind(this);

    this.bridgeService.RCHOST.handleSubscribe(null);
  }

  handleSubscribe(callback: any) {
    var _this: any = this;
    function getApplicationsIFrame() {
        return document.getElementById('rc-app-iframe');
    }
  
    function getApplicationIFrameContent() {
        return getApplicationsIFrame() ? (<HTMLIFrameElement> getApplicationsIFrame()).contentWindow : null;
    }
  
    function pushEvents() {
      if(!callback) {
        return;
      }

      var applications_iframe = getApplicationsIFrame();
      if (applications_iframe) {
        var app = getApplicationIFrameContent()
        if(app) {
            var settings = _this.createSystemEvents();
            settings.push(_this.getSystemEvent('metadata', {'app_data': _app_data}, null));
            _this.pushSystemEventsToClient(app, settings);
            callback(app);
            return;
        }
      }
    }

    pushEvents();
  }
 
  onSystemEvents (events: any) {
    var systemEvents = JSON.parse(events);
    for (var i = 0; i < systemEvents.length; i++) {
        var systemEvent = systemEvents[i];
        var value = systemEvent['value'];
        switch (systemEvent["name"]) {
            case "contentLoaded":
                setTimeout(() => {
                    this.showAgreementLoading = false;
                }, 1000);
                break;
            default:
                // TODO - HANDLE
                break;
        }
    }
  }
  agreementConfirmed() {
  }
  decline() {
  }

}
