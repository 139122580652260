import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { AppRoutingModule } from './app-routing.module';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient
} from '@angular/common/http';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ToastrModule} from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';

import { CoreService } from './core/services/core.service';
import { ToastService } from './core/services/toast.service';
import { AuthorizedService } from './core/services/authorized.service';
import { MediadriveService } from './core/services/mediadrive.service';
import { UserService } from './core/services/user.service';
import { AircraftService } from './core/services/aircraft.service';
import { AuthService } from './core/services/auth.service';
import { AuthInterceptorService } from './core/services/auth-interceptor.service';
import { LoginComponent } from './login/login.component';
import { OperatorService } from './core/services/operator.service';
import { AssignPrivilegesService } from './core/services/assign-privileges.service';
import { FooterComponent } from './layout/footer.component';
import { FooterUserAgreementComponent } from './user-agreement/footer-user-agreement.component';
import { TrustResourcePipe } from './core/filters/trust-resource.pipe';
import { SelectItemsService } from './core/services/select-items.service';
import { FeedService } from './core/services/feed.service';
import { SelectedContentService } from './core/services/selected-content.service';
import { LoginUserAgreementComponent } from './user-agreement/login-user-agreement.component';
import { BridgeService } from './core/services/bridge.service';
import { SelectedComponent } from './selected/selected.component';
import { EpisodeDialog } from './video/episode-dialog.component';
import { MovieDialog } from './video/movie-dialog.component';
import { DashPlayer } from './video/dashplayer.component';
import { Config } from './core/config';
import { TruncatePipe } from './core/filters/truncate.pipe';
import { OrderByPipe } from './core/filters/order-by.pipe';
import { FormatDurationPipe } from './core/filters/format-duration.pipe';
import { WiseSpinnerComponent } from './core/directives/wise-spinner.component';
import { ContentSearchPipe } from './core/filters/content-search.pipe';
import { DataService } from './core/services/data.service';
import { ChannelService } from './core/services/channel.service';
import { SortingService } from './core/services/sorting.service';
import { PlaylistService } from './core/services/playlist.service';
import { MoviecontentComponent } from './video/moviecontent.component';
import { NgInitDirective } from './core/directives/ng-init.directive';
import { TextLoaderComponent } from './core/directives/text-loader.component';
import { MenuService } from './core/services/menu.service';
import { FormatMegabytesPipe } from './core/filters/format-megabytes.pipe';
import { SidebarComponent } from './stage/sidebar.component';
import { MediaHeaderComponent } from './stage/media-header.component';
import { StageComponent } from './stage/stage.component';
import { SelectAircraft } from './stage/select-aircraft.component';
import { SubscriptionService } from './core/services/subscription.service';
import { AircraftsComponent } from '../app/aircraft/aircrafts.component';
import { AircraftDialogComponent } from './aircraft/aircraft-dialog.component';
import { AircraftConfirmDialogComponent } from './aircraft/aircraft-confirm-dialog.component';
import { OperatorfilterInAircraftPipe } from './core/filters/operatorfilter-in-aircraft.pipe';
import { SyncPreferenceChangeDialogComponent } from './aircraft/sync-preference-change-dialog.component';
import { ImageFallbackDirective } from './core/directives/image-fallback.directive';
import { AircraftGeneralConfirmDialogComponent } from './aircraft/aircraft-general-confirm-dialog.component';
import { DownloadService } from './core/services/download.service';
import { SidebarDownloadComponent } from './download/sidebar-download.component';
import { SyncComponent } from './download/sync.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MusiccontentComponent } from './music/musiccontent.component';
import { AlbumDialogComponent } from './music/album-dialog.component';
import { SecdurationPipe } from './core/filters/secduration.pipe';
import { CustomToastComponent } from './custom-toast/custom-toast.component';

import { ChartsModule, ThemeService } from 'ng2-charts';
import { SeriescontentComponent } from './video/seriescontent.component';
import { TooltipDirective } from './core/directives/tooltip.directive';
import { AircraftServiceEnableDialogComponent } from './aircraft/aircraft-service-enable-dialog.component';
import { LimitedServicesDialogComponent } from './aircraft/limited-services-dialog.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SSOConfigurationService } from './core/services/ssoconfiguration.service';
import { UserComponent } from './user/user.component';
import { EditUserDialogComponent } from './user/edit-user-dialog.component';
import { UserConfirmDialog } from './user/user-confirm-dialog';
import { UnlinkUserDialogComponent } from './user/unlink-user-dialog.component';
import { OperatorDialogComponent } from './admin/operator-dialog.component';
import { AdminComponent } from './admin/admin.component';
import { OperatorConfirmDialogComponent } from './admin/operator-confirm-dialog.component';
import { AccounttypefilterInOperatorPipe } from './core/filters/accounttypefilter-in-operator.pipe';
import { RouterModule, Routes } from '@angular/router';
import { SubscriptionPlanChangeDialogComponent } from './aircraft/subscription-plan-change-dialog.component';
import { SubscriptionPlanConfirmDialogComponent } from './aircraft/subscription-plan-confirm-dialog.component';
import { ErrorToastComponent } from './error-toast/error-toast.component';

const appRoutes: Routes = [
  { path: 'aircrafts', component: AircraftsComponent },
  { path: 'users', component: UserComponent },
];


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FooterComponent,
    FooterUserAgreementComponent,
    TrustResourcePipe,
    SelectedComponent,
    LoginUserAgreementComponent,
    EpisodeDialog,
    TruncatePipe,
    OrderByPipe,
    MovieDialog,
    FormatDurationPipe,
    DashPlayer,
    WiseSpinnerComponent,
    ContentSearchPipe,
    //MovieComponent,
    MoviecontentComponent,
    NgInitDirective,
    TextLoaderComponent,
    FormatMegabytesPipe,
    SidebarComponent,
    StageComponent,
    MediaHeaderComponent,
    SelectAircraft,
    AircraftDialogComponent,
    AircraftConfirmDialogComponent,
    AircraftGeneralConfirmDialogComponent,
    OperatorfilterInAircraftPipe,
    AircraftsComponent,
    SyncPreferenceChangeDialogComponent,
    ImageFallbackDirective,
    SidebarDownloadComponent,
    SyncComponent,
    MusiccontentComponent,
    AlbumDialogComponent,
    SecdurationPipe,
    CustomToastComponent,
    SeriescontentComponent,
    TooltipDirective,
    AircraftServiceEnableDialogComponent,
    LimitedServicesDialogComponent,
    UserComponent,
    EditUserDialogComponent,
    UserConfirmDialog,
    UnlinkUserDialogComponent,
    AdminComponent,
    OperatorDialogComponent,
    OperatorConfirmDialogComponent,
    AccounttypefilterInOperatorPipe,
    SubscriptionPlanChangeDialogComponent,
    SubscriptionPlanConfirmDialogComponent,
    ErrorToastComponent
  ],
  imports: [
    BrowserModule,
    DragDropModule,
    UpgradeModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    ChartsModule,
    MatSelectModule,
    MatFormFieldModule,
    RouterModule.forRoot(
      appRoutes
    ),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    })
  ],
  providers: [
    CoreService,
    ToastService,
    AuthorizedService,
    MediadriveService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    UserService,
    AircraftService,
    OperatorService,
    AuthService,
    SelectItemsService,
    FeedService,
    SelectedContentService,
    BridgeService,
    Config,
    AssignPrivilegesService,
    DataService,
    ChannelService,
    SortingService,
    PlaylistService,
    MenuService,
    SubscriptionService,
    DownloadService,
    TextLoaderComponent,
    MoviecontentComponent,
    MusiccontentComponent,
    SeriescontentComponent,
    SSOConfigurationService,
    UserComponent,
    AdminComponent,
    OperatorDialogComponent,
    OperatorConfirmDialogComponent,
    SubscriptionPlanChangeDialogComponent,
    SubscriptionPlanConfirmDialogComponent,
    ThemeService
  ],
  entryComponents: [
    LoginComponent,
    FooterComponent,
    FooterUserAgreementComponent,
    LoginUserAgreementComponent,
    SelectedComponent,
    EpisodeDialog,
    MovieDialog,
    DashPlayer,
    MoviecontentComponent,
    WiseSpinnerComponent,
    SidebarComponent,
    SelectAircraft,
    AircraftsComponent,
    AircraftDialogComponent,
    AircraftConfirmDialogComponent,
    AircraftGeneralConfirmDialogComponent,
    SyncPreferenceChangeDialogComponent,
    SidebarDownloadComponent,
    SyncComponent,
    MusiccontentComponent,
    AlbumDialogComponent,
    CustomToastComponent,
    SeriescontentComponent,
    AircraftServiceEnableDialogComponent,
    LimitedServicesDialogComponent,
    UserComponent,
    UserConfirmDialog,
    EditUserDialogComponent,
    UnlinkUserDialogComponent,
    AdminComponent,
    OperatorDialogComponent,
    OperatorConfirmDialogComponent,
    SubscriptionPlanChangeDialogComponent,
    SubscriptionPlanConfirmDialogComponent,
    ErrorToastComponent
  ]
  //bootstrap: [AppComponent]
})

export class AppModule {

  constructor (
    private upgrade: UpgradeModule,
    private translate: TranslateService
  ) { }

  ngDoBootstrap() {
    this.upgrade.bootstrap(document.body, ['app'], {strictDi: true} );
    this.setTranslateLang();
  }

  setTranslateLang() {
    this.translate.setDefaultLang('en');
  }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/locale-', '.json');
}
