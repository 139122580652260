import { Injectable } from '@angular/core';
declare var angular: any;
import { downgradeInjectable } from '@angular/upgrade/static';

import {HttpClient} from '@angular/common/http';
import settings from '../../../../config.json';
import { CoreService } from './core.service';
import { AuthorizedService } from './authorized.service';
import { AircraftService } from './aircraft.service';

@Injectable({
  providedIn: 'root'
})

export class OperatorService {

  constructor(
    private http: HttpClient,
    private coreservice: CoreService,
    private authorizedService: AuthorizedService,
    private aircraftService: AircraftService
  ) { }

  getOperator() {
    var url: string = settings.configuration.env.apiUrl + 'operators/selected/operator';
    return this.http
      .get(url)
      .toPromise().then(
        (res: any) => {
          this.coreservice.operator = res;
          return res;
        },
        (err: any) => { }
      )
  }

  getOperatorDetails(operatorName: string) {
    var url: string = settings.configuration.env.apiUrl + 'operators/operator/' + operatorName;
    return this.http
        .get(url)
        .toPromise().then(
          (res: string) => { return res },
          null
        );
  }

  setOperator(operator: string) {
    var url: string = settings.configuration.env.apiUrl + 'operators/select/operator/' + operator;
    return this.http
      .post(url, null)
      .subscribe(
        (res: any) => {
          var is_selected: boolean;
          is_selected = res.is_selected;
          if(is_selected) {
            this.getOperator();
            this.aircraftService.getAircraft();
            this.authorizedService.getUserPrivileges();
          } else if (!is_selected) {
            console.log("Couldn't select operator");
          } else {
            console.log("Couldn't select operator");
          }
        }
      )
  }

  getOperators() {
    var url: string = settings.configuration.env.apiUrl + 'operators/assigned';
    return this.http
        .get(url)
        .toPromise().then(
          (res: string) => { return res },
          (err: any) => { console.log(err) }
        );
  }

  getAllAccountTypes() {
    var url: string = settings.configuration.env.apiUrl + 'operators/accounttype';
    return this.http
        .get(url)
      .toPromise().then(
        (res: string) => { return res; },
          (err: any) => { console.log(err) }
        );
  }

  getAllOperators() {
    var url: string = settings.configuration.env.apiUrl + 'operators';
    return this.http
        .get(url)
        .toPromise().then(
          (res: string) => { return res },
          (err: any) => { console.log(err) }
        );
  }

  createOperator(name: string, accountType: boolean) {
    var url: string = settings.configuration.env.apiUrl + 'operators/create/operator';
    return this.http
      .post(url,
        {
          name: name,
          isTest: accountType
        }, {
          observe: "response"
       })
      .toPromise().then(
        (res: any) => { return res },
        (err: any) => { console.log(err) }
      )
  }

  editOperatorName(operatorId: string, newName: string, isTest: boolean) {
    var url: string = settings.configuration.env.apiUrl + 'operators/edit/operator/' + operatorId + '/name'
    return this.http
      .post(url,
        {
          name: newName,
          isTest: isTest
        }, {
          observe: "response"
       })
      .toPromise().then(
        (res: any) => { return res },
        (err: any) => { console.log(err) }
      )
  }

  deleteOperator(operator: string) {
    var url: string = settings.configuration.env.apiUrl + 'operators/delete/operator/' + operator;
    return this.http
      .post(url, null, {
        observe: "response"
     })
      .toPromise().then(
        (res: any) => { return res },
        (err: any) => { return err }
      )
  }
}

angular
  .module('app.core')
  .factory('operatorService', downgradeInjectable(OperatorService));
