import { Component, OnInit, Inject } from '@angular/core';
import settings from '../../../config.json';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastService } from '../core/services/toast.service';
import { AuthorizedService } from '../core/services/authorized.service';
import { AuthService } from '../core/services/auth.service';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserService } from '../core/services/user.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AssignPrivilegesService } from '../core/services/assign-privileges.service';
import * as _ from "lodash";
import { CoreService } from '../core/services/core.service';
import { UserConfirmDialog } from './user-confirm-dialog';
import { UnlinkUserDialogComponent } from './unlink-user-dialog.component';
declare var angular: angular.IAngularStatic;


@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: './edit-user-dialog.component.html',
  styles: [':host { width: -webkit-fill-available; }']
})
export class EditUserDialogComponent implements OnInit {

  //Variable Names:
  public currentTab: string = '';
  public timeStamp: string = '?=' + new Date().getTime();
  public headerTimeStamp: string = '?=' + new Date().getTime();
  public userName: string = '';
  public showImageOptions: boolean = false;

  public oldPassword: string = '';
  public newPassword: string = '';
  public confirmPassword: string = '';
  public aircraftPassword: FormGroup;
  public activeAircraftId: string = '';
  public editableAircraft: string = '';
  public aircraftPrivileges: any = [];
  public emailExists: boolean = false;
  public usersShortDetails: any = [];
  public activeOperatorId: any;
  public editableOperator: any;
  public operatorPrivileges: any = [];
  public opDetails_active: boolean;
  public newOperatorPrivilegesList: any = [];
  public newGlobalPrivilegesList: any = [];
  public globalPrivileges: any = [];
  public originalGlobalPrivileges: any;
  public assignedOperators: any = [];
  public aircraftDetails_active: boolean = false;
  public newAircraftPrivilegesList: any = [];
  public assignedAircrafts: any = [];
  public validationMaxLength : any;
  public validationMinLength : any;
  public validationUsernameRegistration : any;
  public lenthBetweenXY: any;
  public lenthBetween: any;

  public hasNewImage : boolean = false;
  public personalFile: string = '';
  public image: string = settings.configuration.env.apiUrl + 'images/users';
  public placeholder: string = 'assets/icons/default_user.png';
  public validation: any;
  public dateTime: any;
  public hasUser: boolean = false;
  public isCurrentUser: boolean = false;
  public errors: any;
  public flipOn: boolean;
  public flipStart: boolean;
  public changePasswordForm: any;

  public userExsists : boolean = false;
  public blockUI: boolean = false;
  public userInfo: any = [];
  public originalUserInfo : any;
  public operators: any = [];
  public isPrivileges: boolean = false;
  public hideUnlinkUser: boolean = false;
  public filterOperator;
  public registerForm: FormGroup;
  public newOperator: any;
  public getGlobalPrivilege: boolean = false;
  public isCurrentUserNotGlobal: boolean = false;

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    public authorizedService: AuthorizedService,
    private authService: AuthService,
    private userService: UserService,
    public coreservice: CoreService,
    private privilegesService: AssignPrivilegesService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private EditUserDialog: MatDialogRef<EditUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: any
    ) {
      this.errors = '';
      this.validation = settings.configuration.validation.rules;
      this.dateTime = settings.configuration.datetime_formats;

      this.aircraftPassword = new FormGroup({
        oldPassword: new FormControl(''),
        newPassword: new FormControl(''),
        confirmPassword: new FormControl('')
      });
      console.log("edit-user constructor called...");
      //this.getUserInfo();
      console.log("username = ", this.coreservice.username);
      console.log("dialogData =",this.dialogData);

      this.lenthBetween = "LENGTH_BETWEEN_";
      this.userInfo = this.dialogData.userInfo;

      console.log("user name in constructor", this.userInfo.user_name);


      this.originalUserInfo = angular.extend({}, this.userInfo);
      console.log("this.originalUserInfo", this.originalUserInfo);
      this.validationMaxLength = settings.configuration.validation.rules.username_maxlength;
      this.validationMinLength = settings.configuration.validation.rules.username_maxlength;
      this.validationUsernameRegistration = settings.configuration.validation.rules.username_registration
      this.operators = this.dialogData.operators;
      this.assignedOperators = this.dialogData.assignedOperators;
      this.assignedAircrafts = this.dialogData.assignedAircrafts;
      this.aircraftPrivileges = this.dialogData.aircraftPrivileges;
      this.globalPrivileges = this.dialogData.globalPrivileges;
      this.getGlobalPrivilege = this.dialogData.getGlobalPrivilege;

      this.parseNewGlobalPrivileges(this.globalPrivileges);

      // if(this.assignedAircrafts.privileges) {
      //   console.log("inside if this.assignedAircrafts.privileges ",this.assignedAircrafts.privileges);
      //   this.isPrivileges = true;
      // }
      // if (this.userInfo.is_sso_user)
      // {
      //   this.hideUnlinkUser = true;
      // }
      console.log("userInfo =",this.userInfo);
      if (this.userInfo.user_name) {
        // loggedInuserName = this.coreservice.user.user_name
        // selected username = this.userName
        // isCurrentUser isTrue when LoggedInuser same as selecteduser
        this.userName = this.userInfo.user_name;
        this.hasUser = true;
        console.log("editUser constructor hasUser",this.hasUser);

        if (this.userName == this.coreservice.user.user_name) {
          this.isCurrentUser = true;
        }
      }
      this.currentTab = this.dialogData.tabName ? this.dialogData.tabName : 'user';
      //this.emailExists = false;

      if (this.isCurrentUser && this.getGlobalPrivilege) {
        this.isCurrentUserNotGlobal = true;
      }
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      user_name: ['', Validators.required, Validators.minLength(3), Validators.maxLength(50)],
      fName: ['', Validators.required],
      lName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      company: ['', [Validators.required]],
      newsletter: ['', Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get formData() {
    return this.registerForm.controls;
  }

  getUserInfo() {
    console.log("getUserInfo called........");
    console.log("username = ", this.coreservice.username);
    console.log("dialogConfig =",this.dialogData);
    this.userService.getUserDetails(this.dialogData.userId).then(
      (res: any) => {
        this.userInfo = res;
        this.originalUserInfo = this.userInfo;
        console.log("getUserInfo Response...",this.userInfo);
        console.log("coreservice.user.user_name ",this.coreservice.user.user_name);
        console.log("hasUser ",this.hasUser);
        console.log("originalUserInfo.user_name ",this.originalUserInfo.user_name);
        console.log("Operator Privileges =",this.authorizedService.checkIfAuthorized(['Operator Privileges.Read'], 'onlyOne'));
        console.log("Aircraft Privileges =",this.authorizedService.checkIfAuthorized(['Aircraft Privileges.Read'], 'onlyOne'));
        console.log("Global Privileges =",this.authorizedService.checkIfAuthorized(['Global Privileges.Read'], 'onlyOne'));

      }
    );
  }

  openTab(tabName : string) {
    this.currentTab = tabName;
    console.log("openTab dialogData ",this.dialogData);
    console.log("assignedAircrafts ",this.assignedAircrafts);
  }

  saveChanges(formData : any){
    this.errors = '';
    var url: string = settings.configuration.env.apiUrl + 'account/users/edit/user/' + this.originalUserInfo.user_name;

    var returnValue = this.http
    .post(url, formData, {observe: "response"})
    .subscribe(
      (res: any) => {
        if (res.status == 200) {
          if (this.hasNewImage) {
              this.postImage(formData.user_name);
          }
          this.toastService.toastSuccess("success_user_info_updated", "usermanagement_info");
          this.errors = '';
          this.userService.getCurrentUser();
        }
      },
      (err: any) => {
        if (err.status == 409) {
          if (err.error == "USERNAME_EXISTS") {
              this.userExsists = true;
              return this.errors = {
                  "portalUserDto.user_name": ['USERNAME_EXISTS']
              };
          } else if (err.error == "EMAIL_EXISTS") {
              return this.errors = {
                  "portalUserDto.email": ['EMAIL_EXISTS']
              };
            }
          } else if (err.status == 400) {
              this.errors = err.error.modelState;
            }
        }
      );
      return returnValue;
  }

  unlinkUser(userId) {
    if (!this.blockUI) {
        this.blockUI = true;
        var dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
          userId: userId,
          hideUnlinkUser: this.hideUnlinkUser,
        }
        dialogConfig.panelClass = ['matdialog-custom-dialog', 'confirm-dialog', 'dialog'];
        let unlinkUserDialog = this.dialog.open(UnlinkUserDialogComponent, dialogConfig);
        unlinkUserDialog.beforeClosed().subscribe(res => {
          //console.log("unlinkUserDialog Response", res);
          //console.log("hide unlink flag ",this.hideUnlinkUser);
          console.log("Dialog Data hide unlink flag ",this.dialogData.hideUnlinkUser);
          console.log("Dialog Config hide unlink flag ",dialogConfig.data);
          this.hideUnlinkUser = dialogConfig.data.hideUnlinkUser;
            this.blockUI = false;

        });
    }
  }

  getErrorValue(value: any) {
    var values = '';
    if (value.startsWith("LENGTH_BETWEEN_")) {
        var input = value.split("_");
        var minValue = input[2];
        var maxValue = input[4];

        return JSON.stringify({
            minLen: minValue,
            maxLen: maxValue
        });
    }
    return values;
  }

  createUser(formData: any) {
    this.errors = '';
    var url: string = settings.configuration.env.apiUrl + 'account/users/create/user';

    console.log("formdtaa ....", formData);

    return this.http
    .post(url,formData, {observe: 'response'})
    .subscribe(
      (res: any) => {
        console.log("create user response..", res);
        if (res.status == 200) {
          if (this.hasNewImage) {
              this.postImage(formData.user_name);
          }
          this.errors = '';
          this.toastService.toastSuccess("success_user_created", "usermanagement_info");
          this.userName = this.userInfo.user_name;
          this.originalUserInfo = angular.extend({}, this.userInfo);
          return this.assignedOperatorsCleanCall().then((res) => {
              return this.assignedAircraftsCleanCall();
          }).then(() => {
              if(this.authorizedService.checkIfAuthorized(['Global Privileges.Read'], 'onlyOne')){
                return this.assignedGlobalCleanCall();
              }
          }).then(() => {
              this.hasUser = true;
              this.currentTab = 'operator_roles';
          });
      }
    },
    (err: any) => {​​​​​​​​
      console.log("in createuser errpr..", err);
      if (err.status == 409) {​​​​​​​​
        if (err.error == "USERNAME_EXISTS") {​​​​​​​​
          this.userExsists = true;
          return this.errors = {​​​​​​​​
            "portalUserDto.user_name": ['USERNAME_EXISTS']
          }​​​​​​​​;
        }​​​​​​​​ else if (err.error.startsWith("EMAIL_EXISTS")) {​​​​​​​​
          var userName = err.error.split(' ')[1];
          if (!!userName) {​​​​​​​​
          this.showExistingUser(userName);
          }​​​​​​​​ else {​​​​​​​​
            this.toastService.toastWarning("warning_user_exists_in_operator", "usermanagement_operatorroles");
            this.EditUserDialog.close();
          }​​​​​​​​
        }​​​​​​​​ else if (err.error.startsWith("USERNAME_EXISTS")) {​​​​​​​​
            this.toastService.toastError("USERNAME_EXISTS", "usermanagement_reset_password");
          }​​​​​​​​
        }​​​​​​​​ else if (err.status == 400) {​​​​​​​​
            this.errors = err.error.modelState;
        }​​​​​​​​ else if (err.status == 500) {​​​​​​​​
            if (err.error == "EMAIL_NOT_SENT") {​​​​​​​​
              this.toastService.toastError("EMAIL_NOT_SENT","usermanagement_add");
            }​​​​​​​​
        }​​​​​​​​
      }​​​​​​​​
    );
  }

  showExistingUser(username: string) {
    this.userService.getShortUserDetails(username).then((res) => {
        this.emailExists = true;
        this.usersShortDetails = res;
        this.userName = this.usersShortDetails.user_name;
    });
  }

  goBack() {
    this.emailExists = false;
    this.usersShortDetails = null;
    this.userName = null;
  }

  useExsistingAccount() {
    return this.privilegesService.assignOperator(this.userName, this.userInfo.operator_id).then((res) => {
        if (res.status == 200) {
            this.loadAllUserData();
            this.toastService.toastSuccess("success_assigned_operator", "usermanagement_operatorroles");
        }
    }, (err) => {
        this.toastService.toastError("error_assign_operator", "usermanagement_operatorroles");
    });
  }

  loadAllUserData() {
    return this.userService.getUserDetails(this.userName).then((res) => {
        this.userInfo = res;
        this.originalUserInfo = angular.extend({}, this.userInfo);
        this.emailExists = false;
        this.usersShortDetails = null;
        return this.assignedOperatorsCleanCall().then(() => {
            return this.assignedAircraftsCleanCall();
        }).then(() => {
            return this.assignedGlobalCleanCall();
        }).then(() => {
            this.hasUser = true;
        });
    });
  }

  getUserDetails() {
    console.log("getUserDetails called...");
    console.log("username =",this.userName);
    //console.log("userId = ", this.userId);
    return this.userService.getUserDetails(this.userName);
  }

  loadImage(event) {
    this.showImageOptions = false;
    var newImage = new Image();
    var reader = new FileReader();

    reader.onload = () => {
        let output: HTMLElement = document.getElementById('output');
        newImage.setAttribute('src', reader.result.toString());

        setTimeout(() => {
            if (newImage.naturalHeight < 200 && newImage.naturalWidth < 200 ||
                newImage.naturalHeight > 200 && newImage.naturalWidth < 200 ||
                newImage.naturalHeight < 200 && newImage.naturalWidth > 200
            ) {
                event.target.files[0] = null;
                this.hasNewImage = false;
                output.setAttribute('src', this.placeholder);
                this.toastService.toastError("error_min_img_size", "usermanagement_info");
            } else {
                output.setAttribute('src',reader.result.toString());
                this.personalFile = event.target.files[0];
            }
        }, 300);
    };

    if (event.target.files[0].type == 'image/png' ||
        event.target.files[0].type == 'image/jpg' ||
        event.target.files[0].type == 'image/jpeg') {
        this.hasNewImage = true;
        reader.readAsDataURL(event.target.files[0]);
    } else {
        event.target.files[0] = null;
        this.hasNewImage = false;
        this.toastService.toastError("error_only_images_allowed", "usermanagement_info");
        return;
    }
};

postImage(user_id: string) {
  var file: any = this.personalFile;
  var fileName: string = file.name;
  var fd = new FormData();
  fd.append('file', file);
  var url: string = settings.configuration.env.apiUrl + 'account/users/postimage/user/' + user_id;

  var httpHeaders = new HttpHeaders({
      //'Content-Type': undefined,
      'filename': fileName
  });

  var options = {
      //transformRequest: angular.identity,
      headers: httpHeaders,
      observe: 'response' as 'body'
  };

  return this.http
      .post(url, fd, options)
      .subscribe(
          (res: any) => {
              if(res.status==200) {
                  this.headerTimeStamp = '?=' + new Date().getTime();
                  this.timeStamp = '?=' + new Date().getTime();
                  this.toastService.toastSuccess("success_user_image_uploaded", "usermanagement_info");
              } else {
                  this.headerTimeStamp = '?=' + new Date().getTime();
                  this.timeStamp = '?=' + new Date().getTime();
                  this.toastService.toastError("error_upload_image", "usermanagement_info");
              }
          },
          (err: any) => {
              this.toastService.toastError("error_upload_image", "usermanagement_info");
          }
      );
}

  deleteUserImage() {
    this.showImageOptions = false;
    var url: string = settings.configuration.env.apiUrl + 'account/users/deleteimage/user/' + this.userName;
    return this.http
    .post(url, null, {observe: 'response'})
    .subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.showImageOptions = false;
          this.toastService.toastSuccess("success_user_image_deleted", "usermanagement_info");
          this.timeStamp = '?=' + new Date().getTime();
          this.headerTimeStamp = '?=' + new Date().getTime();
        }
      },
      (err: any) => {
        this.toastService.toastError("error_delete_image", "usermanagement_info");
      }
    );
  }

  sendPasswordReset(email) {
    if (!this.blockUI) {
        this.blockUI = true;
        this.authService.resetPassword(email).then((res) => {
            if (res.status == 200) {
                this.toastService.toastSuccess("success_email_instructions_sent", "usermanagement_reset_password");
                this.flipOn = false;
                this.flipStart = false;
            } else {
                for (var key in res.data.modelState) {
                    this.toastService.toastError(res.data.modelState[key][0], "usermanagement_reset_password");
                }
            }
            this.blockUI = false;
        });
    }
  }

  changePassword(oldPassword, newPassword, confirmPassword) {
    this.errors = '';
    if (!this.blockUI) {
        this.blockUI = true;
        return this.authService.changePassword(oldPassword, newPassword, confirmPassword).then((res) => {
            if (res.status == 200) {
                this.oldPassword = '';
                this.newPassword = '';
                this.confirmPassword = '';
                this.changePasswordForm.markAsPristine();
                this.toastService.toastSuccess("success_password_changed", "usermanagement_change_password");
            }
            this.blockUI = false;
        }, (err) => {
            this.blockUI = false;
            if(err.status === 400) {
                if(!!err.error.modelState){
                  for (var key in err.error.modelState) {
                      this.toastService.toastError(err.error.modelState[key][0], "usermanagement_reset_password");
                  }
                } else {
                    return this.errors = {
                        "changePasswordDto.old_password": err.error
                    };
                }
            }
        });
      }
    }

    //Operator & Global PRIVILEGES
    assignOperator(operator) {
      if (!!operator) {
          return this.privilegesService.assignOperator(this.userName, operator).then((res) => {
              if (res.status == 200) {
                  this.toastService.toastSuccess("success_assigned_operator", "usermanagement_operatorroles");
                  this.assignedOperatorsCleanCall();
                  this.assignedAircraftsCleanCall();
              }
          }, (err) => {
              this.toastService.toastError("error_assign_operator", "usermanagement_operatorroles");
          });
      }
  }

  unassignOperator(operator) {
      var unassignOperatorId = operator.name;
      return this.privilegesService.unassignOperator(this.userName, unassignOperatorId).then( (res) => {
          if (res.status == 200) {
              this.toastService.toastSuccess("success_unassigned_operator", "usermanagement_operatorroles");
              this.assignedOperatorsCleanCall();
              this.assignedAircraftsCleanCall();
          }
      }, (err) => {
          this.toastService.toastError("error_unassign_operator", "usermanagement_operatorroles");
      });
  }

  editOperatorPrivileges(operator) {
      this.activeOperatorId = operator.name;
      this.editableOperator = operator.name;
      this.privilegesService.getOperatorPrivileges(this.userName, this.activeOperatorId).then((res) =>{
          this.operatorPrivileges = res;
          this.opDetails_active = true;

          var newOperatorPrivilegesListJSON = _.each(this.operatorPrivileges, (privilege) => {
              if (privilege.is_selected == 4) {
                  this.newOperatorPrivilegesList.push(privilege.id);
              }
          });
      }, (err) => {
          this.toastService.toastError("error_get_operator_privileges", "usermanagement_operatorroles");
      });
  }

  cancelEditOpeatorPrivileges() {
      this.opDetails_active = false;
      this.operatorPrivileges = [];
      this.editableOperator = '';
  }

  parseNewGlobalPrivileges(globalPrivileges) {
      _.each(globalPrivileges, (privilege) => {
          if (privilege.is_selected == 4) {
              this.newGlobalPrivilegesList.push(privilege.id);
          }
      });
  }

  assignGlobalPrivilege(privilege, index) {
      var privilegeStatus = privilege.is_selected;

      console.log("newGlobalPrivilegesList", this.newGlobalPrivilegesList);

      if (privilegeStatus === 0) {
        console.log("privilege.id", privilege.id);
          this.newGlobalPrivilegesList.push(privilege.id);
          this.globalPrivileges[index].is_selected = 4;

      } else if (privilegeStatus === 4) {
          this.newGlobalPrivilegesList = _.without(this.newGlobalPrivilegesList, privilege.id);
          this.globalPrivileges[index].is_selected = 0;
      }
  }

  assignGlobalPrivileges() {
    console.log("newGlobalPrivilegesList...", this.newGlobalPrivilegesList);
    console.log("userName...", this.userName);
      this.privilegesService.setGlobalPrivileges(this.newGlobalPrivilegesList, this.userName).then((res) => {
          if (res.status == 200) {
            console.log("newGlobalPrivilegesList...", this.newGlobalPrivilegesList);
            console.log("userName...", this.userName);
              this.toastService.toastSuccess("success_assigned_global_privileges", "usermanagement_globalroles");
              this.newGlobalPrivilegesList = [];
              this.assignedGlobalCleanCall();
          } else {
              this.globalPrivileges = this.originalGlobalPrivileges;
              this.toastService.toastError("error_assign_global_privileges", "usermanagement_globalroles");
          }
      });
  }

  assignedGlobalCleanCall() {
      return this.privilegesService.getGlobalPrivileges(this.userName).then((res) => {
          this.globalPrivileges = res;
          this.parseNewGlobalPrivileges(this.globalPrivileges);
          this.originalGlobalPrivileges = angular.extend({}, this.globalPrivileges);
      }, (err) => {
          this.toastService.toastError("error_get_global_privileges", "usermanagement_globalroles");
      });
  }

  assignOperatorPrivilege(privilege, index) {
      var privilegeStatus = privilege.is_selected;

      if (privilegeStatus === 0) {
        this.newOperatorPrivilegesList.push(privilege.id);
        this.operatorPrivileges[index].is_selected = 4;

      } else if (privilegeStatus === 4) {
        this.newOperatorPrivilegesList = _.without(this.newOperatorPrivilegesList, privilege.id);
        this.operatorPrivileges[index].is_selected = 0;
      }
  }

  assignOperatorPrivileges() {
    this.privilegesService.setOperatorPrivileges(this.newOperatorPrivilegesList, this.userName, this.activeOperatorId).then((res) => {
          if (res.status == 200) {
            this.opDetails_active = false;
              this.toastService.toastSuccess("success_assigned_operator_privileges", "usermanagement_operatorroles");
              this.newOperatorPrivilegesList = [];
              this.assignedOperatorsCleanCall();
          } else {
              this.toastService.toastError("error_assign_operator_privileges", "usermanagement_operatorroles");
          }
      });
  }

  assignedOperatorsCleanCall() {
      return this.privilegesService.getAssignedOperators(this.userName).then((res) =>{
        this.assignedOperators = res;

        this.opDetails_active = false;
        this.operatorPrivileges = [];
        this.editableOperator = '';
      }, (err) => {
          this.toastService.toastError("error_get_assigned_operators", "usermanagement_operatorroles");
      });
  }

  //Aircraft PRIVILEGES
  assignAircraft(aircraft, index) {
    var aircraftId = aircraft.aircraft_id;

    return this.privilegesService.assignAircraft(this.userName, aircraftId).then((res) => {
        if (res.status == 200) {
            this.toastService.toastSuccess("success_assigned_aircraft", "usermanagement_aircraftroles");
            this.assignedAircraftsCleanCall();
        } else {
            this.toastService.toastError("error_assign_aircraft", "usermanagement_aircraftroles");
        }
    });
  }

  unassignAircraft(aircraft) {
    var unassignAircraftId = aircraft.aircraft_id;
    return this.privilegesService.unassignAircraft(this.userName, unassignAircraftId).then((res) =>{
        if (res.status == 200) {
            this.toastService.toastSuccess("success_assigned_aircraft", "usermanagement_aircraftroles");
            this.assignedAircraftsCleanCall();
        } else {
            this.toastService.toastError("error_unassign_aircraft", "usermanagement_aircraftroles");
        }
    });
  }

  editAircraftPrivileges(aircraft) {
    this.activeAircraftId = aircraft.aircraft_id;
    this.editableAircraft = aircraft.manufacturer + aircraft.model + aircraft.aircraft_id;

    this.privilegesService.getAircraftPrivileges(this.userName, this.activeAircraftId).then((res) =>{
      this.aircraftPrivileges = res;
      this.aircraftDetails_active = true;

        var newAircraftPrivilegesListJSON = _.each(this.aircraftPrivileges, (privilege) => {
            if (privilege.is_selected == 4) {
              this.newAircraftPrivilegesList.push(privilege.id);
            }
        });
        console.log("json ....",newAircraftPrivilegesListJSON);

    }, (err) => {
        this.toastService.toastError("error_get_aircraft_privileges", "usermanagement_aircraftroles");
    });
  }

  assignAircraftPrivilege(privilege, index) {
    var privilegeStatus = privilege.is_selected;

    if (privilegeStatus === 0) {
        this.newAircraftPrivilegesList.push(privilege.id);
        this.aircraftPrivileges[index].is_selected = 4;
    } else if (privilegeStatus === 4) {
      this.newAircraftPrivilegesList = _.without(this.newAircraftPrivilegesList, privilege.id);
      this.aircraftPrivileges[index].is_selected = 0;
    }
  }

  assignAircraftPrivileges() {
    this.privilegesService.setAircraftPrivileges(this.newAircraftPrivilegesList, this.userName, this.activeAircraftId).then((res) => {
        if (res.status == 200) {
          this.aircraftDetails_active = false;
            this.toastService.toastSuccess("success_assigned_aircraft_privileges", "usermanagement_aircraftroles");
            this.newAircraftPrivilegesList = [];
            this.assignedAircraftsCleanCall();
        } else {
            this.toastService.toastError("error_assign_aircraft_privileges", "usermanagement_aircraftroles");
        }
    });
  }

  cancelEditAircraftPrivileges() {
    this.assignedAircraftsCleanCall();
  }

  assignedAircraftsCleanCall() {
    return this.privilegesService.getAssignedAircrafts(this.userName).then((res)=>  {
      this.assignedAircrafts = res;
      this.aircraftDetails_active = false;
      this.aircraftPrivileges = [];
      this.editableAircraft = '';

    }, (err) => {
        this.toastService.toastError("error_get_assigned_aircrafts", "usermanagement_aircraftroles");
    });
  }
}
