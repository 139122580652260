import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-aircraft-service-enable-dialog',
  templateUrl: './aircraft-service-enable-dialog.component.html',
  styleUrls: ['./aircraft.less', './../layout/dialog-main.less']
})
export class AircraftServiceEnableDialogComponent implements OnInit {

  public heading: string = '';
  public message: string = '';
  public action: string = '';
  public cancel: string = '';
  
  constructor(
      private dialogRef: MatDialogRef<AircraftServiceEnableDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any) {
          this.heading = this.data.heading
          this.message = this.data.message
          this.action = this.data.action
          this.cancel = this.data.cancel
      }

  ngOnInit() { }

  actionClicked() { this.dialogRef.close({event: 'action'}); }

  cancelClicked() { this.dialogRef.close({event: 'cancel'}); }

}
