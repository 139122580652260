import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatMegabytes'
})
export class FormatMegabytesPipe implements PipeTransform {

  transform(bytes: any, precision: any): any {
    if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
    if (typeof precision === 'undefined') precision = 1;
    var units = ['MB', 'GB', 'TB', 'PB'],
        number = Math.floor(Math.log(bytes) / Math.log(1024));
    return (Math.floor((bytes / Math.pow(1024, Math.floor(number)))*10)/10) + ' ' + units[number];
  }

}
