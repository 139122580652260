import { Injectable } from '@angular/core';
declare var angular: any;
import { downgradeInjectable } from '@angular/upgrade/static';

import { HttpClient } from '@angular/common/http';
import settings from '../../../../config.json';
import { AuthorizedService } from './authorized.service';
import { CoreService } from './core.service';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  // userService.$inject = ['$http', 'settings', 'authorizedService', 'coreservice'];
  constructor(
    private http: HttpClient,
    private authorizedService: AuthorizedService,
    private coreservice: CoreService
    ) {  }

  getUsers(selectedOperator: any) {
    var url: string = !!selectedOperator ? settings.configuration.env.apiUrl + 'account/users?filter=' + selectedOperator : settings.configuration.env.apiUrl + 'account/users';
    return this.http
      .get(url)
      .toPromise().then(
        (res: string) => { return res },
        null
      );
  }

  getUser(username: string) {
    var url: string = settings.configuration.env.apiUrl + 'account/users/user/' + username;
    return this.http
      .get(url)
      .toPromise().then(
        (res: any) => {
          this.coreservice.user = res;
          console.log("getUser Called... ",this.coreservice.user);
          if (res.is_sso_user) {
            this.coreservice.showingFalconUser = true;
          } else {
            this.coreservice.showingFalconUser = false;
          }
          this.authorizedService.getUserPrivileges();
        },
        (err: any) => { }
      )
  }

  unlinkUser(userId: string){
    var url: string = settings.configuration.env.apiUrl + 'account/users/unlink/user/' + userId;
    return this.http
      .post(url, null, {
        observe: "response"
     })
      .toPromise().then(
        (res: any) => { return res },
        null
      )
  }

  getUserDetails(username: string) {
    var url: string = settings.configuration.env.apiUrl + 'account/users/user/' + username;
    return this.http
        .get(url)
        .toPromise().then(
          (res: any) => { return res },
          null
        );
  }

  getShortUserDetails(username: string) {
    var url: string = settings.configuration.env.apiUrl + 'account/users/user/' + username + '/shortdetails';
    return this.http
        .get(url)
        .toPromise().then(
          (res: any) => { return res },
          null
        );
  }

  getCurrentUser() {
    var username: string = this.coreservice.user.user_name;
    var url: string = settings.configuration.env.apiUrl + 'account/users/user/' + username;

    return this.http
      .get(url)
      .toPromise().then(
        (res: any) => { this.coreservice.user = res }
      )
  }

  deleteUser(userId: string){
    var url: string = settings.configuration.env.apiUrl + 'account/users/delete/user/' + userId;
    return this.http
      .post(url, null, {
        observe: "response"
     })
      .toPromise().then(
        (res: any) => { return res },
        null
      )
  }

  async getUserAgreement() {
    var url: string = settings.configuration.env.apiUrl + 'terms/term';
    var res = await this.http
        .get(url)
        .toPromise();

    return res;
  }

  acceptUserAgreement(termsId: string) {
    var url: string = settings.configuration.env.apiUrl + 'terms/term/' + termsId + '/accept';
    return this.http
      .post(url, null,{
        observe: "response"
      })
      .toPromise().then(
        (res) => { return res },
        (err) => { return err }
      );
  }
}

angular
  .module('app.core')
  .factory('userService', downgradeInjectable(UserService));
