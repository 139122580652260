import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthorizedService } from '../core/services/authorized.service';
import { CoreService } from '../core/services/core.service';
import { SubscriptionService } from '../core/services/subscription.service';
import strings from '../../../assets/locale-en.json';
import { LimitedServicesDialogComponent } from './limited-services-dialog.component';
import { ToastService } from '../core/services/toast.service';
import { SubscriptionPlanConfirmDialogComponent } from './subscription-plan-confirm-dialog.component';

@Component({
  selector: 'app-subscription-plan-change-dialog',
  templateUrl: './subscription-plan-change-dialog.component.html',
  styleUrls: ['./aircraft.less']
})

export class SubscriptionPlanChangeDialogComponent implements OnInit {

  public heading: string = '';
  public message: string = '';
  public action: string = '';
  public cancel: string = '';
  public subscriptionPlanServices = null;
  public newSubscriptionPlanServices = null;
  public originalAircraftId: string = '';
  public limitedServices: any = [];
  public currentLimitedServiceMovieId: any = '';
  public currentLimitedServiceTVShowId: any = '';
  public previousLimitedServiceMovieId = '';
  public previousLimitedServiceTVShowId = '';
  public serviceId: string = '';
  public planId: string = '';
  public selectedSubscriptionPlan: any = [];
  public subscriptionDetails: any = [];
  public isLoading: boolean = false;
  public loaderText: string = '';
  public disableMakeChangesButton: boolean = false;
  public selectedServiceIndex: number = -1;
  public mismatchServices: any = [];
  public subscribeStatus: boolean = false;
  public mismatchLimitedServices: any = [];

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private subscriptionPlanChangeDialogComponent: MatDialogRef<SubscriptionPlanChangeDialogComponent>,
      public authorizedService: AuthorizedService,
      private subscriptionService: SubscriptionService,
      public coreservice: CoreService,
      private toastService: ToastService,
      public matDialog: MatDialog) {
          this.heading = this.data.heading;
          this.message = this.data.message;
          this.action = this.data.action;
          this.cancel = this.data.cancel;
          this.selectedSubscriptionPlan = this.data.selectedSubscriptionPlan;
          this.planId = this.data.planId;
          this.originalAircraftId = this.data.originalAircraftId;
          this.subscriptionDetails = this.data.subscriptionDetails;

          this.currentLimitedServiceMovieId = 'All';
          this.currentLimitedServiceTVShowId = 'All';

          this.getSelectedSubscriptionPlanDetails();
          this.setLimitedServiceJson();
      }

  ngOnInit() {
  }

  getSelectedSubscriptionPlanDetails() {
    this.isLoading = true;
    return this.subscriptionService.getCurrentSubscriptionPlanDetails(this.originalAircraftId).then(
      (res: any) => {
        this.subscriptionPlanServices = res;
        this.getNewSelectedSubscriptionPlanDetails();
      });
  }

  getNewSelectedSubscriptionPlanDetails() {
    return this.subscriptionService.getNewSubscriptionPlanDetails(this.planId, this.originalAircraftId).then(
      (res: any) => {
        this.newSubscriptionPlanServices = res;

        for (var i = 0; i < this.newSubscriptionPlanServices.length; i++) {
          if (this.newSubscriptionPlanServices[i].service_name == 'MOVIES') {
            // Setting Current Selected Limited Service
            if (this.newSubscriptionPlanServices[i].limited_service_name != null) {
              this.currentLimitedServiceMovieId = this.newSubscriptionPlanServices[i].limited_service_name;
              this.previousLimitedServiceMovieId = this.newSubscriptionPlanServices[i].limited_service_name;
            }
          }
          else if (this.newSubscriptionPlanServices[i].service_name == 'TVSHOWS') {
            if (this.newSubscriptionPlanServices[i].limited_service_name != null) {
              this.currentLimitedServiceTVShowId = this.newSubscriptionPlanServices[i].limited_service_name;
              this.previousLimitedServiceTVShowId = this.newSubscriptionPlanServices[i].limited_service_name;
            }

            // Calling Limited Service list API to fill Dropdown
            var serviceName = this.newSubscriptionPlanServices[i].service_name;
            return this.subscriptionService.getLimitedServicesDetails(this.originalAircraftId, serviceName).then(
              (res: any) => {
                if (res != null) {
                  this.limitedServices = res;
                  this.selectLimitedService();
                  this.compareServices();
                }
              });
          }
        }
    });
  }

  selectLimitedService() {
    if (this.subscriptionDetails.title == 'Silver') {
      var result = this.limitedServices.filter((limitedService) => limitedService.limited_service_name == 'Silver');
      if (result[0] !== undefined && result.length > 0) {
        this.currentLimitedServiceMovieId = result[0].limited_service_name;
        this.currentLimitedServiceTVShowId = result[0].limited_service_name;
      } else {
        this.currentLimitedServiceMovieId = 'All';
        this.currentLimitedServiceTVShowId = 'All';
      }
    } else {
      this.currentLimitedServiceMovieId = 'All';
      this.currentLimitedServiceTVShowId = 'All';
    }
    this.isLoading = false;
  }

  onChangeLimitedService(serviceName) {
    this.compareServices();

    this.serviceId = serviceName;
    //this.compareLimtedServices(serviceName);
    if (this.serviceId == "MOVIES") {
      return this.currentLimitedServiceMovieId;
    } else if (this.serviceId == "TVSHOWS") {
      return this.currentLimitedServiceTVShowId;
    }

  }

  // Setting Default Limited Service Data to All
  setLimitedServiceJson(){
    var item = {}
    item ["service_name"] = "MOVIES";
    item ["limited_service_name"] = "All";
    item ["isChanged"] = false;
    this.mismatchLimitedServices.push(item);

    var item2 = {}
    item2 ["service_name"] = "TVSHOWS";
    item2 ["limited_service_name"] = "All";
    item2 ["isChanged"] = false;
    this.mismatchLimitedServices.push(item2);
  }

  // Comparing Limted Given Service name with Current Selected Limited Service Name
  compareLimtedServices(serviceName){
    if(serviceName == "MOVIES") {
      var oldLimitedServiceName = 'All';
      Object.keys(this.subscriptionPlanServices).forEach(key => {
        if (this.subscriptionPlanServices[key].service_name === "MOVIES") {
          if (this.subscriptionPlanServices[key].limited_service_name == null) {
            oldLimitedServiceName = 'All';
          } else {
            oldLimitedServiceName = this.subscriptionPlanServices[key].limited_service_name;
          }

          if(oldLimitedServiceName === this.currentLimitedServiceMovieId) {
            if (this.mismatchLimitedServices[0].service_name === "MOVIES") {
                // set true because when we select silver subscription plan then limited service value does not updated
                this.mismatchLimitedServices[0].isChanged = true;
            }
          }
          else {
            if (this.mismatchLimitedServices[0].service_name === "MOVIES") {
              this.mismatchLimitedServices[0].limited_service_name = this.currentLimitedServiceMovieId;
              this.mismatchLimitedServices[0].isChanged = true;
            }
          }
        }
      });
    }
    else if (serviceName == "TVSHOWS"){
      Object.keys(this.subscriptionPlanServices).forEach(key => {
        if (this.subscriptionPlanServices[key].service_name === "TVSHOWS") {
          if (this.subscriptionPlanServices[key].limited_service_name == null) {
            oldLimitedServiceName = 'All';
          } else {
            oldLimitedServiceName = this.subscriptionPlanServices[key].limited_service_name;
          }

          if(oldLimitedServiceName === this.currentLimitedServiceTVShowId) {
            if (this.mismatchLimitedServices[1].service_name === "TVSHOWS") {
              // set true because when we select silver subscription plan then limited service value does not updated
              this.mismatchLimitedServices[1].isChanged = true;
            }
          } else {
            if (this.mismatchLimitedServices[1].service_name === "TVSHOWS") {
              this.mismatchLimitedServices[1].limited_service_name = this.currentLimitedServiceTVShowId;
              this.mismatchLimitedServices[1].isChanged = true;
            }
          }
        }
      });
    }
  }

  // Saving Limited Service and Calling API
  saveLimitedService(currentLimitedServiceId: any, serviceName: string) {
    this.subscriptionService.setLimitedService(this.originalAircraftId, currentLimitedServiceId, serviceName).then(
      (res: any) => {
        this.toastService.toastSuccess("success_aircraft_subscription_plan_update", "aircraft_subscription_management");
        if(serviceName == "MOVIES")
        {
          this.mismatchLimitedServices[0].isChanged = false;
          this.callLimitedServiceAPI();
        }
        else if (serviceName == "TVSHOWS"){
          this.mismatchLimitedServices[1].isChanged = false;
          this.callLimitedServiceAPI();
        }
        else
        {
          this.closeDialog();
        }
      },
      (err: any) => {
        console.log("Error in Save Service", err);
      }
    );
  }

  actionClicked() {
    var dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['matdialog-custom-dialog', 'confirm-dialog-subscription-change', 'dialog'];
    dialogConfig.data = {
      heading: strings.aircraft_Subcription_Plan_change,
      message: strings.aircraft_Subcription_Plan_change_text,
      action: strings.accept,
      cancel: strings.cancel,
    }

    dialogConfig.disableClose = true;
    let subscriptionPlanConfirmDialogComponent = this.matDialog.open(SubscriptionPlanConfirmDialogComponent, dialogConfig);
    subscriptionPlanConfirmDialogComponent.beforeClosed().subscribe((res: any) => { });

    subscriptionPlanConfirmDialogComponent.afterClosed().subscribe(
      (res: any) => {
        if (res.event === 'action') {
          this.isLoading = true;
          this.disableMakeChangesButton = true;
          // Check if Movies OR TVSHOWs Selected then Compare Limited Service
          this.verifyLimitedService();

          this.loaderText = "Updating subscription plan to " + this.planId;
          return this.subscriptionService.selectSubscriptionPlan(this.originalAircraftId, this.planId).then(
            (res: any) => {
              if (res.status == 200) {
                this.selectedSubscriptionPlan = res;
                if(this.mismatchServices.length > 0){
                  this.saveAction(0);
                }
                else
                {
                  this.closeDialog();
                }
              }
            },
            (err: any) => {
              this.isLoading = false;
              this.disableMakeChangesButton = false;
              this.toastService.toastError("error_aircraft_plan_select", "aircraft_management");
            }
          );
        }
      }
    );
  }

  saveAction (index: number) {
      var element = this.mismatchServices[index];
      this.callSubscribeAPI(element,index);
  }

  callSubscribeAPI(element: any, index: number)
  {
    this.subscribeStatus = element.is_selected ? true : false;
    if(this.subscribeStatus)
      this.loaderText = "Subscribing " + element.service_title ;
    else
      this.loaderText = "Unsubscribing " + element.service_title ;

    return this.subscriptionService.subscribeService(element, this.originalAircraftId, this.subscribeStatus).then(
      (res: any) => {
        if (res.status === 200) {
          // Display Success Message
        }
        else {
          // Display Fail Message
          this.toastService.toastError("error_aircraft_update_service", "aircraft_subscription_management");
        }
        if(index < this.mismatchServices.length -1)
            {
              index++;
              this.saveAction(index);
            }
            else
            {
              this.callLimitedServiceAPI();
            }
      },
      (err: any) => {
        console.log("API Failed with Error : ",err);
        this.toastService.toastError("error_aircraft_update_service", "aircraft_subscription_management");
        this.closeDialog();
      }
    );
  }

  verifyLimitedService()
  {
    Object.keys(this.newSubscriptionPlanServices).forEach(key => {
      if (this.newSubscriptionPlanServices[key].service_name == "MOVIES"
      && this.newSubscriptionPlanServices[key].is_selected == 4)
      {
        this.compareLimtedServices("MOVIES");
      }
      if (this.newSubscriptionPlanServices[key].service_name == "TVSHOWS"
      && this.newSubscriptionPlanServices[key].is_selected == 4){
        this.compareLimtedServices("TVSHOWS");
      }
    });
  }

  callLimitedServiceAPI()
  {
    if (this.mismatchLimitedServices[0].service_name === "MOVIES"
    && this.mismatchLimitedServices[0].isChanged === true)
    {
      this.loaderText = "Updating Limited Service MOVIES to " + this.currentLimitedServiceMovieId;
      this.saveLimitedService(this.currentLimitedServiceMovieId,"MOVIES");
    }
    else if (this.mismatchLimitedServices[1].service_name === "TVSHOWS"
    && this.mismatchLimitedServices[1].isChanged === true)
    {

      this.loaderText = "Updating Limited Service TVSHOWS to " + this.currentLimitedServiceTVShowId;
      this.saveLimitedService(this.currentLimitedServiceTVShowId,"TVSHOWS");
    }
    else
    {
      this.closeDialog();
    }

  }

  closeDialog()
  {
    this.isLoading = false;
    this.disableMakeChangesButton = false;
    console.log("ALL API Success.... so closing Dialog" );
    this.toastService.toastSuccess("success_aircraft_plan_selected", "aircraft_management");
    this.subscriptionPlanChangeDialogComponent.close();
    this.coreservice.changePlan = false;
    this.coreservice.selectPlan = false;
    this.coreservice.editPlan = false;
  }

  cancelClicked() {
    this.subscriptionPlanChangeDialogComponent.close();
    this.coreservice.changePlan = false;
    this.coreservice.selectPlan = false;
    this.coreservice.editPlan = false;
  }

  subscribeService(service: any, index: number) {
    var isSelected: number = service.is_selected;

    var status: boolean = false;

    if (service.service_name == "OPERATOR_CONTENT") {
      if (service.is_selected == 4) {
        for (var i = 0; i < this.newSubscriptionPlanServices.length; i++) {
          if (this.newSubscriptionPlanServices[i].service_name == "CARRYON") {
            if (this.newSubscriptionPlanServices[i].is_selected == 4) {
              this.newSubscriptionPlanServices[i].is_selected = 0;
            }
          }
        }
      }
    }

    if (service.service_name == "CARRYON") {
      if (service.is_selected == 0) {
        for (var i = 0; i < this.newSubscriptionPlanServices.length; i++) {
          if (this.newSubscriptionPlanServices[i].service_name == "OPERATOR_CONTENT") {
            if (this.newSubscriptionPlanServices[i].is_selected == 0) {
              this.newSubscriptionPlanServices[i].is_selected = 4;
            }
          }
        }
      }
    }

    if (isSelected === 0) {
      console.log("service.id", service.id);
      this.newSubscriptionPlanServices[index].is_selected = 4;
    } else if (isSelected === 4) {
      this.newSubscriptionPlanServices[index].is_selected = 0;
    }

    this.compareServices();
  }

  compareServices() {
    const subscriptionPlanServices = this.subscriptionPlanServices;
    const newSubscriptionPlanServices = this.newSubscriptionPlanServices;

    let j = 0;
    for (let i = 0; i < subscriptionPlanServices.length; i++) {
      if (subscriptionPlanServices[i].is_selected === newSubscriptionPlanServices[j].is_selected) {
        if (newSubscriptionPlanServices[j].service_name == 'MOVIES') {
          if (subscriptionPlanServices[i].limited_service_name == null && this.currentLimitedServiceMovieId == 'All' ||
            subscriptionPlanServices[i].limited_service_name === this.currentLimitedServiceMovieId) {
            this.defaultStyle(newSubscriptionPlanServices, j);
          } else {
            this.applyRedColorStyle(newSubscriptionPlanServices, j);
          }
        } else if (newSubscriptionPlanServices[j].service_name == 'TVSHOWS') {
          if (subscriptionPlanServices[i].limited_service_name == null && this.currentLimitedServiceTVShowId == 'All' ||
            subscriptionPlanServices[i].limited_service_name === this.currentLimitedServiceTVShowId) {
              this.defaultStyle(newSubscriptionPlanServices, j);
          } else {
            this.applyRedColorStyle(newSubscriptionPlanServices, j);
          }
        } else {
          this.defaultStyle(newSubscriptionPlanServices, j);
        }
      } else {
        this.applyRedColorStyle(newSubscriptionPlanServices, j);
      }
      j++;
    }
  }

  defaultStyle(service: any, index: any) {
    // if contains then only remove
    var isInArray = this.mismatchServices.indexOf(service[index]) !== -1;
    if(isInArray)
      this.mismatchServices.pop(service[index]);
    document.getElementById(service[index].service_title).style.color = "#6D6E71";
  }

  applyRedColorStyle(service: any, index: any) {
    document.getElementById(service[index].service_title).style.color = "red";
    // if Not contains then only push
    var isInArray = this.mismatchServices.indexOf(service[index]) !== -1;
    if(!isInArray)
      this.mismatchServices.push(service[index]);
  }
}
