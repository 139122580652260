import { Injectable, Inject, Output, EventEmitter } from '@angular/core';
declare var angular: any;
import { downgradeInjectable } from '@angular/upgrade/static';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Location } from '@angular/common';
import { CoreService } from './core.service';
import { UserService } from './user.service';
import { MediadriveService } from './mediadrive.service';
import { AircraftService } from './aircraft.service';
import { OperatorService } from './operator.service';
import * as uuid from 'uuid';
import settings from '../../../../config.json';
import { ToastService } from './toast.service';
import * as _ from "lodash";

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  private refreshTokenInProgress: boolean = false;
  @Output() tokenRefreshed: EventEmitter<any>;

  constructor (
    private http: HttpClient,
    private coreservice: CoreService,
    private toastService: ToastService,
    private userService: UserService,
    private aircraftService: AircraftService,
    private operatorService: OperatorService,
    private location: Location
  ) {
    this.tokenRefreshed = new EventEmitter<any>();
   }

  getUuid() {
    var storedUuid: string = localStorage.getItem('uuid');
    if (!storedUuid) {
      this.setUuid().then(
        (res: string) => {
          this.coreservice.uuid = res;
          return this.coreservice.uuid;
        });
    } else {
      this.coreservice.uuid = storedUuid;
      return this.coreservice.uuid;
    }
  }

  setUuid() {
    var guid: string = uuid.v1();
    localStorage.setItem('uuid', guid);
    return new Promise((resolve, reject) => {
      resolve(guid);
    })
  }

  logIn(username: string, password: string, remember: any, accessToken: any) {
    var url: string = settings.configuration.env.idUrl + 'token';
    var params = new HttpParams()
      .append('username', username)
      .append('password', password)
      .append('remember', remember)
      .append('client_instance', this.coreservice.uuid)
      .append('access_token', accessToken)
      .append('client_type', 'PORTAL');

    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    let options = {
      headers: httpHeaders
    };
    return this.http
      .post(url, params, options)
      .toPromise().then(
        (res: any) => {
          var haveToAcceptUserAgreement: boolean = res.agreement_not_accepted;
          var haveToChangePassword: boolean = res.change_password;
          var otherAircraftsInUse: string = _.uniq(res.other_contexts_in_use);
          var storedAircraftuuid: string = localStorage.getItem('aircraftuuid');

          localStorage.setItem('aircrafts in use', otherAircraftsInUse);

          if (_.includes(otherAircraftsInUse, storedAircraftuuid)) {
            this.toastService.toastError("multisession_started", "operator_management");
            localStorage.setItem('aircrafts in use', otherAircraftsInUse);
          }

          if (remember) {
            this.setLocalToken(res.access_token, username);
            this.coreservice.sessionType = 'local';
            this.rememberUsername(username);

            if (haveToAcceptUserAgreement === true || haveToChangePassword === true) {
              var data = {
                sessionType: 'local',
                haveToAcceptUserAgreement: haveToAcceptUserAgreement,
                haveToChangePassword: haveToChangePassword
              };

              return data;
            } else {
              this.init(username);
            }
          } else {
            this.setSessionToken(res.access_token, username);
            this.coreservice.sessionType = 'session';
            this.forgetUsername();
            if (haveToAcceptUserAgreement === true || haveToChangePassword == true) {
              var data = {
                sessionType: 'session',
                haveToAcceptUserAgreement: haveToAcceptUserAgreement,
                haveToChangePassword: haveToChangePassword
              };

              return data;
            } else {
                this.init(username);
            }
          }
        });
  }

  // SSO_Branding: For to login dassault user
  logInWithDasault(accessToken: string) {
    console.log(" logInWithDasault Called...");
    var url: string = settings.configuration.env.idUrl + 'validateAuthorization';

    var params = new HttpParams()
      .append('access_token', accessToken)
      .append('client_instance', this.coreservice.uuid)
      .append('client_type', 'PORTAL')
      .append('client_context', '');

    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    let options = {
      headers: httpHeaders
    };

    return this.http
      .post(url, params, options)
      .toPromise().then(
        (res: any) => {
          var otherAircraftsInUse: string = _.uniq(res.other_contexts_in_use);
          var storedAircraftuuid: string = localStorage.getItem('aircraftuuid');
          console.log("validateAuthorization Response...",res);
          localStorage.setItem('aircrafts in use', otherAircraftsInUse);
          this.coreservice.username = res.username;

          if (_.includes(otherAircraftsInUse, storedAircraftuuid)) {
            this.toastService.toastError("multisession_started", "operator_management");
            localStorage.setItem('aircrafts in use', otherAircraftsInUse);
          }

          return res;
        });
  }

//   mapStageDassaultUser(accessToken: string, userName: string){
//     {
//       var url: string = settings.configuration.env.apiUrl + 'account/users/mapstagedassaultuser';
//       var params = new HttpParams()
//         .append('username', userName)
//         .append('access_token', accessToken);

//       console.log("default params..", params);

//       let httpHeaders = new HttpHeaders({
//         'Content-Type': 'application/json',
//       });

//       let options = {
//         headers: httpHeaders
//       };
//       return this.http
//         .post(url, params, options)
//         .toPromise().then(
//           (res: any) => {
//             console.log("res " + res);
//           },
//           (err: any) => { console.log ("Error in getting Code" + err)});
//     }
//  }

mapStageDassaultUser(accessToken: string, userName: string){
  {
    var url: string = settings.configuration.env.apiUrl + 'account/users/mapstagedassaultuser';
    // var params = new HttpParams()
    //   .append('username', userName)
    //   .append('access_token', accessToken);

    // console.log("default params..", params);

    // let httpHeaders = new HttpHeaders({
    //   'Content-Type': 'application/json',
    // });

    // let options = {
    //   headers: httpHeaders
    // };
    var params = {
      username: userName,
      access_token: accessToken
    };

    return this.http
      .post(url, params)
      .toPromise().then(
        (res: any) => {
          console.log("res " + res);
        },
        (err: any) => { console.log ("Error in getting Code" + err)});
  }
}
  init(username: string) {
    this.location.go('/');
    this.userService.getUser(username);
    this.operatorService.getOperator();
    this.aircraftService.getAircraft();
    this.coreservice.isAuth = true;
  }

  logOut() {
    var url: string = settings.configuration.env.idUrl + 'logout';
    return this.http
      .post(url, null)
      .subscribe(
        (res: string) => {
          this.coreservice.isAuth = false;
          this.removeToken();
          window.location.href = "/login";
        },
        (err: string) => {
          this.removeToken();
          window.location.href = "/login";
        }
      );
  }

  isrefreshTokenInProgress() {
    return this.refreshTokenInProgress;
  }

  ontokenRefreshed() {
    this.refreshTokenInProgress = false;
    this.tokenRefreshed.emit();
  }

  refreshToken() {
    this.refreshTokenInProgress = true;
    var storedAircraftuuid: string = localStorage.getItem('aircraftuuid');
    var sessionType: string = this.coreservice.sessionType;

    var url: string =  settings.configuration.env.idUrl + 'refreshtoken';
    var params = new HttpParams()
      .append('client_type', "PORTAL")
      .append('client_instance', this.coreservice.uuid)
      .append('client_context', storedAircraftuuid)

    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    let options = {
      headers: httpHeaders
    };

    return this.http
      .post(url, params, options)
      .toPromise().then(
        (res: any) => {
          var otherAircraftsInUse: string = _.uniq(res.other_contexts_in_use);

          if (_.includes(otherAircraftsInUse, storedAircraftuuid)) {
            this.toastService.toastError("multisession_started", "operator_management");
            localStorage.setItem('aircrafts in use', otherAircraftsInUse);
          } else {
            var oldAircrafts: string = localStorage.getItem('aircrafts in use');
            if((_.includes(oldAircrafts, storedAircraftuuid)) && !(_.includes(otherAircraftsInUse, storedAircraftuuid))){
              localStorage.setItem('aircrafts in use', otherAircraftsInUse);
              this.toastService.toastSuccess("multisession_stopped", "operator_management");
            }
          }

          if (sessionType == 'local') {
            var username: string = JSON.parse(localStorage.getItem('authorizationData')).username;
            this.setLocalToken(res.access_token, username);
          } else if (sessionType == 'session') {
            var username: string = JSON.parse(sessionStorage.getItem('authorizationData')).username;
            this.setSessionToken(res.access_token, username);
          }

          return res;
        },
        (err: string) => { return err }
        );
  }

  setLocalToken(token: string, username: string) {
    var storageData: string = JSON.stringify({
      token: token,
      username: username
    });

    return localStorage.setItem('authorizationData', storageData);
  }

  setSessionToken(token: string, username: string) {
    var storageData: string = JSON.stringify({
        token: token,
        username: username
    });

    return sessionStorage.setItem('authorizationData', storageData);
  }

 async  resetPassword(email: string) {
    var url: string = settings.configuration.env.apiUrl + 'account/requestpasswordreset';
     var params = new HttpParams()
       .append('email', email);

    return this.http
      .post(url, params, {
        observe: "response"
     })
      .toPromise().then(
        (res) => { return res },
        (err) => { return err }
      );
  }

  sendNewPassword(newPassword: string, newPasswordConfirm: string, token: string) {
    var url: string = settings.configuration.env.apiUrl + 'account/resetpassword?resetToken=' + token;
    var params = {
      new_password: newPassword,
      new_password2: newPasswordConfirm
    };
    return this.http
      .post(url, params, {
        observe: "response"
      })
      .toPromise().then(
        (res) => { return res },
        (err) => { return err }
      );
  }

  changePassword(oldPassword: string, newPassword: string, passwordConfirm: string) {
    var url: string = settings.configuration.env.apiUrl + 'account/changePassword';
    // var params = new HttpParams()
    //   .append('old_password', oldPassword)
    //   .append('new_password', newPassword)
    //   .append('new_password2', passwordConfirm);
    var params = {
      old_password: oldPassword,
      new_password: newPassword,
      new_password2: passwordConfirm
    }
    return this.http
      .post(url, params, {
        observe: "response"
      })
      .toPromise().then(
        (res) => { return res }
      );
  }

  createFirstPassword(newPassword: string, newPasswordConfirm: string) {
    var url: string = settings.configuration.env.apiUrl + 'account/setpassword';
    var params = {
      new_password: newPassword,
      new_password2: newPasswordConfirm
    };
    return this.http
      .post(url, params, {
        observe: "response"
      })
      .toPromise().then(
        (res) => { return res },
        (err) => { return err }
      );
  }

  removeToken() {
    localStorage.removeItem('authorizationData');
    sessionStorage.removeItem('authorizationData');
  }

  rememberUsername(username: string) {
    localStorage.setItem('username', username);
  }

  forgetUsername() {
    localStorage.removeItem('username');
  }

  getLocalAuthData() {
    return localStorage.getItem('authorizationData');
  }
}

angular
  .module('app.core')
  .factory('authService', downgradeInjectable(AuthService));
