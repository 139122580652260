import { Injectable } from '@angular/core';
declare var angular: any;
import { downgradeInjectable } from '@angular/upgrade/static';
import settings from '../../../../config.json';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FeedService {

  constructor( private http: HttpClient) { }

  getFeeds() {
    var url: string = settings.configuration.env.apiUrl + 'fleet/selected/aircraft';
    return this.http
    .get(url)
    .toPromise().then((aircraftres: any) => {
      console.log("aircraftres", aircraftres);
      var secondUrl = settings.configuration.env.apiUrl + 'content/feeds';
        return this.http.get(secondUrl)
        .toPromise().then((res: any) => {
        if(!!res) {
            return res;
        } else {
            return null;
        }
    });
  });
}

  getFeedsTopics(feed_id: string) {
    console.log("getFeedsTopics");
    var url: string = settings.configuration.env.apiUrl + 'content/feeds/feed/' + feed_id;
    return this.http.get(url)
      .toPromise().then((res: any) => {
        return res;
      });
  }

  getCitites(skip: string, take: string) {
    console.log("getCitites");
    var url: string = settings.configuration.env.apiUrl + 'content/weather/selected/cities?skip=' + skip + '&take=' + take;
    return this.http.get(url)
      .toPromise().then((res: any) => {
        return res;
      });
  }

  getCitiesList() {
    console.log("getCitiesList");
    var url: string = settings.configuration.env.apiUrl + 'content/weather/list';
    return this.http.get(url)
      .toPromise().then((res: any) => {
        return res;
      });
  }

  selectCity(cityId, boolean) {
    console.log("selectCity");
    var url: string = settings.configuration.env.apiUrl + 'content/weather/select/' + boolean + '/city/' + cityId;
    return this.http.post(url, null)
      .toPromise().then((res: any) => {
        return res;
      });
  }

  getStocks() {
    console.log("getStocks");
    var url: string = settings.configuration.env.apiUrl + 'content/stocks/selected/stocks';
    return this.http.get(url)
      .toPromise().then((res: any) => {
        return res;
      });
 }

  getSports(feed_id: string) {
    console.log("getSports");
    var url: string = settings.configuration.env.apiUrl + 'content/feeds/categorizedfeed/' + feed_id;
    return this.http.get(url)
      .toPromise().then((res: any) => {
        return res;
      });
  }

  getStocksList(category: string) {
    console.log("getStocksList");
    var url: string = settings.configuration.env.apiUrl + 'content/stocks/list/category/' + category;
    return this.http.get(url)
      .toPromise().then((res: any) => {
        return res;
      });
  }

  getExternalStock(symbolName: string, qualifier: string) {
    var url: string = settings.configuration.env.apiUrl + 'content/stocks/list/category/EQUITY/symbol/' + symbolName + '/qualifier/' + qualifier;
    return this.http.get(url)
      .toPromise().then((res: any) => {
        return res;
      });
  }

  selectStock(stockId: string, boolean) {
    var url:string = settings.configuration.env.apiUrl + 'content/stocks/select/' + boolean + '/stock/' + stockId;
    return this.http.post(url, null)
      .toPromise().then((res: any) => {
        return res;
    });
  }

  removeNews(item_id: string, type: string, contentType, boolean, item_child_id: string) {
    var url: string = settings.configuration.env.apiUrl + 'content/feeds/feed/' + type + '/topics/topic/' + item_id + '/sources/select/' + boolean + '/source/' + item_child_id;
    return this.http.post(url, null)
      .toPromise().then((res: any) => {
        return res;
    });
  }
}

angular
  .module('app.core')
  .factory('feedService', downgradeInjectable(FeedService));