import { Injectable } from '@angular/core';
declare var angular: any;
import { downgradeInjectable } from '@angular/upgrade/static';

import { HttpClient } from '@angular/common/http';
import settings from '../../../../config.json';

@Injectable({
    providedIn: 'root'
})

export class DownloadService {

    constructor( private http: HttpClient ) { }

    getSyncList(aircraftId: string) {
        var url: string = settings.configuration.env.apiUrl + 'fleet/aircraft/' + aircraftId + '/downloadqueue/elements';
        return this.http
            .get(url)
            .toPromise().then(
                (res: any) => { return res },
                (err: any) => { console.log("Error" + err) }
            )
    }


    setSyncOrder(aircraftId: string, fileName: string, newOrder: string) {
        var url: string = settings.configuration.env.apiUrl + 'fleet/aircraft/' + aircraftId + '/downloadqueue/element/' + fileName + '/set/order/' + newOrder;
        return this.http
            .post(url, null, { observe: "response" })
            .toPromise().then(
                (res: any) => { return res },
                (err: any) => { console.log("Error" + err) }
            )
    }
            

    setDownloadMethod(aircraftId: string, fileName: string, connectionTypeId: string, boolean: boolean) {
        var url: string = settings.configuration.env.apiUrl + 'fleet/aircraft/' + aircraftId + '/downloadqueue/element/' + fileName + '/connectiontype/' + connectionTypeId + '/select/' + boolean;
        return this.http
            .post(url, null, { observe: "response" })
            .toPromise().then(
                (res: any) => { return res },
                (err: any) => { console.log("Error" + err) }
          )
    }
}


angular
  .module('app.core')
  .factory('downloadService', downgradeInjectable(DownloadService));
