import { Injectable } from '@angular/core';
declare var angular: any;
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
  providedIn: 'root'
})

export class CoreService {

  public readyToAuth: boolean = true;
  public isAuth: boolean = false;
  public sessionType: any = '';
  public uuid: any = '';
  public token: any = '';
  public user: any = '';
  public username: any = '';
  public operator: any = '';
  public aircraft: any = '';
  public mediaDrive: any = '';
  public currentPlaylistSize: any = '';
  public textloader: boolean = false;
  public blockUI: boolean = false;
  public spinner: boolean = true;
  public operatorName: string = '';
  public hideSpinner;
  public showingList: boolean = false;
  public lastAircraftManifestTime: any;
  public showingFalconUser: boolean = false;
  public hideUnlinkUser: boolean = false;
  public changePlan: boolean = false;
  public selectPlan: boolean = false;
  public editPlan: boolean = false;
  public errorToast: any;
  public customToast: any;

  constructor() { }

}

angular
  .module('app.core')
  .factory('coreservice', downgradeInjectable(CoreService));
