import { Injectable } from '@angular/core';
declare var angular: any;
import { downgradeInjectable } from '@angular/upgrade/static';
import settings from '../../../../config.json';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

    constructor(private http: HttpClient) { }

    getSubscriptionPlans() {
        var url:string = settings.configuration.env.apiUrl + 'subscription/plans';
        return this.http
            .get(url)
            .toPromise().then(
                (res : any) => { return res; }
            );
    }


    getSubscriptionPlanDetails(planId: string) {
        var url:string = settings.configuration.env.apiUrl + 'subscription/plans/plan/' + planId;
        return this.http
            .get(url)
            .toPromise().then(
                (res : any) => { return res; }
            );
    }


    getCurrentSubscriptionPlanDetails(aircraftId: string) {
        var url:string = settings.configuration.env.apiUrl + 'subscription/fleet/' + aircraftId + '/services';
        return this.http
            .get(url)
            .toPromise().then(
                (res : any) => { return res; }
            );
    }

    getNewSubscriptionPlanDetails(planId: string, aircraftId: string) {
        console.log(" getNewSubscriptionPlanDetails plan ", planId , "Aircraft ",aircraftId);
        var url:string = settings.configuration.env.apiUrl + 'subscription/plan/'+ planId +'/fleet/' + aircraftId + '/services';
        return this.http
            .get(url)
            .toPromise().then(
                (res : any) => { return res; }
            );
    }

    getLimitedServicesDetails(aircraftId: string, serviceId: string) {
      var url:string = settings.configuration.env.apiUrl + 'subscription/fleet/' + aircraftId + '/services/'+ serviceId +'/limitedservices';
      return this.http
          .get(url)
          .toPromise().then(
              (res : any) => { return res; }
          );
    }

    setLimitedService(aircraftId: string, limitedServiceId: string, serviceId: string) {
      //var url: string = settings.configuration.env.apiUrl + 'subscription/fleet/' + aircraftId + '/limitedservice/' + limitedServiceId;
      console.log("limited Service Id ", limitedServiceId);
      var url: string = settings.configuration.env.apiUrl + 'subscriptions/services/subscribe/service/'+ serviceId +'/limitedService/' + limitedServiceId + '/fleet/' + aircraftId ;
      return this.http
          .post(url, null, { observe: "response"})
          .toPromise().then(
              (res: any) => { return res },
              (err: any) => { console.log ("Error setting Limited Service" + err)});
    }

    getCurrentSubscriptionPlan(aircraftId: string) {
        var url:string = settings.configuration.env.apiUrl + 'subscription/plans/fleet/' + aircraftId;
        return this.http
            .get(url)
            .toPromise().then(
                (res : any) => { return res; }
            );
    }


    selectSubscriptionPlan(aircraftId: string, subscriptionPlanId: string) {
        var url:string = settings.configuration.env.apiUrl + 'subscription/plans/select/plan/' + subscriptionPlanId + '/fleet/' + aircraftId;
        return this.http
            .post(url, null, { observe: "response"})
            .toPromise().then(
                (res : any) => { return res; }
            );
    }


    editSubscriptionPlanService(aircraftId: string, service: any) {
        var url:string = settings.configuration.env.apiUrl + 'subscriptions/services/edit/service/' + service.service_name + '/fleet/' + aircraftId;
        return this.http
            .post(url, service, { observe: "response" })
            .toPromise().then(
                (res) => { return res },
                (err) => { return err }
            );
    }


    subscribeService(service: any, aircraftId: string, status: boolean) {
        var url:string = settings.configuration.env.apiUrl + 'subscriptions/services/subscribe/service/' + service.service_name + '/fleet/' + aircraftId + '/status/' + status;
        return this.http
            .post(url, service, { observe: "response" })
            .toPromise().then(
                (res) => { return res },
                (err) => { return err }
            );
    }
}


angular
  .module('app.core')
  .factory('subscriptionService', downgradeInjectable(SubscriptionService));
