import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ToastService } from '../core/services/toast.service';
import { UserService } from '../core/services/user.service';

@Component({
  selector: 'app-user-confirm-dialog',
  templateUrl: '../layout/confirm-dialog.html',
  styleUrls: ['../aircraft/aircraft.less', './user.component.less']
})
export class UserConfirmDialog implements OnInit {

  public option;
  public deleteName;

  constructor(private toastService: ToastService,
              private userService: UserService,
              private UserConfirmDialog: MatDialogRef<UserConfirmDialog>,
              @Inject(MAT_DIALOG_DATA) private dialogData: any)
               { 
                this.option = 'user';
                this.deleteName = this.dialogData.userId;
              }

  ngOnInit() {
  }

  deleteConfirmed() {
    return this.userService.deleteUser(this.dialogData.userId).then((res) => {
        if (res.status == 200) {
            this.toastService.toastSuccess("success_usermanagement_delete_user", "usermanagement_management");
            this.UserConfirmDialog.close();
        } else {
            this.toastService.toastError("error_usermanagement_delete_user", "usermanagement_management");
        }
    });
  }
  closeThisDialog() {
    this.UserConfirmDialog.close();
  }
}

