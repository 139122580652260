import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[ngInit]'
})
export class NgInitDirective {

  constructor() { }

  @Input() ngInit: () => any;
  ngOnInit() {
    if(this.ngInit) { console.log("nginit", this.ngInit);this.ngInit(); }
  } 

}
