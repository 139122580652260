import { Component, OnInit, ViewChild, KeyValueDiffers } from '@angular/core';
declare var angular: angular.IAngularStatic;
import { downgradeComponent } from '@angular/upgrade/static';
import { ChartType } from 'chart.js';
import { MenuService } from '../core/services/menu.service';
import settings from '../../../config.json';
import { CoreService } from '../core/services/core.service';
import { HttpClient } from '@angular/common/http';
import { AircraftService } from '../core/services/aircraft.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { SelectAircraft } from './select-aircraft.component';
import { Label, Colors, BaseChartDirective } from 'ng2-charts';
import { AuthorizedService } from '../core/services/authorized.service';
import { MediadriveService } from '../core/services/mediadrive.service';
import { formatDate } from '@angular/common';


@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less']
})

export class SidebarComponent implements OnInit {
  @ViewChild('baseChart', {static: false}) chart: BaseChartDirective
  menuItems:Array<object> = [];
  image:string;
  mediaDrive:any;
  blockUI:boolean = false;
  differ:any;
  is_aircraft_id:boolean = true;
  doughnutChartLabels: Label[];
  doughnutChartData: Array<object> = [];
  doughnutChartType: ChartType;
  doughnutChartColor: Colors[];
  doughnutChartOptions: any;
  chartReady:boolean = false;
  fleetMode:boolean = false;
  masterAircraftId = "";
  aircrafts = [];
  primaryAircraftEnum;
  prevOperatorValue = "";
  public today: Date;
  public dateTime: any = settings.configuration.datetime_formats;
  usedSpace: number = 0;

  constructor(private http:HttpClient,
              private menuService:MenuService,
              public coreservice:CoreService,
              private aircraftService:AircraftService,
              private differs: KeyValueDiffers,
              public authorizedService: AuthorizedService,
              private mediadriveService: MediadriveService,
              private dialog:MatDialog) {
    menuService.getContentMenu().then((res:any) => {
      this.menuItems = res;
    });
    
    this.image = settings.configuration.env.apiUrl + 'images/fleet/';
    this.mediaDrive = coreservice.mediaDrive;
    this.primaryAircraftEnum = 0;
    this.prevOperatorValue = this.coreservice.operator.name;
    this.usedSpace = this.mediaDrive.used_space
    this.getAssignedAircrafts();

    if (this.authorizedService.checkIfAuthorized(['Global Privileges.Read'], 'onlyOne')) {
      this.mediadriveService.getAircraftManifestLastCreatedDate(this.coreservice.aircraft.aircraft_id).then((res:any) => {
          this.coreservice.lastAircraftManifestTime = res;
      });
    }
    this.differ = this.differs.find({}).create();

    this.chartDataUpdate();

    this.mediadriveService.chartRefreshed.subscribe(() => {
      this.chartDataUpdate();
    });
  }

  chartDataUpdate() {
    if(!!this.mediaDrive) {
      this.doughnutChartLabels = ['Movies', 'TV Shows', 'Music', 'Games', 'Personal', 'Magazines', 'Destinations', 'Available'];
      this.doughnutChartData = [
      this.mediaDrive.movies_size,
      this.mediaDrive.episodes_size,
      this.mediaDrive.tracks_size,
      this.mediaDrive.games_size,
      this.mediaDrive.document_size,
      this.mediaDrive.magazines_count,
      this.mediaDrive.destinations_count,
      this.mediaDrive.free_disk_space
      ];
      this.doughnutChartType = "doughnut";
      this.doughnutChartColor = [ {backgroundColor:['#f16a4c', '#ef922c', '#edc500', '#93cf66', '#678dce', '#F3817A', '#545F66', '#9f9f9f']} ];
      this.doughnutChartOptions = {
        tooltips: {
          enabled: false,
        },
        elements: {
          arc: {
              borderWidth: 0
          }
        },
        cutoutPercentage: 75,
      };
      this.change_doughnutData();
    }
  }

  getAircraftData() {
    if(!this.coreservice.aircraft) {
      this.is_aircraft_id = false;
    } else {
      this.is_aircraft_id = true;
    }
  }

  getAssignedAircrafts() {
    return this.aircraftService.getAssignedAircrafts().then((res:any) => {
      /* For deciding FleetMode */
      this.aircrafts = res;
      var fleetAircraft = [];
      var secondaryAircraftsList = [];
      this.masterAircraftId = "";
      this.fleetMode = false;
      for(var i = 0; i <this.aircrafts.length; i++)
      {
        if(this.aircrafts[i].isMasterAircraftEnums == this.primaryAircraftEnum){
          this.fleetMode = true;
          this.masterAircraftId = this.aircrafts[i].aircraft_id;
          fleetAircraft.push(this.aircrafts[i]);
        }
        else{
          secondaryAircraftsList.push(this.aircrafts[i]);
        }
      }

      if(this.fleetMode == true){
        this.aircrafts = [];
        for(var i = 0; i < fleetAircraft.length; i++){
        this.aircrafts.push(fleetAircraft[i]);
        }
        for(var i = 0; i < secondaryAircraftsList.length; i++){
          this.aircrafts.push(secondaryAircraftsList[i]);
        }
      }
    });
  }

  ngDoCheck() {
    this.getAircraftData();
    if(!!this.coreservice.mediaDrive) {
      const change = this.differ.diff(this.coreservice.mediaDrive);
      if(change) {
        if (this.usedSpace !== change._additionsTail.currentValue) {
          this.usedSpace = change._additionsTail.currentValue;
          this.change_doughnutData();
          this.menuItemsCleanCall();
        }
      }

      const changeOperator = this.differ.diff(this.coreservice.operator);
      if (changeOperator) {
          if(this.prevOperatorValue != this.coreservice.operator.name){
            this.getAssignedAircrafts();
            this.prevOperatorValue = this.coreservice.operator.name;
          }
      }
    }
  }

  ngOnInit() {
  }

  addToDownload() {
    var url:string = settings.configuration.env.apiUrl + 'sync';
    return this.http.get(url).
    toPromise().then((res : any) => {
      return res;
    });
  }

  menuItemsCleanCall() {
    return this.menuService.getContentMenu().then(() => {
      this.menuItems = this.menuService.menuItems;
    });
  }

  change_doughnutData() {
    if(!!this.coreservice.mediaDrive) {
      var count = 0;
      var total_size = this.coreservice.mediaDrive.content_disk_space;  //in MB
      var occupied_size = total_size - this.coreservice.mediaDrive.free_disk_space;
      var size_count_arr = [
          this.coreservice.mediaDrive.movies_size,
          this.coreservice.mediaDrive.episodes_size,
          this.coreservice.mediaDrive.tracks_size,
          this.coreservice.mediaDrive.games_size,
          this.coreservice.mediaDrive.document_size,
          this.coreservice.mediaDrive.magazines_count,
          this.coreservice.mediaDrive.destinations_count
      ];                    
      for (var i=0;i<size_count_arr.length;i++){
          if (size_count_arr[i] != 0) {
              count++;
          }
      }
      var occupied_per = ((occupied_size/total_size) * 100);
      // if occupied percentage is less than one then only need to do round upto 1%
      occupied_per = 1 > occupied_per ? (1 - occupied_per) :0;
      var divided_per = occupied_per/count;
      for (var i=0;i<size_count_arr.length;i++){
          if (size_count_arr[i] != 0) {
              size_count_arr[i] = size_count_arr[i] + (total_size*(divided_per/100));
          }
          this.doughnutChartData[i] = size_count_arr[i];
      }
      this.doughnutChartData[7] = this.coreservice.mediaDrive.free_disk_space;
      if(this.chart !== undefined) {
        this.chart.update();
      }
      this.chartReady = true;
    }  
  }

  getAircrafts() {
    this.getAssignedAircrafts().then(() => {
      if (!this.blockUI) {
        this.blockUI = true;
      
        var dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
          aircrafts: this.aircrafts,
          fleetMode: this.fleetMode
        };
        
        dialogConfig.panelClass = ['matdialog-custom-dialog', 'aircraft-width', 'dialog'];
        let selectaircraftdialog = this.dialog.open(SelectAircraft, dialogConfig);
        selectaircraftdialog.beforeClosed().subscribe(res => {
          this.blockUI= false;
        })
      }
    });
  }
}

angular
  .module('app.stage')
  .directive(
    'sidebar',
    downgradeComponent({component: SidebarComponent}) as angular.IDirectiveFactory
  );
