import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contentSearchFilter'
})
export class ContentSearchPipe implements PipeTransform {

  
  transform(contentData: any, searchText: any, keyID = null): any {
    if (!searchText) {
      return contentData;
    }
    else{
        // Create a new array to store the filtered data
        var filteredContent = [];
        var searchText = searchText.toLowerCase();
        var match = false;
        for (var content of contentData) {
            for (let key in content) {
              if (typeof(content[key]) == 'object'){
                var innerContent = content[key];
                for (let innerKey in innerContent) {
                  if (typeof(innerContent[innerKey]) == 'object' && innerContent[innerKey] != null && innerContent[innerKey].id.toString().toLowerCase().indexOf(searchText) > -1) {
                    match = true;
                    break;
                  }
                }
              }
              else {
                if ((content[key] != null && keyID == null && content[key].toString().toLowerCase().indexOf(searchText) > -1) || 
                   (key == keyID && keyID != null && content[key].toString().toLowerCase().indexOf(searchText) > -1)) 
                {
                  match = true;
                  break;
                }
              }
            }
            if (match){
              filteredContent.push(content);
              match = false;
            }
        }
        return filteredContent;
    }
  }

}
