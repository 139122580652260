import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[ImageFallback]'
})
export class ImageFallbackDirective {

  @Input('ImageFallback') placeholder;

  @HostListener('error') bindFallback() {
    this.el.nativeElement.setAttribute('src', this.placeholder);
  }

  constructor(private el: ElementRef) { }

  imageFallback(placeholder) {
    if(!this.el.nativeElement.src) {
      this.el.nativeElement.setAttribute('src', placeholder);
    }
  }

  ngOnInit() {
    this.imageFallback(this.placeholder);
  }
}
