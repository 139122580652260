import { Component, OnInit, EventEmitter, Output} from '@angular/core';
declare var angular: angular.IAngularStatic;
import settings from '../../../config.json';
import { downgradeComponent } from '@angular/upgrade/static';
import { AuthService } from '../core/services/auth.service';
import { ToastService } from '../core/services/toast.service';
import { CoreService } from '../core/services/core.service';
import { Router, ActivatedRoute, UrlTree } from '@angular/router';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { LoginUserAgreementComponent } from '../user-agreement/login-user-agreement.component'
import { SSOConfigurationService } from '../core/services/ssoconfiguration.service';
import { HttpParams } from '@angular/common/http';
import { AircraftService } from '../core/services/aircraft.service';

@Component({
  selector: 'ContentStore',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})

export class LoginComponent implements OnInit {

  @Output() agreementAccepted: EventEmitter<any> = new EventEmitter();
  @Output() passwordCreated: EventEmitter<any> = new EventEmitter();

  resettokenParam: string;
  forgottenParam: string;
  flipStart: boolean;
  flipOn: boolean;
  message: string;
  forgotten: boolean;
  passwordChange: boolean;
  blockUI: boolean;
  resetToken: string;
  loginData: any;
  fromInit: boolean;
  validation: any;
  tokenExpiration: number;
  utcInSeconds: number;
  remember: boolean;
  hasConfirmed: boolean;
  tokenExpired: boolean;
  backface:boolean;
  public username = localStorage.getItem('username');
  public hideLoginScreen: boolean = false;
  public isSSOLogin: boolean = false;
  public dassaultLoginMessage: string;
  private dassaultToken: any;
  private dassaultLoginData: any;
  public dassaultUserName: any;
  private code: string;
  private aircraft_id: string;
  public hideDassaultErrorText: boolean = false;
  public hideDassaultLoginButton: boolean = false;
  public newPassword: any;
  public confirmPassword: any;
  //public showSSOElement: boolean = false;

  constructor(
    private authService: AuthService,
    private toastService: ToastService,
    public coreservice: CoreService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private aircraftService: AircraftService,
    private ssoConfigService: SSOConfigurationService,
  ) {
    this.flipStart = false;
    this.flipOn = false;
    this.message = '';
    this.forgotten = false;
    this.passwordChange = false;
    this.remember = false;
    this.coreservice.blockUI = false;
    this.blockUI = false;
    this.hasConfirmed = false;
    this.validation = settings.configuration.validation.rules;
    this.tokenExpired = false;
    if (this.coreservice.aircraft) {
      this.aircraft_id = this.coreservice.aircraft.aircraft_id;
    }
  }

  ngOnInit() {
    console.log("username oninit: ", this.username);
    this.utcInSeconds = this.getUtcInSeconds();
    this.initActionsListeners();
    this.route.queryParams
      .subscribe((params: any) => {
        //alert(params);
        this.code = params.code;
        this.resettokenParam = params.resettoken;
        this.forgottenParam = params.forgotten;
      });

      var urlTree: UrlTree = this.router.parseUrl(window.location.search);
      this.resettokenParam = urlTree.queryParams.resettoken;
      this.forgottenParam = urlTree.queryParams.forgotten;
      this.code = urlTree.queryParams.code;
      console.log("urlTree ",urlTree.queryParams.sso);
      // if(urlTree.queryParams.sso == "true" || urlTree.queryParams.sso == true){
      //   this.showSSOElement = true;
      // }
      
      this.ssoConfigService.InitSSOConfig(settings.configuration.ssoconfig.authUrl, settings.configuration.ssoconfig.tokenUrl,settings.configuration.ssoconfig.clientId, settings.configuration.ssoconfig.redirectUrl);

    switch (true) {
      case !!this.code :
        this.dassaultCodeRequest();
      break;

      case !!this.resettokenParam:
          this.resetToken = this.resettokenParam;
          this.tokenExpiration = +this.resetToken.split('_')[1];
          if (this.tokenExpiration > this.utcInSeconds) {
              this.flipStart = true;
              this.passwordChange = true;
              setTimeout(() => {
                  this.flipOn = true;
              }, 600);
          } else {
              this.flipStart = true;
              this.forgotten = true;
              this.tokenExpired = true;
              setTimeout(() => {
                  this.router.navigate(['/login'], { queryParams:{ resettoken : null, forgotten : true}});
                  this.flipOn = true;
              }, 600);
          }
          break;

      case !!this.forgottenParam:
          this.flipStart = true;
          this.forgotten = true;
          this.router.navigate(['/login'], { queryParams:{ resettoken : null, forgotten : true}});
          setTimeout(() => {
              this.flipOn = true;
          }, 600);
          break;
      // case !!this.showSSOElement:
      //     console.log("inside Case: ",this.showSSOElement);
      //     break;
    }
  }

  getUtcInSeconds() {
    var now = new Date();
    var utc_time = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),
      now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());
    return (Math.floor(utc_time / 1000));
  }

  login(username: string, password: string, remember: any) {
    console.log("login with username password called...");
    this.coreservice.blockUI = true;
    this.coreservice.readyToAuth = false;
    console.log("username: ", username);
    console.log("Before access_token",this.dassaultToken);
    if(this.dassaultToken==null)
    {
      this.dassaultToken = '';
    }
    console.log("After access_token",this.dassaultToken);

    this.authService.logIn(username, password, remember, this.dassaultToken)
        .then(
          (res: any) => {
            this.message = '';
            this.loginData = res;
            this.coreservice.username = username;
            if (this.loginData) {
            this.initActions();
            }
          },
          (error: any) => {
            this.coreservice.blockUI = false;
            this.coreservice.readyToAuth = true;
            this.message = 'Entered Username or Password is incorrect!'
          });
  }

  initActions() {
      this.fromInit = true;
      if (this.loginData.haveToChangePassword === true) {
        this.openPasswordChange();
      } else if (this.loginData.haveToAcceptUserAgreement === true) {
          this.openUserAgreement();
      }
  }

  initActionsListeners() {
      this.passwordCreated.subscribe(() => {
          this.loginData.haveToChangePassword = false;
          this.authService.refreshToken().then( (res: any) => {
              if (this.loginData.haveToAcceptUserAgreement === true) {
                  this.openUserAgreement();
              } else {
                  setTimeout(() => {
                      this.authService.init(this.coreservice.username);
                  }, 1000);
              }
          });
        });

      this.agreementAccepted.subscribe(() => {
          this.authService.refreshToken().then((res: any) => {
              setTimeout(() => {
                  this.authService.init(this.coreservice.username);
              }, 1000);
          });
      });
  }

  openUserAgreement() {
      if (!this.blockUI) {
          this.blockUI = true;
          this.coreservice.blockUI = true;
          var dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;

          var dialogRef = this.dialog.open(LoginUserAgreementComponent, dialogConfig);
          dialogRef.beforeClose()
            .subscribe(
              (res: any) => {
                if(res == true) {
                  this.agreementAccepted.emit(null);
                  this.hasConfirmed = true;
                }
                this.blockUI = false;
                this.coreservice.blockUI = false;
            },
              (err: any) => {
                this.blockUI = false;
                this.coreservice.blockUI = false;
              }
            )
      }
  }

  forgottenPassword() {
    if (!this.blockUI) {
      this.blockUI = true;
      switch (true) {
        case this.forgotten:
          this.flipStart = false;
          this.flipOn = false;
          this.router.navigate(['/login'], { queryParams:{ resettoken : null, forgotten : null}});
          setTimeout(() => {
            this.forgotten = false;
            this.tokenExpired = false;
            this.blockUI = false;
          }, 600);
          break;
        case !this.forgotten:
          this.flipStart = true;
          this.forgotten = true;
          this.router.navigate(['/login'], { queryParams:{ resettoken : null, forgotten : true}});
          setTimeout(() => {
            this.flipOn = true;
            this.blockUI = false;
          }, 600);
          break;
      }
    }
  }

  sendEmail(email: string) {
    this.coreservice.blockUI = true;
    this.authService.resetPassword(email)
      .then(
        (res: any) => {
          if (res.status == 200) {
            this.toastService.toastSuccess("success_email_sent", "success");
            this.forgottenPassword();
          } else if(res.status == 400) {
            this.toastService.toastError("EMAIL_NOT_SENT", "error");
          } else {
            this.toastService.toastError("error_reset_password", "error");
          }
          this.coreservice.blockUI = false;
        });
  }

  openPasswordChange() {
    this.coreservice.readyToAuth = true;
    this.coreservice.blockUI = false;
    this.hideDassaultErrorText = false;

    if (!this.blockUI) {
      this.blockUI = true;
      switch (true) {
        case this.passwordChange:
          if (this.fromInit) {
            this.authService.logOut();
          } else {
            this.flipStart = false;
            this.flipOn = false;
            this.router.navigate(['/login'], { queryParams:{ resettoken : null, forgotten : null}});
            setTimeout(() => {
              this.passwordChange = false;
              this.blockUI = false;
            }, 600);
          }
          break;

          case !this.passwordChange:
            this.flipStart = true;
            this.passwordChange = true;
            setTimeout(() => {
              this.flipOn = true;
              this.blockUI = false;
            }, 600);
            break;
      }
    }
  }

  closePasswordWithoutLogout() {
      this.flipStart = false;
      this.flipOn = false;
      setTimeout(() => {
         this.passwordChange = false;
         this.router.navigate(['/login'], { queryParams:{ resettoken : null, forgotten : null}});
         this.blockUI = false;
      }, 600);
  }

  createPassword(password: string, passwordConfirm: string) {
    this.coreservice.readyToAuth = true;

    if (this.fromInit) {
      this.authService.createFirstPassword(password, passwordConfirm).then((res: any) => {
        if (res.status == 200) {
          this.toastService.toastSuccess("success_new_password", "success");
          this.closePasswordWithoutLogout();
          this.passwordCreated.emit(null);

          var tkn = this.dassaultToken;
          var uname = this.coreservice.username;
          console.log("tkn", tkn);
          console.log("this.dassaultToken ", this.dassaultToken);
          // this.authService.mapStageDassaultUser(tkn, uname).then(
          //   (res: any) => { console.log( "Stage Dassault User Map Success !: " + res);},
          //   (err: any) => { console.log("Error in Map Stage Dassault User: " + err) }
          // );

          //if (this.isSSOLogin) {
            // SSO_Branding: if response true then logout user
            this.authService.mapStageDassaultUser(tkn, uname).then(
              (res: any) => {
                console.log("Stage Dassault User Map Success !: " + res);
                //this.authService.logOut();
              },
              (err: any) => {
                (err: any) => { console.log("Error in Map Stage Dassault User: " + err) }
              }
            );
          //}
        }
        this.coreservice.blockUI = false;
      }, (err: any) => {
        this.coreservice.blockUI = false;
        this.toastService.toastError("error_please_try_again", "error");
      });
    } else {
      this.authService.sendNewPassword(password, passwordConfirm, this.resetToken).then(
        (res: any) => {
          if (res.status == 200) {
            this.toastService.toastSuccess('success_new_password', 'success');
            this.openPasswordChange();
          } else if (res.status === 404) {
            this.toastService.toastError("error_password_token_expired", "error");
          } else {
            this.toastService.toastError("error_please_try_again", "error");
          }

          this.coreservice.blockUI = false;
        },
        (err: any) => {
          this.coreservice.blockUI = false;
          if (err.status === 404) {
            this.toastService.toastError("error_password_token_expired", "error");
          } else {
            this.toastService.toastError("error_please_try_again", "error");
          }
        });
    }
  }

  displayFalconLoginScreen() {
    this.hideLoginScreen = true;
    this.isSSOLogin = true;
  }

  redirectToMainLoginScreen() {
    this.hideLoginScreen = false;
    this.isSSOLogin = false;
  }

  redirectToFalconLogin() {
    //window.location.href="https://falcon.dev.stage.rockwellcollins.com";
    this.hideDassaultErrorText = true;
      console.log("redirectToFalconLogin this.code != else");
      this.ssoConfigService.redirectToSSO();

  }

  getParamValueQueryString(paramName) {
    const url = window.location.href;
    let paramValue = "";
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }

  dassaultCodeRequest() {
    this.coreservice.readyToAuth = false;

    console.log(" dassaultCodeRequest called");
    this.ssoConfigService.tokenAPI(this.code)
      .then(
        (res: any) => {
          console.log("dassaultCodeRequest response...", res);
          this.dassaultToken = res.access_token;
          this.dassaultTokenRequest();
        },
        (error: any) => {
          // SSO_Branding: error
          console.log("error in code request", error);
        });
  }

  dassaultTokenRequest() {
    console.log("dassaultTokenRequest Called...");
    this.authService.logInWithDasault(this.dassaultToken)
      .then(
        (res: any) => {
          console.log("dassaultTokenRequest response...", res);
          this.dassaultLoginData = res;
          this.dassaultUserName = this.dassaultLoginData.username;

          var haveToAcceptUserAgreement: boolean = res.agreement_not_accepted;
          var haveToChangePassword: boolean = res.change_password;

          this.authService.setSessionToken(res.access_token, this.dassaultLoginData.username);
          this.coreservice.sessionType = 'session';
          this.authService.forgetUsername();

          // SSO_Branding: if terms and condition is accepted then redirected to the Dashboard otherwise display T&C popup

          //   if (haveToChangePassword == true) {
          //     this.openPasswordChange();
          //  }
          if (this.dassaultLoginData.agreement_not_accepted == true) {
            // SSO_Branding: Display T&C popup
            this.openUserAgreement();
          } else {
            this.authService.init(this.dassaultLoginData.username);

            // SSO_Branding: Navigate to the Dashboard
            this.router.navigate(['/content/movies/all']);
          }
        },
        (error: any) => {
          // SSO_Branding: if error is 401 then navigate to login page otherwise display only error message
          if (error.status == 401) {
            this.dassaultLoginMessage = 'Please enter your stage manager credentials.';
            this.hideDassaultErrorText = true;
            this.hideDassaultLoginButton = true;
            this.router.navigate(['/login']);
            this.coreservice.readyToAuth = true;
          }
          else if (error.status == 500) {
            this.hideDassaultErrorText = true;
            this.hideDassaultLoginButton = false;
            this.dassaultLoginMessage = 'Internal Server Error';
            this.coreservice.readyToAuth = true;
          }
          else {
            this.hideDassaultErrorText = true;
            this.hideDassaultLoginButton = false;
            this.dassaultLoginMessage = error.message;
            this.coreservice.readyToAuth = true;
          }
        });
  }

  getMSUDetails() {
    return this.aircraftService.getMSUDetails(this.aircraft_id).then(
      (res: any) => {
        if (res == true) {
          this.toastService.toastMSUversionWarning("warning", "warning");
        }
      });
  }
}

angular
  .module('app.login')
  .directive(
    'login',
    downgradeComponent({component: LoginComponent}) as angular.IDirectiveFactory
  );
